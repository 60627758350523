import { throwIsomorphicError } from "./throwIsomorphicError";

/**
 * Safely parses a JSON string with configurable error handling.
 *
 * @param str - The JSON string to parse.
 * @param onError - Action to take on parsing failure:
 *                  - "returnOriginal" (default): Returns the original string if parsing fails.
 *                  - "throw": Throws an error if parsing fails.
 * @returns The parsed JSON object, the original string (if parsing fails and onError is "returnOriginal"),
 *          or null if the input string is falsy.
 */
export function safelyParseJSON(
  str: string,
  onError: "returnOriginal" | "throw" = "returnOriginal"
) {
  if (!str) {
    return null; // Return null if the input string is falsy
  }

  if (typeof str === "string") {
    try {
      // Attempt to parse the string as JSON
      return JSON.parse(str);
    } catch (error: any) {
      console.error(`Error parsing to JSON str ${str}`, error);
      // Handle parsing failure based on onError setting
      if (onError === "throw") {
        throwIsomorphicError({
          name: "JsonParsingError",
          message: `Failed to parse JSON str ${str}: ${error.message}`,
          statusCode: 400,
          details: {
            name: "JsonParsingError",
            endpoint: "safelyParseJSON",
            message: `Failed to parse JSON str ${str}: ${error.message}`,
            status: 400,
          },
        });
      }
      // Return the original string if parsing fails and onError is "returnOriginal"
      return str;
    }
  }

  return null; // Return null if the input is not a string
}
