"use client";

import * as Headless from "@headlessui/react";
import clsx from "clsx";
import { LayoutGroup } from "framer-motion";
import React, { forwardRef, useId } from "react";
import { TouchTarget } from "./Button";
import { Link } from "./Link";
export function Navbar({
  className,
  ...props
}: React.ComponentPropsWithoutRef<"nav">) {
  return <nav {...props} className={clsx(className, "tw-flex tw-flex-1 tw-items-center tw-gap-4 tw-rounded-tl-2xl tw-rounded-tr-2xl tw-px-4 tw-py-[0.625rem]", "tw-dark:tw-bg-interface-card-dark tw-bg-interface-card-light", "tw-dark:tw-border-interface-divider-dark tw-border-interface-divider-light lg:tw-border-b")} data-sentry-component="Navbar" data-sentry-source-file="Navbar.tsx" />;
}
export function NavbarDivider({
  className,
  ...props
}: React.ComponentPropsWithoutRef<"div">) {
  return <div aria-hidden="true" {...props} className={clsx(className, "tw-dark:tw-bg-white/10 tw-h-6 tw-w-px tw-bg-zinc-950/10")} data-sentry-component="NavbarDivider" data-sentry-source-file="Navbar.tsx" />;
}
export function NavbarSection({
  className,
  ...props
}: React.ComponentPropsWithoutRef<"div">) {
  let id = useId();
  return <LayoutGroup id={id} data-sentry-element="LayoutGroup" data-sentry-component="NavbarSection" data-sentry-source-file="Navbar.tsx">
      <div {...props} className={clsx(className, "tw-flex tw-items-center tw-gap-2")} />
    </LayoutGroup>;
}
export function NavbarSpacer({
  className,
  ...props
}: React.ComponentPropsWithoutRef<"div">) {
  return <div aria-hidden="true" {...props} className={clsx(className, "-tw-ml-4 tw-flex-1")} data-sentry-component="NavbarSpacer" data-sentry-source-file="Navbar.tsx" />;
}

// #region NavbarItem

// NavbarItem types
type VariantsNavbarItem = "base";
type StyleItemNavbarItem = string[];
type VariantStyleNavbarItem = { [K in VariantsNavbarItem]: {
  colors: StyleItemNavbarItem;
} };

// NavbarItem styles
const variantsStyleNavbarItem: VariantStyleNavbarItem = {
  base: {
    colors: [
    // Text color light
    "tw-text-brandBlues-darkBlue-light",
    // Text color dark
    "tw-dark:tw-text-brandBlues-darkBlue-dark",
    // Hover background light
    "data-[hover]:tw-bg-brandBlues-cream-light",
    // Hover background dark
    "tw-dark:data-[hover]:tw-bg-brandBlues-cream-dark",
    // Focus light
    "data-[focus]:tw-outline-brandBlues-brandDark-light",
    // Focus dark
    "tw-dark:data-[focus]:tw-outline-brandBlues-brandDark-dark"]
  }
};

// NavbarItem Props
export type NavbarItemProps = {
  variant?: VariantsNavbarItem;
  current?: boolean;
  className?: string;
  children: React.ReactNode;
} & (Omit<Headless.ButtonProps, "as" | "className"> | Omit<React.ComponentPropsWithoutRef<typeof Link>, "className">);
export const NavbarItem = forwardRef(function NavbarItem({
  variant = "base",
  current,
  className,
  children,
  ...props
}: NavbarItemProps, ref: React.ForwardedRef<HTMLAnchorElement | HTMLButtonElement>) {
  let classes = clsx(
  // Base
  "tw-relative tw-flex tw-min-w-0 tw-items-center tw-gap-1 tw-rounded-lg tw-p-2 tw-text-left tw-text-14px-regular tw-font-medium",
  // Colors
  variantsStyleNavbarItem[variant].colors,
  // Leading icon/icon-only
  "data-[slot=icon]:*:tw-size-5 data-[slot=icon]:*:tw-shrink-0",
  // Trailing icon (down chevron or similar)
  "data-[slot=icon]:last:[&:not(:nth-child(2))]:*:tw-ml-auto data-[slot=icon]:last:[&:not(:nth-child(2))]:*:tw-size-2",
  // Avatar
  "data-[slot=avatar]:*:-tw-m-0.5 data-[slot=avatar]:*:tw-size-7 data-[slot=avatar]:*:[--avatar-radius:theme(borderRadius.DEFAULT)] data-[slot=avatar]:*:[--ring-opacity:100%] sm:data-[slot=avatar]:*:tw-size-8",
  // Focus
  "focus:tw-outline-none data-[focus]:tw-outline data-[focus]:tw-outline-1 data-[focus]:tw-outline-offset-0",
  // Transition
  "tw-transition-colors tw-duration-200 tw-ease-in-out");
  return <span className={clsx(className, "tw-relative")}>
      {"href" in props ? <Link {...props} className={clsx("tw-cursor-pointer", classes)} data-current={current ? "true" : undefined} ref={ref as React.ForwardedRef<HTMLAnchorElement>}>
          <TouchTarget>{children}</TouchTarget>
        </Link> : <Headless.Button {...props} className={clsx("tw-cursor-pointer", classes)} data-current={current ? "true" : undefined} ref={ref}>
          <TouchTarget>{children}</TouchTarget>
        </Headless.Button>}
    </span>;
});

// #endregion

export function NavbarLabel({
  className,
  ...props
}: React.ComponentPropsWithoutRef<"span">) {
  return <span {...props} className={clsx(className, "tw-truncate")} data-sentry-component="NavbarLabel" data-sentry-source-file="Navbar.tsx" />;
}