import ErrorNotificaion from "@components/GenericElements/ErrorNotification";
import { useSetSnackNotification } from "@custom-hooks/useSetSnackNotification";
import { GenericErrorNotification } from "@tw-components/error/GenericErrorNotification";
import { useEffect } from "react";
const useSetError = (error: any) => {
  const {
    createSnackNotification
  } = useSetSnackNotification();
  useEffect(() => {
    if (error) {
      const newError = process.env.NODE_ENV === "development" ? <ErrorNotificaion error={error} /> : <GenericErrorNotification />;

      /**
      * status undefined --> called failed because it was cancelled
      * status 530 --> timeout error generated by next.js server
      * status 533 --> errors not handled by backend or next.js or gateway
      			 * status 400 --> errors handled by backend
      * status 401 --> errors handled by backend
      * status 403 --> errors handled by backend
      * status 404 --> errors handled by backend
      * status 405 --> errors handled by backend
      * status 409 --> errors handled by backend
      * status 500 --> errors handled by backend
      * status 501 --> errors handled by backend
      * status 502 --> errors handled by backend, bad gateway
      * status 504 --> errors handled by backend
      */

      const errorStatus = error?.status ? parseInt(error.status) : error?.details?.status ? parseInt(error.details.status) : undefined;
      const notificationCondition = errorStatus && errorStatus !== 401;
      if (notificationCondition) {
        createSnackNotification({
          type: "new_error",
          component: newError
        });
      }
    }
  }, [error]);
};
export { useSetError };