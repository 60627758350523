//
// useGetProjectNodes.ts
//

import { useFetchData } from "@custom-hooks/fetch-data";
import { useGetFetchConditionBasedOnProject } from "@custom-hooks/fetch-data/hooks/useGetFetchConditionBasedOnProject";
import { FetchHookResult } from "@data-types/generic-hook-type";
import { Nodes } from "@data-types/nodes-types";
import { ProjectStatus } from "@data-types/projects-types";

/**
 * Custom hook to fetch and analyze node data for a given project ID.
 *
 * @param {string} projectId - The ID of the project to fetch node data for.
 * @param {number} [refreshInterval] - Optional interval (in milliseconds) for refreshing nodes data.
 *
 * @returns {FetchHookResult<Nodes>} The result object with the list of nodes
 *   (or `undefined` if no nodes are available), UI state indicators, and a function to manage fetched data.
 */
export function useGetProjectNodes(
  projectId: string,
  refreshInterval?: number
): FetchHookResult<Nodes> {
  const fetchCondition = useGetFetchConditionBasedOnProject({
    id: projectId,
    projectStatus: ProjectStatus.RUNNING_AND_WORKING,
  });

  return useFetchData<Nodes>({
    fetchCondition: fetchCondition && projectId !== "",
    source: "backend",
    swrKey: {
      url: `/api/projects/${projectId}/nodes`,
      component: "useGetProjectNodes",
    },
    options: {
      refreshInterval,
      revalidateOnFocus: false,
      revalidateIfStale: false,
    },
  });
}
