import { useCustomRouter } from "@custom-hooks/useCustomRouter";
import { useGetUserOrganizations } from "./useGetUserOrganizations";

/**
 * Hook to retrieve the current organization ID from the URL query parameters.
 * - If the `organizationId` exists in the query and is valid, it is returned.
 * - If the `organizationId` is not in the list of user organizations, it returns `false`.
 * - If no `organizationId` is found, it returns `undefined`.
 *
 * @returns {string | false | undefined} The valid organization ID, `false` if invalid, or `undefined` if none exists.
 */
export const useGetCurrentOrganizationId = () => {
  const organizations = useGetUserOrganizations();
  const { query } = useCustomRouter();

  const organizationId =
    typeof query?.organizationId === "string"
      ? query.organizationId
      : undefined;

  const actualOrganizationId =
    organizations && Array.isArray(organizations) && organizationId
      ? organizations.some((obj) => obj.organizationId === organizationId)
        ? organizationId
        : false
      : undefined;

  return actualOrganizationId;
};
