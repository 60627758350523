import {
  SnackNotification,
  useSnackNotificationStore,
} from "@state/SnackNotificationStore";
import { useRef } from "react";

export const useSetSnackNotification = () => {
  const { snackNotifications, updateSnackNotifications } =
    useSnackNotificationStore();

  const snackNotificationsRef = useRef<SnackNotification[]>([]);
  snackNotificationsRef.current = snackNotifications;

  const createSnackNotification = (notification: SnackNotification) => {
    const renderCondition = notification;

    if (renderCondition) {
      let newSnackNotifications: SnackNotification[];

      if (
        snackNotificationsRef.current &&
        snackNotificationsRef.current.length > 0
      ) {
        newSnackNotifications = snackNotificationsRef.current.slice(0);
      } else {
        newSnackNotifications = [];
      }

      newSnackNotifications.unshift(notification);
      snackNotificationsRef.current = newSnackNotifications;
      updateSnackNotifications(snackNotificationsRef.current);
    }
  };

  return { createSnackNotification };
};
