//
// useGetCurrentOrganizationOwnerUserId.ts
//

import { useCustomRouter } from "@custom-hooks/useCustomRouter";
import { useGetUserOrganizations } from "./useGetUserOrganizations";

/**
 * Retrieves the user ID of the owner of the currently selected organization.
 *
 * This function looks up the user's accessible organizations and finds the one
 * matching the `organizationId` from the URL query parameters. If found, it
 * returns the `ownerId` (the user ID of the organization owner). Otherwise, it returns `undefined`.
 *
 * @returns {string | undefined} - The user ID of the organization owner, or `undefined` if not found.
 */
export const useGetCurrentOrganizationOwnerUserId = () => {
  const organizations = useGetUserOrganizations();
  const { query } = useCustomRouter();

  const organizationId =
    typeof query?.organizationId === "string"
      ? query.organizationId
      : undefined;

  const ownerUserId =
    organizations && Array.isArray(organizations) && organizationId
      ? organizations.find((obj) => obj.organizationId === organizationId)
          ?.ownerId || undefined
      : undefined;

  return ownerUserId;
};
