/**
 * Validates a string to ensure it contains only letters, numbers, dots, or dashes,
 * and that the last character is a letter or number.
 *
 * @param {string} str - The string to validate.
 * @returns {object} An object with a boolean `valid` property and a `messages` array indicating validation errors.
 */
export function validateString(str: string): {
  valid: boolean;
  messages: string[];
} {
  // Extract the last character of the string
  const strLastChr = str.slice(-1);

  // Check if the string contains only allowed characters (letters, numbers, dots, or dashes)
  const strOnlyLettersAndNumbersPointLine = /^[A-Za-z0-9.-]*$/.test(str);

  // Check if the last character is a letter or number
  const strLastChrOnlyLettersAndNumbers = /^[A-Za-z0-9]*$/.test(strLastChr);

  // Initialize the return value with validation status and messages array
  let returnValue = {
    valid: strOnlyLettersAndNumbersPointLine && strLastChrOnlyLettersAndNumbers,
    messages: [] as string[],
  };

  // Add validation messages if checks fail
  if (!strOnlyLettersAndNumbersPointLine) {
    returnValue.messages.push(
      "Allowed characters are [a-z, A-Z, 0-9, . and -]."
    );
  }
  if (!strLastChrOnlyLettersAndNumbers) {
    returnValue.messages.push(
      "The last character must be one of [a-z, A-Z, 0-9]."
    );
  }

  // Return validation results
  return returnValue;
}
