import { deepCopyMap } from "./deepCopyMap";

/**
 * Creates a deep copy of an object, array, or Map, recursively cloning nested structures.
 *
 * @param {any} obj - The object, array, or Map to deep copy.
 * @returns {any} A deep copy of the input.
 */
export function deepCopy<T>(obj: T): T {
  // If obj is not an object or is null, return it as is
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  // If obj is a Map, use deepCopyMap to create a deep copy
  if (obj instanceof Map) {
    return deepCopyMap(obj) as T;
  }

  // If obj is an array or plain object, create a new instance and copy properties recursively
  const newObj: any = Array.isArray(obj) ? [] : {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      newObj[key] = deepCopy((obj as any)[key]);
    }
  }

  return newObj;
}
