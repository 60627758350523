//
// useGetUserProjectsByOrganizationId.ts
//

import { useFetchData } from "@custom-hooks/fetch-data";
import { FetchHookResult } from "@data-types/generic-hook-type";
import { Projects, RawProject, RawProjects } from "@data-types/projects-types";
import { useMemo } from "react";

/**
 * Custom hook to fetch project data and provide various states for managing the display.
 *
 * @param {boolean} [fetchCondition] - A condition to determine if projects should be fetched.
 * @param {number} [refreshInterval] - Interval (in milliseconds) for refreshing projects data.
 *
 * @returns {FetchHookResult<Projects>} The result object containing the list of parsed project data, including a
 *   `regionsList` extracted from the project's `regions` JSON field (or `undefined` if no project data is available),
 *   along with UI state indicators and a function to manage fetched data.
 */
export function useGetUserProjectsByOrganizationId(
  organizationId: string | false | undefined,
  fetchCondition?: boolean,
  refreshInterval?: number
): FetchHookResult<Projects> {
  fetchCondition = fetchCondition ?? true;

  const { data, hasData, ...fetchDetails } = useFetchData<RawProjects>({
    fetchCondition: fetchCondition && organizationId ? true : false,
    source: "backend",
    swrKey: {
      url: `/api/organizations/${organizationId}/projects`,
      component: "useGetUserProjectsByOrganizationId",
    },
    options: {
      refreshInterval,
      revalidateOnFocus: true,
    },
  });

  const parsedData: Projects | undefined = useMemo(() => {
    if (!hasData || !data) return undefined;

    return data
      .map((project: RawProject) => {
        try {
          const regionsList = JSON.parse(project.regions);
          return {
            ...project,
            regionsList: regionsList[0] ? regionsList : [],
          };
        } catch {
          return { ...project, regionsList: [] };
        }
      })
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [hasData, data]);

  return {
    data: parsedData,
    hasData,
    ...fetchDetails,
  };
}
