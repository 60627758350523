import { useEditData_v2 } from "@custom-hooks/edit-data";
import {
  useGetCurrentOrganizationId,
  useGetCurrentOrganizationOwnerUserId,
} from "@custom-hooks/organizations";
import { AddonLookupKey } from "@data-types/billing-types";
import { EditDataOpt, EditDataResult } from "@data-types/generic-hook-type";
import { Project } from "@data-types/projects-types";

/**
 * Custom hook for managing the creation of free projects.
 *
 * @param options - Configuration options for the hook, including settings for the edit data operation.
 *
 * @returns An object containing:
 * - `createFreeProject`: A function to initiate the free project creation process.
 * - `isCreatingProject`: A boolean indicating whether the project creation process is ongoing.
 * - `error`: Any error encountered during the project creation process.
 */
export function useCreateFreeProject(options: EditDataOpt<Project>) {
  const { isMutatingData, isLoading, editData, error } = useEditData_v2({
    ...options,
    stopGlobalError: options?.stopGlobalError ?? true,
  });

  const organizationId = useGetCurrentOrganizationId();
  const organizationOwnerId = useGetCurrentOrganizationOwnerUserId();

  /**
   * Initiates the creation of a free project.
   *
   * @param params.projectName - The name of the project to be created.
   * @param params.region - The region where the project will be hosted.
   * @param params.label - A numeric label representing the environment type.
   *
   * @returns A promise resolving to the result of the API call for project creation.
   */
  const createFreeProject = async ({
    projectName,
    region,
    label,
  }: {
    projectName: string;
    region: string;
    label: number;
  }): Promise<EditDataResult<Project>> => {
    return await editData({
      method: "POST",
      endpoint: `/api/organizations/${organizationId}/projects`,
      endpointCallLocation: "useCreateFreeProject",
      serverType: "backend",
      body: {
        name: projectName,
        region,
        env: label,
        plan_id: AddonLookupKey.SANDBOX_PROJECT,
        description: "My awesome project",
      },
      mutateApis: [
        {
          url: `/api/organizations/${organizationId}/projects`,
          component: "useGetUserProjectsByOrganizationId",
        },
        { url: "/api/auth/session", component: "useGetUserSession" },
        {
          url: `/api/users/${organizationOwnerId}/owned-sandbox-projects`,
          component: "useGetOwnedSandboxProjectsByUserId",
        },
      ],
    });
  };

  return {
    createFreeProject,
    isCreatingProject: isLoading || isMutatingData,
    error,
  };
}
