//
// getForbiddenFile.ts
//

import { ForbiddenFile } from "@data-types/upload-types";

/**
 * Searches for a forbidden file by name.
 *
 * @param forbiddenFiles - The list of forbidden files.
 * @param fileName - The name of the file to search for.
 * @returns The forbidden file object if found, otherwise undefined.
 */
export const getForbiddenFile = (
  forbiddenFiles: ForbiddenFile[],
  fileName: string
): ForbiddenFile | undefined => {
  return forbiddenFiles.find((file) => file.name === fileName);
};
