import { useGetCurrentOrganizationId } from "@custom-hooks/organizations";
import { useGetUserSession } from "@custom-hooks/user";
import { SubscriptionStatusAlertProps } from "@data-types/billing-types";
import {
  PAST_DUE_SUBSCRIPTIONS_GRACE_PERIOD_DAYS,
  SQLITE_CLOUD_ORGANIZATION_ID,
} from "@lib/billing/constants";
import { formatDate } from "@lib/iso-utils";
import Stripe from "stripe";

/**
 * Custom hook to retrieve subscription details and status from the user's session.
 *
 * @returns {Object} - An object containing the following:
 *   - `subscriptionStatus`: The current status of the subscription (e.g., "active", "past_due").
 *   - `hasSubscription`: A boolean indicating if the user has a valid subscription
 *     (true for "active" or "past_due", false otherwise).
 *   - `subscriptionStatusAlertProps`: Alert properties for displaying messages related to
 *     the subscription status, such as actions or warnings.
 */
export const useSubscriptionDetails = () => {
  const { data: session, hasData: availableSession } = useGetUserSession();
  const organizationId = useGetCurrentOrganizationId();

  const subscription = organizationId
    ? session?.authorizations?.organizations[organizationId]?.attributes
        ?.subscription
    : undefined;

  const ownerUnpaidInvoices = organizationId
    ? Object.values(
        session?.authorizations?.organizations[organizationId]
          ?.ownerUnpaidInvoices ?? {}
      )
    : undefined;

  const currentOrganizationUnpaidInvoices =
    ownerUnpaidInvoices && subscription
      ? ownerUnpaidInvoices.filter(
          (unpaidInvoice) =>
            unpaidInvoice.metadata?.processed_subscriptions
              ?.split(",")
              ?.includes(subscription.id) ||
            unpaidInvoice.subscription === subscription.id
        )
      : undefined;

  const hasGracePeriodExpired = (
    unpaidBillingCycleInvoice: Stripe.Invoice
  ): boolean => {
    const consolidationKey =
      unpaidBillingCycleInvoice.metadata?.consolidation_key;
    const gracePeriodInSeconds =
      PAST_DUE_SUBSCRIPTIONS_GRACE_PERIOD_DAYS * 24 * 60 * 60;

    const billingCycleEndDate = consolidationKey
      ? Number(consolidationKey.split("_").pop())
      : unpaidBillingCycleInvoice.created;

    const gracePeriodEndTime = billingCycleEndDate + gracePeriodInSeconds;

    return gracePeriodEndTime < Math.floor(Date.now() / 1000);
  };

  const subscriptionStatus = (() => {
    if (!subscription) {
      return undefined;
    }

    switch (subscription.status as Stripe.Subscription.Status) {
      case "canceled":
      case "incomplete_expired":
      case "incomplete":
      case "trialing":
        return subscription.status;
      default: {
        if (!currentOrganizationUnpaidInvoices) {
          return undefined;
        }

        if (currentOrganizationUnpaidInvoices.length === 0) {
          return "active";
        }

        const unpaidBillingCycleInvoices =
          currentOrganizationUnpaidInvoices.filter(
            (unpaidInvoice) =>
              unpaidInvoice.billing_reason === "manual" &&
              unpaidInvoice.metadata?.is_consolidated
          );

        if (unpaidBillingCycleInvoices.length > 0) {
          const hasExpiredGracePeriodInvoices = unpaidBillingCycleInvoices.some(
            (unpaidInvoice) => hasGracePeriodExpired(unpaidInvoice)
          );

          if (hasExpiredGracePeriodInvoices) {
            return "unpaid";
          }

          return "past_due";
        }

        return "active_with_pending_update";
      }
    }
  })();

  const currentOrganizationSubscriptionStatusAlertProps:
    | SubscriptionStatusAlertProps
    | undefined = (() => {
    switch (subscriptionStatus) {
      case "incomplete":
        return {
          title: "Update Your Payment Method",
          description:
            "Your subscription payment could not be processed. Please update your payment method.",
          actionButtonText: "Edit payment method",
        };
      case "past_due":
        const currentPeriodStart = subscription
          ? subscription.current_period_start
          : undefined;

        if (currentPeriodStart) {
          const currentPeriodStartDate = new Date(currentPeriodStart * 1000);
          const date = new Date();

          const diffInMs = date.getTime() - currentPeriodStartDate.getTime();
          const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));

          if (PAST_DUE_SUBSCRIPTIONS_GRACE_PERIOD_DAYS > diffInDays) {
            return {
              title: "Update Your Payment Method",
              description: `Your subscription payment is overdue. Please update your payment method to avoid cancellation in ${PAST_DUE_SUBSCRIPTIONS_GRACE_PERIOD_DAYS - diffInDays} day(s).`,
              actionButtonText: "Edit payment method",
            };
          }
        } else {
          return {
            title: "Update Your Payment Method",
            description: `Your subscription payment is overdue. Please update your payment method.`,
            actionButtonText: "Edit payment method",
          };
        }
      case "unpaid":
        return {
          title: "Subscription Paused Due to Unpaid Invoice",
          description: `Your subscription has been paused because your renewal payment was not completed in ${PAST_DUE_SUBSCRIPTIONS_GRACE_PERIOD_DAYS}-day grace period. To regain full access to features, please complete your payment.`,
          actionButtonText: "Go to invoices",
        };
      case "active_with_pending_update":
        return {
          title: "Pay Your Outstanding Invoice",
          description:
            "Your subscription is active, but some features are restricted due to an unpaid invoice. Complete your payment to restore full functionality.",
          actionButtonText: "Go to invoices",
        };
    }

    return undefined;
  })();

  const subscriptionStatusAlertProps: SubscriptionStatusAlertProps | undefined =
    (() => {
      if (currentOrganizationSubscriptionStatusAlertProps) {
        return currentOrganizationSubscriptionStatusAlertProps;
      }

      if (
        ownerUnpaidInvoices === undefined ||
        ownerUnpaidInvoices.length === 0
      ) {
        return undefined;
      }

      return {
        title:
          "Features Restricted Due to Unpaid Invoices in Another Workspace",
        description:
          "Your subscription is active, but some features are restricted because of an unpaid invoice in another workspace owned by the same user. Please settle the payment to restore full functionality.",
        actionButtonText: "Manage billing",
      };
    })();

  return {
    subscriptionStatus,
    hasSubscription: availableSession
      ? subscriptionStatus
        ? ["trialing", "past_due", "unpaid", "active"].includes(
            subscriptionStatus
          )
        : false
      : undefined,
    trialEnd:
      availableSession &&
      subscription &&
      subscriptionStatus &&
      subscriptionStatus === "trialing" &&
      subscription.trial_end
        ? formatDate(subscription.trial_end * 1000)
        : undefined,
    subscriptionStatusAlertProps:
      organizationId === SQLITE_CLOUD_ORGANIZATION_ID
        ? undefined
        : subscriptionStatusAlertProps,
  };
};
