import { format } from "date-fns";
import { getUtcOffset } from "./getUtcOffset";

/**
 * Converts a UTC date to the user's local time zone and formats it as specified.
 *
 * @param {Date | string | number} inputDate - The UTC date to convert to local time.
 * @param {string} dateFormat - The format to output the date in (default: "yyyy-MM-dd HH:mm:ss").
 * @param {boolean} appendUtc - Whether to append the UTC offset to the formatted string (default: `false`).
 * @returns {string} The formatted local date string, optionally with UTC offset.
 */
export function convertUtc0ToLocalTimeZone(
  inputDate: Date | string | number,
  dateFormat: string = "yyyy-MM-dd HH:mm:ss",
  appendUtc: boolean = false
): string {
  // Convert the input to a Date object
  const date = new Date(inputDate);

  // Adjust the date from UTC to local time by subtracting the timezone offset in milliseconds
  const utcDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);

  // Format the local date using the specified format
  let outputString = format(utcDate, dateFormat);

  // Append UTC offset if requested
  if (appendUtc) {
    outputString = `${outputString} (${getUtcOffset(date)})`;
  }

  // Return the formatted date string
  return outputString;
}
