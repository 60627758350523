import { useEditData_v2 } from "@custom-hooks/edit-data";
import { EditDataOpt, EditDataResult } from "@data-types/generic-hook-type";

/**
 * Custom hook to handle editing the encryption settings of a database within a project.
 *
 * @param {EditDataOpt<null>} options - Configuration options for the `useEditData_v2` hook.
 *
 * @returns {Object} An object containing:
 * - `editDatabaseEncryption`: A function to edit the encryption settings of a database.
 * - `isEditingDatabaseEncryption`: A boolean indicating if the request is in progress.
 * - `error`: Any error encountered during the request, if any.
 */
export function useEditDatabaseEncryption(options: EditDataOpt<null>) {
  const { isMutatingData, isLoading, editData, error } = useEditData_v2({
    ...options,
    stopGlobalError: options?.stopGlobalError ?? true,
  });

  /**
   * Function to edit the encryption settings of a database.
   *
   * @param {string} params.projectId - The ID of the project containing the database.
   * @param {string} params.databaseName - The name of the database to update.
   * @param {string} params.encryptionKey - The new encryption key for the database.
   *
   * @returns {Promise<EditDataResult<null>>} A promise that resolves to the result of the update operation, indicating success or failure.
   */
  const editDatabaseEncryption = async ({
    projectId,
    databaseName,
    encryptionKey,
  }: {
    projectId: string;
    databaseName: string;
    encryptionKey: string;
  }): Promise<EditDataResult<null>> => {
    return await editData({
      method: "POST",
      endpoint: `/api/projects/${projectId}/databases/${databaseName}/encryption/edit`,
      endpointCallLocation: "useEditDatabaseEncryption",
      serverType: "backend",
      body: {
        key: encryptionKey,
      },
      mutateApis: [
        {
          url: `/api/projects/${projectId}/databases`,
          component: "useGetProjectDatabases",
        },
      ],
    });
  };

  return {
    editDatabaseEncryption,
    isEditingDatabaseEncryption: isLoading || isMutatingData,
    error,
  };
}
