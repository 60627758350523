import { useGetUserSession } from "@custom-hooks/user";
import { useCallback } from "react";
import { useGetUserOrganizations } from "./useGetUserOrganizations";

const LAST_VISITED_ORGANIZATION_KEY = "last_visited_organization";

/**
 * Custom hook to manage the last visited organization.
 * It retrieves the last visited organization ID from local storage or falls back
 * to the first available organization from the user's session.
 *
 * @returns {{
 *   getLastVisitedOrganization: () => string | null,
 *   setLastVisitedOrganization: (organizationId: string) => void,
 *   getRedirectUrlForLastVisitedOrganization: () => string
 * }} An object containing methods to get, set, and determine the redirect URL for the last visited organization.
 */
export function useLastVisitedOrganization() {
  const organizations = useGetUserOrganizations();
  const { data: session } = useGetUserSession();

  /**
   * Stores the given organization ID as the last visited organization for the current user.
   *
   * @param {string} organizationId - The ID of the organization to store.
   */
  const setLastVisitedOrganization = (organizationId: string | null) => {
    if (session === undefined) {
      return;
    }

    const storedData = localStorage.getItem(LAST_VISITED_ORGANIZATION_KEY);
    const lastVisitedMap = storedData ? JSON.parse(storedData) : {};

    if (organizationId === null) {
      delete lastVisitedMap[session.user.id];
    } else {
      lastVisitedMap[session.user.id] = organizationId;
    }

    localStorage.setItem(
      LAST_VISITED_ORGANIZATION_KEY,
      JSON.stringify(lastVisitedMap)
    );
  };

  /**
   * Retrieves the last visited organization ID for the current user from local storage.
   * If not found, it returns `null`.
   *
   * @returns {string | null} The last visited organization ID, or `null` if not found.
   */
  const getLastVisitedOrganization = (): string | null => {
    if (session === undefined) {
      return null;
    }

    const storedData = localStorage.getItem(LAST_VISITED_ORGANIZATION_KEY);
    const lastVisitedMap = storedData ? JSON.parse(storedData) : {};

    return lastVisitedMap[session.user.id] || null;
  };

  /**
   * Determines the appropriate redirect URL based on the last visited organization.
   * - If a last visited organization exists, redirects to its projects page.
   * - Otherwise, selects the first available organization.
   * - If no organizations exist:
   *   - Redirects to `/onboarding/setup-organization` if onboarding is incomplete.
   *   - Otherwise, redirects to `/organizations/create`.
   *
   * @returns {string} The appropriate URL for navigation.
   */
  const getRedirectUrlForLastVisitedOrganization = useCallback(():
    | string
    | undefined => {
    if (session === undefined) {
      return undefined;
    }

    const lastVisitedOrganization = getLastVisitedOrganization();
    const isMemberOfLastVisitedOrganization =
      lastVisitedOrganization && organizations
        ? organizations
            ?.map((organization) => organization.organizationId)
            ?.includes(lastVisitedOrganization)
        : false;

    if (!isMemberOfLastVisitedOrganization) {
      setLastVisitedOrganization(null);
    }

    if (isMemberOfLastVisitedOrganization) {
      return `/organizations/${lastVisitedOrganization}/projects`;
    }

    if (organizations && organizations.length > 0) {
      return `/organizations/${organizations[0].organizationId}/projects`;
    }

    const onboardingCompletedAt =
      session.user?.attributes?.onboarding?.completedAt;

    return onboardingCompletedAt
      ? `/organizations/create`
      : `/onboarding/setup-organization`;
  }, [organizations, session]);

  return {
    getLastVisitedOrganization,
    setLastVisitedOrganization,
    getRedirectUrlForLastVisitedOrganization,
  };
}
