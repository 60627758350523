//
// server-error-types.ts
//

//TODO:TIZ:DAMLA create a more rigid link between error name and status code

/**
 * Enum representing possible error names.
 */
export type ServerErrorName =
  | "Backend error message"
  | "TooManyDraftOperations"
  | "Unauthorized"
  | "Forbidden"
  | "ValidationError"
  | "NotFoundError"
  | "ResourceMissing"
  | "GenericStripeError"
  | "BadRequest"
  | "UnexpectedError"
  | "DatabaseQueryError"
  | "JsonParsingError"
  | "InternalServerError"
  | "Timeout"
  | "InvoiceIdMismatch"
  | "MissingConnectionUrl";

/**
 * Enum representing possible HTTP status codes for server errors.
 */
export type HttpStatusCode = 400 | 401 | 403 | 404 | 500 | 533;

/**
 * Type representing the structure of a server error.
 *
 * This is useful for strongly typing error objects in situations where
 * the `ServerError` class is not instantiated but an error-like structure is needed.
 */
export interface ServerErrorDetails {
  name: ServerErrorName; // The name of the error
  statusCode: HttpStatusCode; // The associated HTTP status code
  message: string; // A descriptive error message
}

/**
 * Represents a custom server error.
 *
 * This class extends the built-in `Error` object to include additional
 * metadata such as the error name and HTTP status code.
 */
export class ServerError extends Error {
  name: ServerErrorName; // The name of the error, e.g., "ValidationError"
  statusCode: HttpStatusCode; // The HTTP status code, e.g., 400 or 404

  /**
   * Creates a new `ServerError` instance.
   *
   * @param name - The name of the error, corresponding to a `ServerErrorName`.
   * @param message - A descriptive message for the error.
   * @param statusCode - The HTTP status code for the error, corresponding to a `HttpStatusCode`.
   */
  constructor(
    name: ServerErrorName,
    message: string,
    statusCode: HttpStatusCode
  ) {
    super(message); // Call the parent Error constructor
    this.name = name;
    this.statusCode = statusCode;

    // Maintain proper stack trace (only in V8 environments like Node.js)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ServerError);
    }
  }
}
