//
// useUppyDatabaseUpload.ts
//

import {
  useGetCurrentProjectId,
  useGetProjectStorageInfo,
} from "@custom-hooks/projects";
import { useUppy } from "@custom-hooks/upload";
import { useGetProjectInfo } from "@custom-hooks/useApi";
import { ClientError } from "@data-types/client-error-types";
import { UseUppyDatabaseUploadReturn } from "@data-types/databases-types";
import { UploadMethods } from "@data-types/upload-types";
import { fetchApiRoute_v2 } from "@lib/client-side";
import { formatBytes } from "@lib/iso-utils";
import * as Sentry from "@sentry/nextjs";
import { useEffect, useState } from "react";
import { useSWRConfig } from "swr";
import { allowedDatabaseExtensions } from "../utils/allowedDatabaseExtensions";
import { useGetProjectDatabases } from "./useGetProjectDatabases";

/**
 * Hook for handling database uploads using Uppy.
 * Supports direct uploads (XHR) and multipart uploads (S3).
 */
export function useUppyDatabaseUpload(): UseUppyDatabaseUploadReturn {
  // Get mutate SWR method
  const { mutate } = useSWRConfig();

  // Get current project ID
  const projectId = useGetCurrentProjectId() || "";

  // Get project info
  const { gatewayUrlHTTP, connectionStringApiKey } = useGetProjectInfo();
  // Fetch project storage plan and available storage
  const { data: databases, hasData: databasesHasData } =
    useGetProjectDatabases(projectId);
  const { data: storageInfo } = useGetProjectStorageInfo(projectId);

  // Extract database names to prevent duplicate uploads
  const forbiddenFiles =
    databasesHasData && databases
      ? databases.map((db) => ({
          name: db.name,
          size: db.size,
        }))
      : [];

  // Initialize Uppy for database uploads
  const uppyUpload = useUppy({
    resourceId: projectId,
    allowedExtensions: allowedDatabaseExtensions,
    forbiddenFiles: forbiddenFiles,
    initialConfig: {
      restrictions: {
        minFileSize: 0,
        maxTotalFileSize: null,
        minNumberOfFiles: 1,
        requiredMetaFields: ["name"],
        maxFileSize: null,
        maxNumberOfFiles: 1, // Allow only one database file
        allowedFileTypes: allowedDatabaseExtensions,
      },
    },
  });

  // Toggles the overwrite state
  const [overwrite, setOverwrite] = useState(false);
  const toggleOverwrite = () => {
    setOverwrite((prev) => !prev);
  };

  // Extract states and method from uppyUpload
  const {
    uppy,
    fileName,
    fileSize,
    fileId,
    isFileTaken,
    updateUploadMethod,
    startUpload,
    completeUpload,
    setUploadError,
  } = uppyUpload;

  useEffect(() => {
    setOverwrite(false);
  }, [fileId]);

  let availableStorage = 0;
  let usedStorageAfterUpload = 0;
  let usedStoragePercentageAfterUpload = 0;

  if (storageInfo) {
    const { availableStorageInBytes = 0, usedStorageInBytes = 0 } = storageInfo;

    availableStorage = Math.max(
      availableStorageInBytes - usedStorageInBytes!,
      0
    ); // Prevent negative values

    // Compute storage usage after upload
    const fileSizeInBytes = fileSize ?? 0;
    if (isFileTaken) {
      if (overwrite) {
        usedStorageAfterUpload =
          usedStorageInBytes! - isFileTaken.size + fileSizeInBytes;
        usedStoragePercentageAfterUpload =
          ((usedStorageInBytes! - isFileTaken.size + fileSizeInBytes) /
            (availableStorageInBytes || 1)) *
          100;
      } else {
        usedStorageAfterUpload = usedStorageInBytes!;
        usedStoragePercentageAfterUpload =
          (usedStorageInBytes! / (availableStorageInBytes || 1)) * 100;
      }
    } else {
      usedStorageAfterUpload = usedStorageInBytes! + fileSizeInBytes;
      usedStoragePercentageAfterUpload =
        ((usedStorageInBytes! + fileSizeInBytes) /
          (availableStorageInBytes || 1)) *
        100;
    }
  }

  /**
   * Initiates the database upload process
   * @param uploadMethod - The upload method (xhr or s3)
   * @param overwrite - Whether to overwrite an existing file
   */
  const uploadDatabase = async ({
    uploadMethod,
    decryptionKey,
  }: {
    uploadMethod: UploadMethods;
    decryptionKey: string;
  }) => {
    if (uppy && fileId) {
      if (uploadMethod === "xhr") {
        // Configure direct XHR upload
        updateUploadMethod({
          method: "xhr",
          config: {
            endpoint: `${gatewayUrlHTTP}/v2/weblite/${fileName}`,
            method: overwrite ? "PATCH" : "POST",
            formData: false,
            headers: {
              authorization: `Bearer ${connectionStringApiKey}`,
              "Content-Type": "application/octet-stream",
            },
          },
        });
      }

      if (uploadMethod === "s3") {
        const MiB = 0x10_00_00;

        // Configure S3 for single or multipart upload
        updateUploadMethod({
          method: "s3",
          config: {
            // Files that are more than 100MiB should be uploaded in multiple parts.
            shouldUseMultipart: (file) => {
              return file.size ? file.size > 100 * MiB : false;
            },

            // Request signed URL for direct S3 upload
            getUploadParameters: async (file, options) => {
              const data: any = await fetchApiRoute_v2({
                auth: connectionStringApiKey,
                endpoint: `${gatewayUrlHTTP}/v2/storage/databases/singlepart`,
                endpointCallLocation: "upload-database-s3_getUploadParameters",
                method: "GET",
                serverType: "gateway",
              });

              // Return an object in the correct shape.
              return {
                method: "PUT",
                url: data.data.url,
                fields: {}, // For presigned PUT uploads, this should be left empty.
                // Provide content type header required by S3
                headers: {
                  "Content-Type": file.type,
                },
              };
            },

            // Initiate multipart upload
            createMultipartUpload: async (file) => {
              const data: any = await fetchApiRoute_v2({
                auth: connectionStringApiKey,
                endpoint: `${gatewayUrlHTTP}/v2/storage/databases/multipart`,
                endpointCallLocation:
                  "upload-database-s3_createMultipartUpload",
                body: {
                  filename: file.name,
                  type: file.type,
                },
                method: "POST",
                serverType: "gateway",
              });
              return data.data;
            },

            // Abort multipart upload if needed
            abortMultipartUpload: async (file, { key, uploadId, signal }) => {
              await fetchApiRoute_v2({
                auth: connectionStringApiKey,
                endpoint: `${gatewayUrlHTTP}/v2/storage/databases/multipart/${uploadId}?key=${key}`,
                endpointCallLocation: "upload-database-s3_abortMultipartUpload",
                method: "DELETE",
                serverType: "gateway",
              });
            },

            // Sign each part for multipart upload
            signPart: async (file, options) => {
              const { uploadId, key, partNumber } = options;

              if (uploadId == null || partNumber == null) {
                throw new ClientError(
                  "upload-database-s3_signPart: Cannot sign without an uploadId, and a partNumber"
                );
              }
              const data: any = await fetchApiRoute_v2({
                auth: connectionStringApiKey,
                endpoint: `${gatewayUrlHTTP}/v2/storage/databases/multipart/${uploadId}/${partNumber}?key=${key}`,
                endpointCallLocation: "upload-database-s3_signPart",
                method: "GET",
                serverType: "gateway",
              });
              return data.data;
            },

            // List uploaded parts
            listParts: async (file, { key, uploadId }) => {
              const data: any = await fetchApiRoute_v2({
                auth: connectionStringApiKey,
                endpoint: `${gatewayUrlHTTP}/v2/storage/databases/multipart/${uploadId}?key=${key}`,
                endpointCallLocation: "upload-database-s3_listParts",
                method: "GET",
                serverType: "gateway",
              });
              return data.data;
            },

            // Complete multipart upload
            completeMultipartUpload: async (file, { key, uploadId, parts }) => {
              const data: any = await fetchApiRoute_v2({
                auth: connectionStringApiKey,
                endpoint: `${gatewayUrlHTTP}/v2/storage/databases/multipart/${uploadId}/complete?key=${key}`,
                endpointCallLocation:
                  "upload-database-s3_completeMultipartUpload",
                method: "POST",
                serverType: "gateway",
                body: { parts },
              });
              return data.data;
            },
          },
        });
      }

      // Start the upload process
      startUpload();
      const result = await uppy.upload();
      if (result) {
        console.info("Successful uploads:", result.successful);
        if (
          result.successful &&
          Array.isArray(result.successful) &&
          result.successful.length > 0
        ) {
          const uploadResult = result.successful[0];
          const transferFile = async () => {
            try {
              await fetchApiRoute_v2({
                auth: connectionStringApiKey,
                endpoint: `${gatewayUrlHTTP}/v2/weblite/${uploadResult.name}?key=${decryptionKey}`,
                endpointCallLocation: "upload-database-s3_upload-success",
                body: {
                  location: uploadResult.uploadURL,
                },
                method: overwrite ? "PATCH" : "POST",
                serverType: "gateway",
              });
              await mutate({
                url: `/api/projects/${projectId}/databases`,
                component: "useGetProjectDatabases",
              });
              await mutate({
                url: `${gatewayUrlHTTP}/v2/weblite/sql?sql=${encodeURIComponent("LIST INFO")}`,
                component: "useRunSQLQuery",
                auth: connectionStringApiKey,
              });
            } catch (error: any) {
              completeUpload();
              setUploadError({
                error: error,
                retryMethod: "transfer",
                method: transferFile,
              });
              throw error;
            }
          };
          if (uploadMethod === "s3") {
            await transferFile();
          }
          completeUpload();
        }
        if (
          result.failed &&
          Array.isArray(result.failed) &&
          result.failed.length > 0
        ) {
          completeUpload();

          const clientError = new ClientError(
            `[Uppy Error] Database upload failed`
          );

          Sentry.captureException(clientError, {
            extra: { uppy_error: JSON.stringify(result.failed) },
          });

          throw clientError;
        }
      }
    }
  };

  return {
    uploadDatabase,
    overwrite,
    toggleOverwrite,
    storageInfo: storageInfo
      ? {
          ...storageInfo,
          usedStorageAfterUpload: formatBytes(usedStorageAfterUpload),
          usedStoragePercentageAfterUpload: usedStoragePercentageAfterUpload,
        }
      : undefined,
    ...uppyUpload,
  };
}
