/**
 * This component is used to display a generic error message to the user
 * inside the snack notification for errors. 
 *
 * @returns {JSX.Element} A styled error notification UI component.
 */
export function GenericErrorNotification(): JSX.Element {
  return <div className="tw-border-1 tw-flex tw-flex-col tw-gap-y-4 tw-rounded-md tw-pt-2" data-sentry-component="GenericErrorNotification" data-sentry-source-file="GenericErrorNotification.tsx">
      <div className="tw-text-18px-medium tw-text-semantics-error-light dark:tw-text-semantics-error-dark">
        Something went wrong.
      </div>
      <div className="tw-text-16px-regular tw-text-semantics-error-light dark:tw-text-semantics-error-dark">
        Please try again later. If the problem persists, contact us for
        assistance.
      </div>
    </div>;
}