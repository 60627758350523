/**
 * Checks if a table name exists in the list of tables.
 *
 * @param {string} tableName - The name of the table to search for.
 * @param {Array<{ name: string }>} tablesList - The list of tables, each with a `name` property.
 * @returns {boolean} Returns `true` if the table name exists, otherwise `false`.
 */
export function tableNameExist(
  tableName: string,
  tablesList: { name: string }[]
): boolean {
  if (tablesList) {
    return tablesList.some((table) => table.name === tableName);
  }
  return false;
}
