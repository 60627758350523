/**
 * Truncates a string to a specified length and adds ellipsis if it exceeds that length.
 *
 * @param {string} input - The string to truncate.
 * @param {number} cutLength - The maximum length of the truncated string (default: 20).
 * @returns {string} The truncated string with ellipsis if it exceeds the specified length.
 */
export function truncateString(input: string, cutLength: number = 20): string {
  // Check if the input length exceeds the cut length; if so, truncate and add "..."
  return input.length > cutLength
    ? `${input.substring(0, cutLength)}...`
    : input;
}
