import posthog from "posthog-js";

/**
 * Signs out the user and performs cleanup actions for PostHog,
 * then redirects to a specified callback URL.
 *
 * @param {string} [callbackUrl="/auth/sign-in"] - The URL to redirect the user to after sign-out.
 *   Defaults to the sign-in page.
 *
 * @returns {Promise<void>} - A promise that resolves when the sign-out process is complete.
 *
 * Function Steps:
 * 1. Fetches a CSRF token from the authentication API to ensure secure sign-out.
 * 2. Sends a POST request to the sign-out endpoint with the CSRF token.
 * 3. Resets the PostHog instance to clear any user-specific tracking data.
 * 4. Redirects the user to the specified callback URL, typically the sign-in page.
 *
 * This function is useful for securely signing out users, cleaning up any session-related data
 * from analytics (PostHog), and providing a smooth redirection experience for users post-logout.
 */
export async function signOut(
  callbackUrl: string = "/auth/sign-in"
): Promise<void> {
  try {
    // Fetch the CSRF token
    const resCsrfToken = await fetch("/api/auth/csrf");
    const csrfToken = await resCsrfToken.json();

    // Perform sign out request
    await fetch("/api/auth/signout", {
      method: "POST",
      body: JSON.stringify(csrfToken),
      headers: {
        "Content-Type": "application/json",
      },
    });

    // After successful logout, reset PostHog
    posthog.reset();

    // Redirect to sign-in page
    window.location.href = callbackUrl;
  } catch (error) {
    console.error("Sign-out error:", error);
  }
}
