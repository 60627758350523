import { useGetUserSession } from "@custom-hooks/user";
import { Organization } from "@data-types/organization-types";

/**
 * Custom hook to retrieve a list of organizations the user has access to.
 * It extracts the organizations from the user's session data and returns them sorted by name.
 *
 * @returns {Array<Organization> | undefined} An array of organization objects if available, otherwise `undefined`.
 */
export const useGetUserOrganizations = (): Organization[] | undefined => {
  const { data: session, hasData: availableSession } = useGetUserSession();

  if (availableSession && session?.authorizations?.organizations) {
    const organizations = Object.values(session.authorizations.organizations);

    return organizations.sort((a, b) => a.name.localeCompare(b.name));
  }

  return undefined;
};
