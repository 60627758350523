//
// useWebliteSql.ts
//

import { useFetchData } from "@custom-hooks/fetch-data";
import { useGetFetchConditionBasedOnProject } from "@custom-hooks/fetch-data/hooks/useGetFetchConditionBasedOnProject";
import { useGetProjectInfo } from "@custom-hooks/useApi";
import { FetchHookResult } from "@data-types/generic-hook-type";
import { ProjectStatus } from "@data-types/projects-types";
import { SWRConfiguration } from "swr";

export type SQLRequestMethod = "GET" | "POST";

/**
 * Custom hook to execute SQL queries through GET or POST requests.
 *
 * This hook interacts with the backend API to run SQL queries against a specified database
 * using the provided HTTP method. It supports SWR for data fetching, enabling features like
 * revalidation and caching.
 *
 * @template T - The expected shape of the returned data.
 *
 * @param {Object} params - Parameters for running the SQL query.
 * @param {string} params.query - The SQL query string to execute.
 * @param {SQLRequestMethod} [params.method="GET"] - The HTTP method to use ("GET" or "POST").
 * @param {string} [params.database] - (Optional) The target database for the query.
 * @param {SWRConfiguration} [params.options={ revalidateOnFocus: false }] - (Optional) SWR configuration options.
 *
 * @returns {FetchHookResult<T>} An object containing:
 * - `data` (T | undefined): The response data from the query.
 * - `isLoading` (boolean): Indicates if the request is currently loading.
 * - `error` (Error | undefined): Any error encountered during the request.
 * - Additional fetch details from the `useFetchData` hook.
 */
export function useRunSQLQuery<T>({
  query,
  method = "GET",
  database,
  projectId,
  options = { revalidateOnFocus: false },
}: {
  query: string;
  projectId: string;
  method?: SQLRequestMethod;
  database?: string;
  options?: SWRConfiguration;
}): FetchHookResult<T> {
  // Retrieve API key and gateway URL for the current project
  const { connectionStringApiKey, gatewayUrlHTTP } =
    useGetProjectInfo(projectId);

  const fetchCondition = useGetFetchConditionBasedOnProject({
    id: projectId,
    projectStatus: ProjectStatus.RUNNING_AND_WORKING,
  });

  // Determine if the request is a GET request
  const isGetRequest = method === "GET";

  // Encode query and database for safe URL transmission
  const encodedQuery = encodeURIComponent(query);
  const encodedDatabase = database ? encodeURIComponent(database) : undefined;

  // Build the request URL based on the HTTP method
  const requestUrl = isGetRequest
    ? `${gatewayUrlHTTP}/v2/weblite/sql?sql=${encodedQuery}${
        encodedDatabase ? `&database=${encodedDatabase}` : ""
      }`
    : `${gatewayUrlHTTP}/v2/weblite/sql`;

  // Execute the data fetch using the custom hook
  const { data, ...fetchDetails } = useFetchData<T>({
    fetchCondition:
      fetchCondition && Boolean(gatewayUrlHTTP && connectionStringApiKey),
    source: "gateway",
    swrKey: {
      url: requestUrl,
      component: "useRunSQLQuery",
      auth: connectionStringApiKey,
    },
    options: options,
  });

  return {
    data,
    ...fetchDetails,
  };
}
