/**
 * Converts an object to a Map, recursively converting nested plain objects to Maps.
 *
 * @param {Record<string, any>} obj - The object to convert.
 * @returns {Map<string, any>} A Map representation of the object.
 */
export function objectToMap(obj: Record<string, any>): Map<string, any> {
  const map = new Map<string, any>();

  // Iterate over the object's entries and convert each to a Map entry
  for (const [key, value] of Object.entries(obj)) {
    // Recursively convert plain objects to Maps
    if (value && typeof value === "object" && !Array.isArray(value)) {
      map.set(key, objectToMap(value as Record<string, any>));
    } else {
      // Otherwise, directly set the value in the Map
      map.set(key, value);
    }
  }

  return map;
}
