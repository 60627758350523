import { useEditData_v2 } from "@custom-hooks/edit-data";
import { useGetCurrentOrganizationId } from "@custom-hooks/organizations";
import { EditDataOpt, EditDataResult } from "@data-types/generic-hook-type";

/**
 * Custom hook to edit project details, such as name, description, environment, and admin credentials.
 *
 * @param {EditDataOpt<null>} options - Configuration options for the `useEditData_v2` hook.
 *
 * @returns {Object} An object containing:
 * - `editProjectDetails`: A function to update project details.
 * - `isEditingProjectDetails`: A boolean indicating if the request is in progress.
 * - `error`: Any error encountered during the request, if any.
 */
export function useEditProjectDetails(options: EditDataOpt<null>) {
  const { isMutatingData, isLoading, editData, error } =
    useEditData_v2<null>(options);

  const organizationId = useGetCurrentOrganizationId();

  /**
   * Function to update project details.
   *
   * @param {string} params.projectId - The ID of the project to update.
   * @param {string} [params.newProjectName] - The new name for the project.
   * @param {string} [params.newDescription] - The new description for the project.
   * @param {string} [params.newEnvironment] - The new environment for the project.
   * @param {string} [params.newAdminUserName] - The new admin username for the project.
   * @param {string} [params.newAdminPassword] - The new admin password for the project.
   *
   * @returns {Promise<EditDataResult<null>>} A promise that resolves to the result of the editing project operation, indicating success or failure.
   */
  const editProjectDetails = async ({
    projectId,
    newProjectName,
    newDescription,
    newEnvironment,
    newAdminUserName,
    newAdminPassword,
  }: {
    projectId: string;
    newProjectName?: string;
    newDescription?: string;
    newEnvironment?: string;
    newAdminUserName?: string;
    newAdminPassword?: string;
  }): Promise<EditDataResult<null>> => {
    return await editData({
      method: "POST",
      endpoint: `/api/projects/${projectId}/edit`,
      endpointCallLocation: "useEditProjectDetails",
      serverType: "backend",
      body: {
        name: newProjectName,
        description: newDescription,
        admin_username: newAdminUserName,
        env: newEnvironment,
        admin_password: newAdminPassword,
      },
      mutateApis: [
        {
          url: `/api/organizations/${organizationId}/projects`,
          component: "useGetUserProjectsByOrganizationId",
        },
      ],
    });
  };

  return {
    editProjectDetails,
    isEditingProjectDetails: isLoading || isMutatingData,
    error,
  };
}
