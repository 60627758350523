/**
 * Generates a Tailwind CSS class string based on the hover state.
 *
 * @param {boolean} isHovered - A boolean indicating whether the element is hovered.
 *
 * @returns {string} A Tailwind class string that controls opacity, transition, and pointer events.
 */
export const tableTransitionTailwind = (isHovered: boolean): string =>
  isHovered
    ? "tw-opacity-100 tw-transition-opacity tw-duration-500 tw-pointer-events-auto"
    : "tw-opacity-0 tw-transition-opacity tw-duration-100 tw-pointer-events-none";
