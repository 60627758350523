//
// users_removeOrganizationIdFromSession.ts
//

import { Session } from "@data-types/user-types";

export function users_removeOrganizationIdFromSession(
  session: Session | undefined,
  organizationIdToRemove: string
): Session | undefined {
  // Deep clone the object to avoid mutating the original
  const newSession = structuredClone(session);

  if (
    newSession?.authorizations?.organizations &&
    newSession.authorizations.organizations[organizationIdToRemove]
  ) {
    delete newSession.authorizations.organizations[organizationIdToRemove];
  }

  return newSession;
}
