import { useEditData_v2 } from "@custom-hooks/edit-data";
import { EditDataOpt, EditDataResult } from "@data-types/generic-hook-type";

/**
 * Custom hook to handle disconnecting an account provider.
 *
 * @param {EditDataOpt<null>} options - Configuration options for the `useEditData_v2` hook.
 *
 * @returns {Object} An object containing:
 * - `disconnectAccountProvider`: A function to disconnect a specified account provider.
 * - `isDisconnectingAccountProvider`: A boolean indicating if the disconnection request is in progress.
 * - `error`: Any error encountered during the request, if any.
 */
export function useDisconnectAccountProvider(options: EditDataOpt<null>) {
  const { isMutatingData, isLoading, editData, error } =
    useEditData_v2<null>(options);

  /**
   * Function to disconnect an account provider.
   *
   * @param {Object} params - The parameters for the disconnection request.
   * @param {"google" | "github"} params.provider - The provider to disconnect.
   *
   * @returns {Promise<EditDataResult<null>>} A promise that resolves to the result of account provider disconnection, either success or failure.
   */
  const disconnectAccountProvider = async ({
    provider,
  }: {
    provider: "google" | "github";
  }): Promise<EditDataResult<null>> => {
    return await editData({
      method: "GET",
      endpoint: `/api/unlink-account?provider=${provider}`,
      endpointCallLocation: "useDisconnectAccountProvider",
      serverType: "dashboard",
      mutateApis: [
        {
          url: `/api/provider-profile?provider=${provider}`,
          component: "useGetUserAccountProfile",
        },
      ],
    });
  };

  return {
    disconnectAccountProvider,
    isDisconnectingAccountProvider: isLoading || isMutatingData,
    error,
  };
}
