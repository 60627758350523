/**
 * Checks if an object has no enumerable properties.
 *
 * @param {Record<string, unknown>} obj - The object to check for properties.
 * @returns {boolean} Returns `true` if the object is empty, otherwise `false`.
 */
export function isEmpty(obj: Record<string, unknown>): boolean {
  // Iterate over the object's keys
  for (const key in obj) {
    // Check if the object has the key as its own property
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      return false; // Object is not empty
    }
  }
  return true; // Object is empty
}
