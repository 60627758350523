import { BackendErrorResponse } from "@data-types/backend-response-types";
import { ClientError } from "@data-types/client-error-types";
import {
  HttpStatusCode,
  ServerError,
  ServerErrorName,
} from "@data-types/server-error-types";

/**
 * Throws a custom error (`ServerError` or `ClientError`) based on the runtime environment.
 *
 * This utility function standardizes error handling in both client and server environments.
 * - On the server, it throws a `ServerError`, including metadata such as name, message, and status code.
 * - On the client, it throws a `ClientError`, including a message and optional error details.
 *
 * @param params - The error details:
 *   - `name` (server-only): A short, descriptive name of the error (e.g., "ValidationError", "NotFoundError").
 *   - `message`: A detailed error message providing context about the issue.
 *   - `statusCode` (server-only): The HTTP status code associated with the error (default: 400).
 *   - `details` (client-only): Additional details about the error, typically an object describing the issue.
 *
 * @throws A `ServerError` or `ClientError` instance based on the environment.
 */
export function throwIsomorphicError(params: {
  name?: ServerErrorName; // Required for server errors
  message: string;
  statusCode?: HttpStatusCode; // Optional, defaults to 400 for server errors
  details?: BackendErrorResponse; // Optional, for client errors
}): never {
  if (typeof window !== "undefined") {
    // Client environment: throw a ClientError
    throw new ClientError(params.message, params.details);
  } else {
    // Server environment: throw a ServerError
    if (!params.name || !params.statusCode) {
      throw new Error(
        "`name` and `statusCode` are required for throwing a `ServerError`."
      );
    }
    throw new ServerError(
      params.name,
      params.message,
      params.statusCode || 400
    );
  }
}
