//
// useProjectPollingManager.ts
//

import { useEffect, useState } from "react";
import { useGetProject } from "./useGetProject";

/**
 * Custom hook to manage polling based on a project's `creating_count`. It starts polling
 * when `creating_count > 0` and stops when it reaches `0`. Additionally, it triggers one or more
 * callback functions when polling stops.
 *
 * @param {string} projectId - The unique identifier of the project to monitor.
 * @param {(() => void) | (() => void)[]} callbacks - A function or an array of functions to invoke
 * when polling stops and `creating_count` reaches `0`.
 *
 * @returns {{ isPolling: boolean }} An object containing:
 *   - `isPolling`: A boolean indicating whether polling is currently active.
 */
export function useProjectPollingManager(
  projectId: string,
  callbacks: (() => void) | (() => void)[]
): { isPolling: boolean } {
  // State to track whether polling is active
  const [isPolling, setIsPolling] = useState(false);

  // Fetch project info to monitor `creating_count`
  const { data: projectInfo, hasData: availableProjectInfo } = useGetProject(
    projectId,
    isPolling ? 10000 : 0 // Poll every 10 seconds if active
  );

  // Extract `creating_count` from the project data
  const creatingCount = availableProjectInfo ? projectInfo?.creating_count : 0;

  useEffect(() => {
    // Start polling if `creating_count > 0` and polling is not already active
    if (creatingCount && creatingCount > 0 && !isPolling) {
      setIsPolling(true);
    }

    // Stop polling if `creating_count` becomes `0`
    if (creatingCount === 0 && isPolling) {
      setIsPolling(false);

      // Trigger callbacks when polling stops
      if (callbacks) {
        if (Array.isArray(callbacks)) {
          // Invoke each callback in the array
          callbacks.forEach((callback) => {
            callback();
          });
        } else {
          // Invoke the single callback
          callbacks();
        }
      }
    }
  }, [creatingCount, isPolling, callbacks]);

  return {
    isPolling, // Indicates whether polling is active
  };
}
