import { deepCopy } from "./deepCopy";

/**
 * Creates a deep copy of a Map, recursively cloning any nested objects or Maps within keys and values.
 *
 * @param {Map<any, any>} originalMap - The original Map to deep copy.
 * @returns {Map<any, any>} A new Map with deeply copied keys and values.
 */
export function deepCopyMap<K, V>(originalMap: Map<K, V>): Map<K, V> {
  const newMap = new Map<K, V>();

  originalMap.forEach((value, key) => {
    // Recursively clone the value if it is an object or another Map
    const clonedValue =
      typeof value === "object" && value !== null ? deepCopy(value) : value;

    // Recursively clone the key if it is an object or another Map
    const clonedKey =
      typeof key === "object" && key !== null ? deepCopy(key) : key;

    // Set the cloned key-value pair in the new Map
    newMap.set(clonedKey as K, clonedValue as V);
  });

  return newMap;
}
