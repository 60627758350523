//
// index.tsx - API requests and mutations related functionality
//

import { useSetError } from "@custom-hooks/useSetError";
import { BackendSuccessResponse } from "@data-types/backend-response-types";
import { ClientError } from "@data-types/client-error-types";
import { SWRFetcherKey } from "@data-types/client-fetch-types";
import { EditDataOpt, EditDataResult, EditDataReturn } from "@data-types/generic-hook-type";
import { FetchApiOptions_v2, fetchApiRoute_v2 } from "@lib/client-side";
import { GlobalErrorDialog } from "@tw-components/error/GlobalErrorDialog";
import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { useSWRConfig } from "swr";

/**
 * Custom hook to handle API calls and data mutations with state management.
 *
 * @template T - The type of the edited data result.
 *
 * @param opt - Configuration options for callbacks and global error handling.
 *
 * @returns An object containing state variables and the `editData` function to perform API calls.
 */
export function useEditData_v2<T>(opt: EditDataOpt<T>): EditDataReturn<T> {
  const {
    startCallback = null,
    errorCallback = null,
    successCallback = null,
    mutatedCallback = null,
    stopGlobalError = false,
    customErrorDialog = null
  } = opt;
  const {
    mutate
  } = useSWRConfig();

  // State variables
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<InstanceType<typeof ClientError> | null>(null);
  const [editedData, setEditedData] = useState<BackendSuccessResponse<T> | null>(null);
  const [mutatedData, setMutatedData] = useState<any>(null);
  const [isMutatingData, setIsMutatingData] = useState(false);

  // This logic handles error display based on specific conditions:
  // - To handle the error locally (for example, inside a dialog), `stopGlobalError` must be set to `true`.
  // - If you don't want to handle the error locally:
  //   - To display a custom error dialog, `stopGlobalError` must be `false`, and you need to provide the props for `customErrorDialog`.
  //   - If `stopGlobalError` is `false` but no `customErrorDialog` is provided, a generic error snack notification will be shown.
  //     Note: This generic error handling is a temporary fix and will be removed after refactoring the old components.
  //     Eventually, all errors will either be handled locally or displayed using a custom error dialog.
  useSetError(stopGlobalError || customErrorDialog ? undefined : error);
  useEffect(() => {
    if (!stopGlobalError && error && customErrorDialog) {
      const portalContainer = document.getElementById("error-portal") ?? (() => {
        const div = document.createElement("div");
        div.id = "error-portal";
        document.body.appendChild(div);
        return div;
      })();
      const root = createRoot(portalContainer);
      root.render(<GlobalErrorDialog {...customErrorDialog} error={error} />);
      return () => root.unmount();
    }
  }, [error]);

  // Resets all states before a new operation
  const resetStates = () => {
    setEditedData(null);
    setMutatedData(null);
    setError(null);
  };

  /**
   * Performs optimistic mutations on a set of SWR cache keys.
   *
   * @param mutateApis - Array of SWR cache keys (API endpoints) to mutate and optional optimistc data.
   *
   * @returns Array of mutation results.
   */
  const handleMutations = async (mutateApis?: (SWRFetcherKey | {
    key: SWRFetcherKey;
    mutateOptimisticData?: any;
  })[]) => {
    if (!mutateApis || mutateApis.length === 0) return [];
    const results: any[] = [];
    for (const item of mutateApis) {
      let key: SWRFetcherKey;
      let optimisticData: any = undefined;
      if (typeof item === "object" && "key" in item) {
        key = item.key;
        optimisticData = item.mutateOptimisticData;
      } else {
        key = item;
      }
      const options = optimisticData !== undefined ? {
        optimisticData,
        rollbackOnError: true,
        revalidate: true
      } : undefined;
      const result = await mutate(key, undefined, options);
      results.push(result);
    }
    return results;
  };

  /**
   * Executes an API call and manages related SWR cache updates.
   *
   * @param fetchOpt - Configuration for the API request, including:
   *   - The endpoint, method, and request body.
   *   - Optional `mutateApis`: an array of SWR keys to revalidate after the request.
   *   - Optional `mutateOptimisticData`: an array of optimistic values, matching the order of `mutateApis`.
   *     Each optimistic value will be used to temporarily update the corresponding cache key before revalidation.
   *
   * @returns A promise resolving to the result of the API call, including success or error state.
   */
  const editData = async (fetchOpt: FetchApiOptions_v2 & {
    mutateApis?: (SWRFetcherKey | {
      key: SWRFetcherKey;
      mutateOptimisticData?: any;
    })[];
  }): Promise<EditDataResult<T>> => {
    setIsLoading(true);
    resetStates();

    // Invoke start callback if provided
    await startCallback?.();
    const {
      mutateApis
    } = fetchOpt;
    let errorCaught = false;

    // Indicate mutating state if targets are provided
    if (mutateApis?.length) {
      setIsMutatingData(true);
    }
    try {
      // Perform the API request
      const data = await fetchApiRoute_v2<T>(fetchOpt);

      // In case of redirect data is undefined
      if (data) {
        // Invoke the edited callback if provided
        successCallback?.(data);
        setEditedData(data);

        // Return the successful data
        return {
          success: true,
          data
        };
      } else {
        return {
          success: false,
          error: new ClientError("Request completed with redirect")
        };
      }
    } catch (error: any) {
      // Handle errors and invoke the error callback
      setError(error);
      errorCallback?.(error);
      errorCaught = true;

      // Return error
      return {
        success: false,
        error
      };
    } finally {
      setIsLoading(false);
      // Handle mutations if targets are provided
      if (mutateApis?.length && !errorCaught) {
        const mutationResults = await handleMutations(mutateApis);
        setMutatedData(mutationResults);
        mutatedCallback?.();
      }
      setIsMutatingData(false);
    }
  };
  return {
    isLoading,
    error: error,
    editedData,
    mutatedData,
    isMutatingData,
    editData
  };
}