import { format } from "date-fns";

/**
 * Converts a given date to UTC (offset 0) and formats it as "yyyy-MM-dd HH:mm:ss".
 *
 * @param {Date | string | number} inputDate - The date to convert to UTC. Can be a Date object, ISO string, or timestamp.
 * @returns {string} The formatted date string in UTC.
 */
export function convertDateToUtc0(inputDate: Date | string | number): string {
  // Convert input to a Date object
  const date = new Date(inputDate);

  // Adjust date to UTC by adding the timezone offset in milliseconds
  const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

  // Format the UTC date as "yyyy-MM-dd HH:mm:ss"
  const outputString = format(utcDate, "yyyy-MM-dd HH:mm:ss");

  // Return the formatted date string
  return outputString;
}
