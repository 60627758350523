import * as Headless from "@headlessui/react";
import clsx from "clsx";
import type React from "react";
export function SwitchGroup({
  className,
  ...props
}: React.ComponentPropsWithoutRef<"div">) {
  return <div data-slot="control" {...props} className={clsx(className,
  // Basic groups
  "tw-space-y-3 [&_[data-slot=label]]:tw-font-normal",
  // With descriptions
  "has-[[data-slot=description]]:tw-space-y-6 [&_[data-slot=label]]:has-[[data-slot=description]]:tw-font-medium")} data-sentry-component="SwitchGroup" data-sentry-source-file="Switch.tsx" />;
}
export function SwitchField({
  className,
  ...props
}: {
  className?: string;
} & Omit<Headless.FieldProps, "as" | "className">) {
  return <Headless.Field data-slot="field" {...props} className={clsx(className,
  // Base layout
  "tw-grid tw-grid-cols-[1fr_auto] tw-items-center tw-gap-x-8 tw-gap-y-1 sm:tw-grid-cols-[1fr_auto]",
  // Control layout
  "[&>[data-slot=control]]:tw-col-start-2 [&>[data-slot=control]]:tw-self-center",
  // Label layout
  "[&>[data-slot=label]]:tw-col-start-1 [&>[data-slot=label]]:tw-row-start-1 [&>[data-slot=label]]:tw-justify-self-start",
  // Description layout
  "[&>[data-slot=description]]:tw-col-start-1 [&>[data-slot=description]]:tw-row-start-2",
  // With description
  "[&_[data-slot=label]]:has-[[data-slot=description]]:tw-font-medium")} data-sentry-element="unknown" data-sentry-component="SwitchField" data-sentry-source-file="Switch.tsx" />;
}

//
// types
//

type StyleItem = string[];
type Sizes = "medium" | "small";
type Variants = "primary";
type SizesStyle = { [K in Sizes]: {
  container: StyleItem;
  circle: StyleItem;
} };
type TranslationStyle = { [K in Sizes]: StyleItem };
type VariantStyle = { [K in Variants]: StyleItem };

//
// component
//

// Define generic styles for all switches
const sizes: SizesStyle = {
  medium: {
    container: ["tw-h-6 tw-w-12 tw-px-1"],
    circle: ["tw-size-4"]
  },
  small: {
    container: ["tw-h-5 tw-w-10 tw-px-1"],
    circle: ["tw-size-[0.875rem]"]
  }
};
const translations: TranslationStyle = {
  medium: ["group-data-[checked]:tw-translate-x-6"],
  small: ["group-data-[checked]:tw-translate-x-[1.125rem]"]
};

// Define variant colors for the switches
const variants: VariantStyle = {
  primary: [
  // Container colors light default
  "[--switch-bg:theme(colors.brandBlues.ice.light)]",
  // Container colors dark default
  "tw-dark:[--switch-bg:theme(colors.brandBlues.ice.dark)]",
  // Circle item light default
  "[--switch:theme(colors.brandBlues.brandSecondary.light)]",
  // Circle item dark default
  "tw-dark:[--switch:theme(colors.brandBlues.brandSecondary.dark)]"]
};

// Switch Props
export type SwitchProps = {
  variant?: Variants;
  size?: Sizes;
  className?: string;
} & Omit<Headless.SwitchProps, "as" | "className" | "children">;
export function Switch({
  variant = "primary",
  size = "medium",
  className,
  ...props
}: SwitchProps) {
  return <Headless.Switch data-slot="control" {...props} className={clsx(className,
  // Base styles
  "tw-group tw-relative tw-isolate tw-inline-flex tw-cursor-default tw-items-center tw-rounded-2xl",
  // Sizes
  sizes[size].container,
  // Transitions
  "tw-transition tw-duration-0 tw-ease-in-out data-[changing]:tw-duration-200",
  // Outline and background color in forced-colors mode so switch is still visible
  "tw-forced-colors:tw-outline tw-forced-colors:[--switch-bg:Highlight] tw-dark:tw-forced-colors:[--switch-bg:Highlight]",
  // Unchecked
  "tw-dark:tw-bg-brandBlues-ice-dark tw-bg-brandBlues-ice-light",
  // Checked
  "tw-dark:data-[checked]:tw-bg-[--switch-bg] data-[checked]:tw-bg-[--switch-bg]",
  // Focus
  "tw-dark:data-[focus]:tw-outline-brandBlues-brandSecondary-dark focus:tw-outline-none data-[focus]:tw-outline data-[focus]:tw-outline-1 data-[focus]:tw-outline-offset-[0.1875rem] data-[focus]:tw-outline-brandBlues-brandSecondary-light",
  // Disabled
  "data-[disabled]:data-[checked]:tw-bg-interface-gray-light data-[disabled]:tw-bg-interface-gray-light", "tw-dark:data-[disabled]:tw-bg-interface-gray-dark tw-dark:data-[disabled]:data-[checked]:tw-bg-interface-gray-dark",
  // Color specific styles
  variants[variant])} data-sentry-element="unknown" data-sentry-component="Switch" data-sentry-source-file="Switch.tsx">
      <span aria-hidden="true" className={clsx(
    // Basic layout
    "tw-pointer-events-none tw-relative tw-inline-block tw-rounded-full",
    // Sizes
    sizes[size].circle,
    // Transition
    "tw-translate-x-0 tw-transition tw-duration-200 tw-ease-in-out",
    // Invisible border so the switch is still visible in forced-colors mode
    "tw-border tw-border-transparent",
    // Unchecked light
    "tw-bg-interface-card-light group-data-[hover]:tw-bg-brandBlues-cream-light",
    // Unchecked dark
    "tw-dark:tw-bg-interface-card-dark tw-dark:group-data-[hover]:tw-bg-brandBlues-cream-light",
    // Checked
    "group-data-[checked]:tw-bg-[--switch] group-data-[hover]:group-data-[checked]:tw-bg-[--switch]",
    // Translations
    translations[size],
    // Disabled
    "group-data-[disabled]:group-data-[checked]:tw-bg-interface-card-light")} />
    </Headless.Switch>;
}