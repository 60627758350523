import { useGetCurrentOrganizationId } from "@custom-hooks/organizations";
import { useGetUserSession } from "@custom-hooks/user";

export const useGetUserHasCancelledSubscription = () => {
  const { data: session, hasData: availableSession } = useGetUserSession();
  const organizationId = useGetCurrentOrganizationId();

  return availableSession && organizationId
    ? session!.authorizations?.organizations[organizationId]?.attributes
        ?.subscriptions_cancelled
    : false;
};
