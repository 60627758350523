import { useEditData_v2 } from "@custom-hooks/edit-data";
import { useGetCurrentOrganizationId } from "@custom-hooks/organizations";
import { useGetUserSession } from "@custom-hooks/user";
import {
  AddonActionNames,
  AddonLookupKey,
  CreateSubscriptionSuccessResponse,
  HandlingCreateSubscriptionFlow,
} from "@data-types/billing-types";
import { EditDataOpt } from "@data-types/generic-hook-type";
import { getBaseUrlClientSide } from "@lib/client-side";
import { useCallback } from "react";

/**
 * Custom hook to manage the subscription creation process.
 *
 * @param {EditDataOpt<CreateSubscriptionSuccessResponse>} options - Configuration options for `useEditData_v2`.
 *
 * @returns {object} An object containing:
 *   - `createSubscription` (function): A function to initiate the subscription creation process.
 *       - Parameters:
 *         - `newAddon` (AddonItem<AddonLookupKey>): The add-on item to add to the subscription.
 *       - Returns:
 *         - `Promise<CreateSubscriptionSuccessResponse>`: The result of the subscription creation process.
 *   - `isLoading` (boolean): Indicates whether the subscription creation or associated mutation is in progress.
 *   - `error` (ClientError | null): The error returned by the useEditData_v2, if any.
 */
export const useCreateSubscription = (
  options: EditDataOpt<CreateSubscriptionSuccessResponse>
) => {
  const { data: session } = useGetUserSession();

  const organizationId = useGetCurrentOrganizationId();

  const { email = "", userId = "" } = {
    userId: session?.userId,
    email: organizationId
      ? session?.authorizations?.organizations[organizationId]?.ownerEmail
      : undefined,
  };

  const { isLoading, isMutatingData, editData, error } =
    useEditData_v2<CreateSubscriptionSuccessResponse>({
      ...options,
      successCallback: (data) => {
        options.successCallback?.(data);

        const stripeRedirectUrl = data.value.redirectUrl;
        if (data.value.status === "completed" && stripeRedirectUrl) {
          window.location.href = stripeRedirectUrl;
        }
      },
      stopGlobalError: options?.stopGlobalError ?? true,
      customErrorDialog: {
        title: "Failed to upgrade",
        message:
          "Something went wrong while upgrading. Please try again later. If the issue persists, contact us for assistance.",
      },
    });

  const createSubscription = useCallback(
    async ({
      newAddons,
      successUrl,
    }: Pick<HandlingCreateSubscriptionFlow, "newAddons"> & {
      successUrl?: string;
    }) => {
      // Get the user's timezone offset in minutes
      const timezoneOffsetMinutes = new Date().getTimezoneOffset();
      // Convert the offset to seconds (UTC offset is negative of the timezone offset)
      const timezoneOffsetSeconds = timezoneOffsetMinutes * 60;

      // Search for an addon that represents a project upgrade, and if found, extract its projectId.
      // The search is performed using `.find()`, which iterates over `newAddons` and stops at the first match.
      // If an addon with `addon: PRO_PROJECT` and `action.name: UPGRADE` is found, its `projectId` is assigned to `projectId`.
      // If no such addon exists, `projectId` remains an empty string.
      let projectId: string = "";
      newAddons.find((addon) => {
        if (
          addon.addon === AddonLookupKey.PRO_PROJECT &&
          addon.action.name === AddonActionNames.UPGRADE
        ) {
          projectId = addon?.action.data?.projectId;
        }
      });

      const createSessionResponse = await editData({
        method: "POST",
        endpoint: `/api/billing/subscriptions/${email}`,
        endpointCallLocation: "useCreateSubscription.js",
        serverType: "dashboard",
        body: {
          newAddons,
          successUrl: successUrl ? successUrl : getBaseUrlClientSide(),
          cancelUrl: getBaseUrlClientSide(),
          organizationId,
          email,
          userId,
          projectId,
          timezoneOffsetSeconds,
        } as HandlingCreateSubscriptionFlow,
        mutateApis: [
          {
            url: `/api/organizations/${organizationId}/invitations`,
            component: "useGetPendingInvitations",
          },
          {
            url: `/api/organizations/${organizationId}/members`,
            component: "useGetMembers",
          },
          {
            url: `/api/organizations/${organizationId}/projects`,
            component: "useGetUserProjectsByOrganizationId",
          },
          { url: "/api/auth/session", component: "useGetUserSession" },
          ...(projectId
            ? [
                {
                  url: `/api/projects/${projectId}/jobs/nodes`,
                  component: "useGetProjectJobs",
                },
              ]
            : []),
        ],
      });

      return createSessionResponse;
    },
    [email, organizationId, userId, editData]
  );

  return {
    createSubscription,
    isCreatingSubscription: isLoading || isMutatingData,
    createSubscriptionError: error,
  };
};
