"use client";

import * as Headless from "@headlessui/react";
import ChevronDownIcon from "@layouts/svg-icon/chevron-down-icon.svg";
import ChevronUpIcon from "@layouts/svg-icon/chevron-up-icon.svg";
import clsx from "clsx";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Description, ErrorMessage, Field, Label } from "./Fieldset";
import { LoadingIndicator } from "./LoadingIndicator";
type Sizes = "large" | "medium" | "small" | "custom";
type StyleItem = string[];
type SizeStyles = { [K in Sizes]: StyleItem };
interface AppearanceStyles {
  typography: {
    placeholder: StyleItem;
    button: StyleItem;
    selectedOption: StyleItem;
    unselectedOption: StyleItem;
  };
  border: {
    button: StyleItem;
    buttonOpen: StyleItem;
    selectedOption: StyleItem;
    options: StyleItem;
  };
  background: {
    button: StyleItem;
    selectedOption: StyleItem;
    options: StyleItem;
  };
  icon: {
    enabled: StyleItem;
    disabled: StyleItem;
  };
}
const sizeStyles: SizeStyles = {
  small: ["tw-h-[2.375rem] tw-px-[calc(theme(spacing[3])-1px)] tw-py-[calc(theme(spacing[3])-1px)]"],
  medium: ["tw-h-12 tw-px-[calc(theme(spacing[3])-1px)] tw-py-[calc(theme(spacing[3])-3px)]"],
  large: ["tw-h-16 tw-px-[calc(theme(spacing[3])-1px)] tw-py-[calc(theme(spacing[7])-3px)]"],
  custom: []
};
const appearanceStyles: AppearanceStyles = {
  typography: {
    placeholder: [
    // font
    "tw-text-14px-regular",
    // color
    "tw-text-text-subTitle-light dark:tw-text-text-subTitle-dark tw-opacity-60",
    // disabled
    "data-[disabled]:tw-text-interface-gray-light dark:data-[disabled]:tw-text-interface-gray-dark"],
    button: [
    // font
    "tw-text-14px-regular",
    // color
    "tw-text-text-title-light dark:tw-text-text-title-dark",
    // disabled
    "group-data-[disabled]:tw-text-interface-gray-light dark:group-data-[disabled]:tw-text-interface-gray-dark"],
    selectedOption: [
    // font
    "tw-text-14px-book",
    // color
    "tw-text-text-title-light dark:tw-text-text-title-dark"],
    unselectedOption: [
    // font
    "tw-text-14px-regular",
    // color
    "tw-text-text-subTitle-light dark:tw-text-text-subTitle-dark",
    // focus
    "data-[focus]:tw-text-text-body-light dark:data-[focus]:tw-text-text-body-dark data-[focus]:tw-opacity-60 "]
  },
  border: {
    button: ["after:data-[focus]:tw-ring-2 after:data-[focus]:tw-ring-blue-500"],
    buttonOpen: ["after:tw-ring-2 after:tw-ring-brandBlues-brandSecondary-light"],
    selectedOption: [
    // border
    "tw-border tw-border-interface-divider-light dark:tw-border-interface-divider-dark",
    // hover
    "group-data-[hover]:tw-border-text-title-light group-data-[hover]:dark:tw-border-text-title-dark",
    // active
    "group-data-[active]:tw-border-text-title-light dark:group-data-[active]:tw-border-text-title-dark",
    // invalid
    "data-[invalid]:dark:tw-border-semantics-error-dark data-[invalid]:data-[hover]:tw-border-semantics-error-dark data-[invalid]:data-[hover]:tw-border-semantics-error-light data-[invalid]:tw-border-semantics-error-light",
    // disabled
    "group-data-[disabled]:tw-border-interface-divider-light data-[hover]:group-data-[disabled]:tw-border-interface-divider-light group-data-[disabled]:dark:tw-border-interface-divider-dark dark:data-[hover]:group-data-[disabled]:tw-border-interface-divider-dark"],
    options: [
    // border
    "tw-border tw-border-interface-divider-light dark:tw-border-interface-divider-dark",
    // hover
    "group-data-[hover]:tw-border-text-title-light dark:group-data-[hover]:tw-border-text-title-dark",
    // active
    "group-data-[active]:tw-border-text-title-light dark:group-data-[active]:tw-border-text-title-dark"]
  },
  background: {
    button: ["before:tw-bg-interface-card-light before:data-[disabled]:tw-bg-interface-card-light"],
    selectedOption: ["tw-bg-transparent dark:tw-bg-interface-card-dark dark:group-data-[disabled]:tw-bg-interface-card-dark"],
    options: ["tw-bg-interface-card-light dark:tw-bg-interface-card-dark"]
  },
  icon: {
    enabled: ["tw-text-text-body-light dark:tw-text-text-body-dark"],
    disabled: ["tw-text-interface-gray-light dark:tw-text-interface-gray-dark"]
  }
};
interface ComboboxBaseProps<T> extends Omit<Headless.ComboboxProps<T, false>, "as" | "multiple"> {
  className?: string;
  placeholder?: string;
  autoFocus?: boolean;
  "aria-label"?: string;
  children?: React.ReactNode[];
  showLoader?: boolean;
  invalid?: boolean;
  onInputChange: (value: string) => void;
}
type ComboboxSizeProps = {
  size: Exclude<Sizes, "custom">;
  customSize?: never;
} | {
  size: "custom";
  customSize: StyleItem;
};
type ComboboxStyleProps = {
  style: "default";
  customStyle?: never;
} | {
  style: "custom";
  customStyle: AppearanceStyles;
};
export type ComboboxProps<T> = ComboboxBaseProps<T> & ComboboxSizeProps & ComboboxStyleProps;
export function Combobox<T>({
  className,
  placeholder,
  autoFocus,
  "aria-label": ariaLabel,
  children: options,
  size,
  customSize,
  style,
  customStyle,
  showLoader,
  invalid,
  onInputChange,
  ...props
}: ComboboxProps<T>) {
  const styles = style === "default" ? appearanceStyles : customStyle;
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [buttonWidth, setButtonWidth] = useState<number | null>(null);
  useEffect(() => {
    const handleResize = () => {
      if (buttonRef.current) {
        setButtonWidth(buttonRef.current.offsetWidth);
      }
    };
    window.addEventListener("resize", handleResize);
    if (buttonRef.current) {
      setButtonWidth(buttonRef.current.offsetWidth);
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return <Headless.Combobox {...props} multiple={false} data-sentry-element="unknown" data-sentry-component="Combobox" data-sentry-source-file="Combobox.tsx">
      {({
      open
    }) => <>
          <Headless.ComboboxButton ref={buttonRef} autoFocus={autoFocus} data-slot="control" aria-label={ariaLabel} className={clsx([className,
      // Basic layout
      "tw-group tw-relative tw-block tw-w-full",
      // Background color + shadow applied to inset pseudo element, so shadow blends with border in light mode
      "before:tw-absolute before:tw-inset-px before:tw-rounded-[calc(theme(borderRadius.lg)-1px)]",
      // Background color is moved to control and shadow is removed in dark mode so hide `before` pseudo
      "dark:before:tw-hidden",
      // Hide default focus styles
      "focus:tw-outline-none",
      // Focus ring
      "after:tw-pointer-events-none after:tw-absolute after:tw-inset-0 after:tw-rounded-lg after:tw-ring-inset", styles.border.button, open && styles.border.buttonOpen,
      // Disabled state
      "data-[disabled]:tw-opacity-50  before:data-[disabled]:tw-shadow-none",
      // Color transitions
      "tw-transition-colors tw-duration-200 data-[focus]:tw-transition-colors data-[focus]:tw-duration-200",
      // Background colors
      styles.background.button])} onClick={() => onInputChange("")}>
            <Headless.ComboboxInput className={clsx([
        // Basic layout
        "tw-relative tw-block tw-w-full tw-appearance-none tw-rounded-lg", size !== "custom" ? sizeStyles[size] : customSize, "tw-flex tw-items-center tw-text-left",
        // Set minimum height for when no value is selected
        "tw-min-h-11 sm:tw-min-h-9",
        // Horizontal padding
        "tw-pl-[calc(theme(spacing[3.5])-1px)] tw-pr-[calc(theme(spacing.8)-1px)] sm:tw-pl-[calc(theme(spacing.3)-1px)]",
        // Typography
        styles.typography.button,
        // Background color
        styles.background.selectedOption,
        // Border
        styles.border.selectedOption,
        // Disabled state
        "group-data-[disabled]:tw-opacity-100 ",
        // Color transitions
        "group-data-[hover]:tw-transition-colors group-data-[hover]:tw-duration-200", "tw-outline-none"])} placeholder={placeholder} data-invalid={invalid || undefined} onChange={e => onInputChange(e.target.value)} />
            <div className={clsx("tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-2", props.disabled ? styles.icon.disabled : styles.icon.enabled)}>
              {showLoader ? <LoadingIndicator className="tw-mr-1 tw-h-5 tw-w-5 tw-fill-brandBlues-brandSecondary-light tw-text-brandBlues-pale-light dark:tw-fill-brandBlues-brandSecondary-dark dark:tw-text-brandBlues-pale-dark" /> : open ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </div>
          </Headless.ComboboxButton>

          {options?.length !== 0 && <Headless.ComboboxOptions transition anchor="bottom start" className={clsx(
      // Anchor positioning
      "tw-z-50 tw-mt-1",
      // Base styles
      "tw-isolate tw-select-none tw-scroll-py-1 tw-space-y-2 tw-rounded-xl tw-p-3",
      // Invisible border that is only visible in `forced-colors` mode for accessibility purposes
      "tw-focus:tw-outline-none tw-outline tw-outline-1 tw-outline-transparent",
      // Handle scrolling when menu won't fit in viewport
      "tw-overflow-y-scroll tw-overscroll-contain",
      // Popover background
      styles.background.options, "tw-backdrop-blur-xl",
      // Transitions
      "tw-transition-opacity tw-duration-100 tw-ease-in data-[transition]:tw-pointer-events-none data-[closed]:data-[leave]:tw-opacity-0 group-data-[hover]:tw-transition-colors group-data-[hover]:tw-duration-200",
      // Border
      styles.border.options)} style={{
        width: buttonWidth || "auto"
      }}>
              <>
                {React.Children.map(options, (child, index) => {
            if (React.isValidElement<ComboboxOptionProps<T>>(child)) {
              return React.cloneElement(child, {
                key: child.key || index,
                getTypography: (selected: boolean) => {
                  return selected ? styles.typography.selectedOption : styles.typography.unselectedOption;
                }
              });
            }
            return child;
          })}
              </>
            </Headless.ComboboxOptions>}
        </>}
    </Headless.Combobox>;
}
type ComboboxOptionProps<T> = {
  className?: string;
  children?: React.ReactNode;
  getTypography?: (selected: boolean) => StyleItem;
} & Omit<Headless.ComboboxOptionProps<"div", T>, "as" | "className">;
export function ComboboxOption<T>({
  children,
  className,
  getTypography,
  ...props
}: ComboboxOptionProps<T>) {
  let sharedClasses = clsx(
  // Base
  "tw-flex tw-min-w-0 tw-cursor-pointer tw-items-center",
  // Icons
  "[&>[data-slot=icon]]:tw-size-5 [&>[data-slot=icon]]:tw-shrink-0 sm:[&>[data-slot=icon]]:tw-size-4", "[&>[data-slot=icon]]:tw-text-zinc-500 [&>[data-slot=icon]]:group-data-[focus]/option:tw-text-white [&>[data-slot=icon]]:dark:tw-text-zinc-400", "forced-colors:[&>[data-slot=icon]]:tw-text-[CanvasText] forced-colors:[&>[data-slot=icon]]:group-data-[focus]/option:tw-text-[Canvas]",
  // Avatars
  "[&>[data-slot=avatar]]:-tw-mx-0.5 [&>[data-slot=avatar]]:tw-size-6 sm:[&>[data-slot=avatar]]:tw-size-5");
  return <Headless.ComboboxOption as={Fragment} {...props} data-sentry-element="unknown" data-sentry-component="ComboboxOption" data-sentry-source-file="Combobox.tsx">
      {({
      selected
    }) => {
      return <div className={clsx(
      // Basic layout
      "group/option tw-grid tw-cursor-default tw-grid-cols-[theme(spacing.5),1fr] tw-items-baseline tw-gap-x-2 tw-rounded-lg tw-py-2.5 tw-pl-2 tw-pr-3.5 sm:tw-grid-cols-[theme(spacing.4),1fr] sm:tw-py-1.5 sm:tw-pl-1.5 sm:tw-pr-3",
      // Forced colors mode
      "tw-forced-color-adjust-none forced-colors:data-[focus]:tw-bg-[Highlight] forced-colors:data-[focus]:tw-text-[HighlightText]",
      // Disabled
      "data-[disabled]:tw-opacity-50",
      // Typography
      getTypography?.(selected),
      // Prevent line breaks
      "tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap",
      // Color transitions
      "data-[focus]:tw-transition-colors data-[focus]:tw-duration-200")}>
            <svg className="tw-relative tw-ml-1.5 tw-hidden tw-self-center tw-stroke-white group-data-[focus]/option:tw-block sm:tw-ml-1 sm:tw-size-3" viewBox="0 0 16 16" fill="none" aria-hidden="true">
              <path d="M5 8.5l2.25 2L11 6" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <div className={clsx(className, sharedClasses)}>{children}</div>
          </div>;
    }}
    </Headless.ComboboxOption>;
}
export function ComboboxLabel({
  className,
  ...props
}: React.ComponentPropsWithoutRef<"span">) {
  return <span {...props} className={clsx(className, "ml-2.5 truncate first:ml-0 sm:ml-2 sm:first:ml-0")} data-sentry-component="ComboboxLabel" data-sentry-source-file="Combobox.tsx" />;
}
export type GenericComboboxProps<T> = {
  label?: string;
  description?: string;
  errors?: Record<string, string | undefined>;
  comboboxClassName?: ComboboxProps<T>["className"];
  comboboxOptions: {
    options: T[];
    getOptionKey: (option: T) => string;
    getOptionLabel: (option: T) => string;
    filterOption: (option: T, query: string) => boolean;
  };
} & Omit<ComboboxBaseProps<T>, "children" | "onInputChange"> & ComboboxSizeProps & ComboboxStyleProps;
export function GenericCombobox<T>({
  label,
  description,
  disabled,
  errors,
  comboboxClassName,
  comboboxOptions,
  ...props
}: GenericComboboxProps<T>) {
  const [query, setQuery] = useState("");
  const filteredOptions = query === "" ? comboboxOptions.options : comboboxOptions.options.filter(option => {
    return comboboxOptions.filterOption(option, query.toLowerCase());
  });
  return <Field disabled={disabled} data-sentry-element="Field" data-sentry-component="GenericCombobox" data-sentry-source-file="Combobox.tsx">
      {label && <Label>{label}</Label>}

      {description && <Description>{description}</Description>}

      <Combobox {...props} className={comboboxClassName} invalid={errors && props.name && errors[props.name] ? true : false} onInputChange={value => setQuery(value)} data-sentry-element="Combobox" data-sentry-source-file="Combobox.tsx">
        {filteredOptions.map(option => <ComboboxOption key={comboboxOptions.getOptionKey(option)} value={option}>
            <ComboboxLabel>
              {comboboxOptions.getOptionLabel(option)}
            </ComboboxLabel>
          </ComboboxOption>)}
      </Combobox>

      {errors && props.name && errors[props.name] && <ErrorMessage>{errors[props.name]}</ErrorMessage>}
    </Field>;
}