import { useEditData_v2 } from "@custom-hooks/edit-data";
import {
  useGetCurrentOrganizationId,
  useGetCurrentOrganizationOwnerUserId,
} from "@custom-hooks/organizations";
import { EditDataOpt } from "@data-types/generic-hook-type";

/**
 * Custom hook for managing the deletion of a project.
 *
 * @param options - Configuration options for the hook, including settings for the edit data operation.
 *
 * @returns An object containing:
 * - `deleteProject`: A function to initiate the project deletion process.
 * - `isDeletingProject`: A boolean indicating whether the project deletion process is ongoing.
 * - `error`: Any error encountered during the project deletion process.
 */
export function useDeleteFreeProject(options: EditDataOpt<null>) {
  const { isMutatingData, isLoading, editData, error } = useEditData_v2<null>({
    ...options,
    stopGlobalError: options?.stopGlobalError ?? true,
  });

  const organizationId = useGetCurrentOrganizationId();
  const organizationOwnerId = useGetCurrentOrganizationOwnerUserId();

  /**
   * Initiates the deletion of a project.
   *
   * @param projectId - The ID of the project to be deleted.
   *
   * @returns A promise resolving to the result of the API call for project deletion.
   */
  const deleteProject = async (projectId: string) => {
    return await editData({
      method: "GET",
      endpoint: `/api/projects/${projectId}/delete`,
      endpointCallLocation: "useDeleteProject",
      serverType: "backend",
      mutateApis: [
        { url: "/api/auth/session", component: "useGetUserSession" },
        {
          url: `/api/organizations/${organizationId}/projects`,
          component: "useGetUserProjectsByOrganizationId",
        },
        {
          url: `/api/users/${organizationOwnerId}/owned-sandbox-projects`,
          component: "useGetOwnedSandboxProjectsByUserId",
        },
      ],
    });
  };

  return {
    deleteProject,
    isDeletingProject: isLoading || isMutatingData,
    error,
  };
}
