//
// useGetOwnedSandboxProjectsByUserId.ts
//

import { useFetchData } from "@custom-hooks/fetch-data";
import { FetchHookResult } from "@data-types/generic-hook-type";
import {
  FetchOwnedSandboxProjectsResult,
  ProjectRecord,
} from "@data-types/projects-types";

/**
 * Custom hook to fetch and manage sandbox projects owned by a specific user.
 *
 * This hook utilizes `useFetchData` to retrieve projects where the specified user is an owner.
 *
 * @param {string} userId - The unique identifier of the user whose owned sandbox projects should be fetched.
 *
 * @returns {FetchHookResult<any>} An object containing:
 *   - `data`: The list of retrieved projects.
 *   - `hasData`: A boolean indicating if there is valid project data.
 *   - Additional fetch-related properties for managing request state.
 */
export function useGetOwnedSandboxProjectsByUserId(
  userId: string | false | undefined,
  refreshInterval?: number
): FetchOwnedSandboxProjectsResult<ProjectRecord[]> {
  const { data, hasData, emptyData, ...fetchDetails } = useFetchData<
    ProjectRecord[]
  >({
    fetchCondition: Boolean(userId), // Ensures data is only fetched if userId is provided
    source: "backend",
    swrKey: {
      url: `/api/users/${userId}/owned-sandbox-projects`,
      component: "useGetOwnedSandboxProjectsByUserId",
    },
    options: {
      refreshInterval,
      revalidateOnFocus: true,
    },
  });

  const sandboxProjectsCount = emptyData
    ? 0
    : hasData
      ? data?.length
      : undefined;

  return {
    data,
    hasData,
    emptyData,
    sandboxProjectsCount,
    ...fetchDetails,
  };
}
