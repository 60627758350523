/**
 * Hides the middle part of a string, leaving only the start and end visible.
 *
 * @param {string} str - The string to obfuscate.
 * @param {number} visibleStart - The number of visible characters at the start (default: 2).
 * @param {number} visibleEnd - The number of visible characters at the end (default: 4).
 * @returns {string} The obfuscated string with the middle part hidden.
 */
export function hideMiddlePartOfString(
  str: string,
  visibleStart: number = 2,
  visibleEnd: number = 4
): string {
  // If the string is too short, return it as is
  if (str.length <= visibleStart + visibleEnd) {
    return str;
  }

  // Extract the start and end visible parts of the string
  const firstVisiblePart = str.slice(0, visibleStart);
  const lastVisiblePart = str.slice(str.length - visibleEnd);

  // Create the hidden part with a fixed number of asterisks
  const hiddenPart = "*".repeat(4);

  // Concatenate the visible parts with the hidden part in between
  return firstVisiblePart + hiddenPart + lastVisiblePart;
}
