//
// alert.tsx
//

import InfoIcon from "@layouts/svg-icon/info-circle-icon.svg";
import SuccessIcon from "@layouts/svg-icon/success-circle-icon.svg";
import WarningIcon from "@layouts/svg-icon/warning-circle-icon.svg";
import clsx from "clsx";

//
// types
//

export enum MessageType {
  Error = "error",
  Warning = "warning",
  Success = "success",
  Info = "info",
}
export interface Message {
  type: MessageType;
  description?: string;
  title: string;
}
type StyleItem = string[];
type MessageTypeStyle = { [K in MessageType]: {
  background: StyleItem;
  text: StyleItem;
} };

//
// component
//

// Define styles for all messagge types
const styles: MessageTypeStyle = {
  error: {
    background: ["tw-bg-semantics-error10-light tw-dark:tw-bg-semantics-error10-dark"],
    text: ["tw-text-semantics-error-light tw-dark:tw-text-semantics-error-dark"]
  },
  warning: {
    background: ["tw-bg-semantics-warning10-light tw-dark:tw-bg-semantics-warning10-dark"],
    text: ["tw-text-semantics-warning-light tw-dark:tw-text-semantics-warning-dark"]
  },
  success: {
    background: ["tw-bg-semantics-success10-light tw-dark:tw-bg-semantics-success10-dark"],
    text: ["tw-text-semantics-success-light tw-dark:tw-text-semantics-success-dark"]
  },
  info: {
    background: ["tw-bg-semantics-informational10-light tw-dark:tw-bg-semantics-informational10-dark"],
    text: ["tw-text-semantics-informational-light tw-dark:tw-text-semantics-informational-dark"]
  }
};

// Alert Props
export type AlertsProps = {
  messages?: Message[];
};
export function Alerts({
  messages = undefined
}: AlertsProps) {
  return <>
      {messages && <div className="tw-flex tw-w-full tw-flex-col tw-gap-4">
          {messages.map((message, i) => {
        return <div key={i} className={clsx("tw-min-h-12 tw-rounded tw-px-2 tw-py-3", styles[message.type].background)}>
                <div className={clsx("tw-flex tw-gap-x-1", message.description ? "tw-items-start" : "tw-items-center")}>
                  <div className={clsx("tw-size-6 tw-shrink-0 ", message.description ? "tw-px-1" : "tw-p-1", styles[message.type].text)}>
                    {message.type === "warning" && <WarningIcon />}
                    {message.type === "error" && <WarningIcon />}
                    {message.type === "success" && <SuccessIcon />}
                    {message.type === "info" && <InfoIcon />}
                  </div>
                  <div className="ml-3 flex-1 md:flex md:justify-between">
                    <p className={clsx("tw-text-14px-medium", styles[message.type].text)}>
                      {message.title}
                    </p>
                    {message.description && <p className={clsx("tw-mt-2", "tw-text-12px-regular", styles[message.type].text)}>
                        {message.description}
                      </p>}
                  </div>
                </div>
              </div>;
      })}
        </div>}
    </>;
}