//
// isTrialStillValid.ts
//

/**
 * Checks if the trial period is still valid based on the environment variable
 * `NEXT_PUBLIC_STRIPE_TRIAL_END_DATE`.
 *
 * The function follows these steps:
 * 1. Retrieves the trial end date from the environment variable.
 * 2. If the variable is not defined or invalid, return `false`.
 * 3. Converts the current browser time to UTC.
 * 4. Calculates a date that is 2 days in the future from the current UTC time.
 * 5. Compares the trial end date against this threshold.
 * 6. Returns `true` if the trial is still valid, otherwise `false`.
 *
 * @returns {boolean} - `true` if the trial end date is at least 2 days in the future, otherwise `false`.
 */
export function isTrialStillValid() {
  // Retrieve the trial end date from the environment variable
  const trialEndDateStr = process.env.NEXT_PUBLIC_STRIPE_TRIAL_END_DATE;

  // If the environment variable is not set, return false
  if (!trialEndDateStr) {
    return false;
  }

  try {
    // Parse the trial end date string into a Date object
    const trialEndDate = new Date(trialEndDateStr);

    // Check if the parsed date is invalid
    if (isNaN(trialEndDate.getTime())) {
      return false; // Invalid date format
    }

    // Get the current date and convert it to UTC
    const now = new Date();
    const nowUTC = new Date(
      Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        now.getUTCHours(),
        now.getUTCMinutes(),
        now.getUTCSeconds(),
        now.getUTCMilliseconds()
      )
    );

    // Calculate a date that is 2 days in the future
    const twoDaysFromNow = new Date(nowUTC);
    twoDaysFromNow.setUTCDate(twoDaysFromNow.getUTCDate() + 2);

    // Return true if the trial end date is at least 2 days in the future
    return trialEndDate >= twoDaysFromNow;
  } catch {
    // If an error occurs during date processing, return false
    return false;
  }
}
