//
// app.tsx
//

import { useGetCurrentOrganizationId } from "@custom-hooks/organizations";
import { useGetUserSession } from "@custom-hooks/user";
import { useSetError } from "@custom-hooks/useSetError";
import { useSetSentryContext } from "@custom-hooks/useSetSentryContext";
import useTheme from "@custom-hooks/useTheme";
import { CacheProvider, EmotionCache } from "@emotion/react";
import { analyzeError } from "@lib/client-side";
import createEmotionCache from "@lib/createEmotionCache";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AppStateProvider } from "@state/AppStateProvider";
import { ThemeProvider } from "@state/ThemeContext";
import { UppyProvider } from "@state/UppyProvider";
import createTheme from "@theme";
import ErrorBoundary from "@tw-components/error/ErrorBoundary";
import "@vendor/jvectormap.css";
import "@vendor/perfect-scrollbar.css";
import { NextPage } from "next";
import { AppProps } from "next/app";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { SWRConfig } from "swr";
import "./style.css";

// Define a custom type for NextPage to include getLayout
type NextPageWithLayout = NextPage & {
  getLayout?: (page: React.ReactNode) => React.ReactNode;
};

// Extend AppProps to use the custom NextPageWithLayout type
type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

// Client-side Emotion cache setup
const clientSideEmotionCache = createEmotionCache();

// PostHog initialization (only client-side for Next.js SSR)
if (typeof window !== "undefined") {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY || "", {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://app.posthog.com",
    loaded: posthog => {
      if (process.env.NEXT_PUBLIC_VERCEL_ENV === "development") posthog.debug();
    },
    autocapture: false,
    capture_pageview: false,
    before_send: event => {
      if (process.env.NEXT_PUBLIC_VERCEL_ENV === "development") {
        console.log(`
          posthog [debug]: Event captured! (Won't be sent in the development environment)
          -----------------------------------
          Event: ${event?.event}
          Properties: ${event?.properties ? JSON.stringify(event.properties, null, 2) : "No properties"}
          -----------------------------------
        `);
        return null;
      }
      return event;
    }
  });
}

/**
 * Main App component to initialize application providers and configurations.
 * Sets up Emotion cache, PostHog, theme, error handling, and other necessary contexts.
 *
 * @param Component - The main component to render.
 * @param emotionCache - Optional Emotion cache; defaults to client-side cache.
 * @param pageProps - Props to be passed to the main component.
 * @returns {JSX.Element} The rendered application.
 */
function App({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps
}: AppPropsWithLayout & {
  emotionCache?: EmotionCache;
}) {
  // Get theme and set layout for the component
  const {
    theme
  } = useTheme();
  const getLayout = Component.getLayout ?? ((page: React.ReactNode) => page);

  // Error handling state and functions
  const [error, setError] = useState<Error | null>(null);
  useSetError(error);

  // Retrieve user data for PostHog analytics
  const {
    data: session
  } = useGetUserSession();
  const organizationId = useGetCurrentOrganizationId();

  // Identify user in PostHog
  useEffect(() => {
    if (session?.user && organizationId) {
      posthog.identify(session.user.id, {
        user_email: session.user.email,
        organization_id: organizationId
      });
    }
  }, [session, organizationId]);
  useSetSentryContext();
  return <PostHogProvider client={posthog} data-sentry-element="PostHogProvider" data-sentry-component="App" data-sentry-source-file="_app.tsx">
      <CacheProvider value={emotionCache} data-sentry-element="CacheProvider" data-sentry-source-file="_app.tsx">
        <HelmetProvider data-sentry-element="HelmetProvider" data-sentry-source-file="_app.tsx">
          <Helmet titleTemplate="%s" defaultTitle="SQLite Cloud Dashboard" data-sentry-element="Helmet" data-sentry-source-file="_app.tsx" />
          <LocalizationProvider dateAdapter={AdapterDateFns} data-sentry-element="LocalizationProvider" data-sentry-source-file="_app.tsx">
            <MuiThemeProvider theme={createTheme(theme)} data-sentry-element="MuiThemeProvider" data-sentry-source-file="_app.tsx">
              <ErrorBoundary data-sentry-element="ErrorBoundary" data-sentry-source-file="_app.tsx">
                <SWRConfig value={{
                onErrorRetry: (error, key, config, revalidate, {
                  retryCount
                }) => {
                  const result = analyzeError(error);
                  //TODO:We could retry indefinitely but increasin the retry timeout based on retryCount
                  // Report the error when retryCount is equal to 10
                  if (result.reportError && retryCount >= 10) {
                    setError(error);
                    return;
                  }
                  // Retry with 3 seconds between retries
                  if (!result.retry) return;
                  setTimeout(() => revalidate({
                    retryCount
                  }), 3000);
                }
              }} data-sentry-element="SWRConfig" data-sentry-source-file="_app.tsx">
                  {getLayout(<Component {...pageProps} />)}
                </SWRConfig>
              </ErrorBoundary>
            </MuiThemeProvider>
          </LocalizationProvider>
        </HelmetProvider>
      </CacheProvider>
    </PostHogProvider>;
}

/**
 * Higher-order component to provide theme and state contexts to the app.
 *
 * @param Component - The main App component to wrap.
 * @returns {JSX.Element} The App component wrapped with providers.
 */
const withThemeProvider = (Component: typeof App) => {
  const AppWithThemeProvider = (props: AppProps) => <ThemeProvider data-sentry-element="ThemeProvider" data-sentry-component="AppWithThemeProvider" data-sentry-source-file="_app.tsx">
      <AppStateProvider data-sentry-element="AppStateProvider" data-sentry-source-file="_app.tsx">
        <UppyProvider data-sentry-element="UppyProvider" data-sentry-source-file="_app.tsx">
          <Component {...props} data-sentry-element="Component" data-sentry-source-file="_app.tsx" />
        </UppyProvider>
      </AppStateProvider>
    </ThemeProvider>;
  AppWithThemeProvider.displayName = "AppWithThemeProvider";
  return AppWithThemeProvider;
};
export default withThemeProvider(App);