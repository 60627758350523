"use client";

import * as Headless from "@headlessui/react";
import DownChevronIcon from "@layouts/svg-icon/down-chevron-icon.svg";
import clsx from "clsx";
import { LayoutGroup } from "framer-motion";
import React, { Fragment, forwardRef, useId } from "react";
import { TouchTarget } from "./Button";
import { Link } from "./Link";
export function Sidebar({
  className,
  ...props
}: React.ComponentPropsWithoutRef<"nav">) {
  return <nav {...props} className={clsx(className, "tw-flex tw-h-full tw-min-h-0 tw-flex-col tw-gap-y-8 tw-p-3")} data-sentry-component="Sidebar" data-sentry-source-file="Sidebar.tsx" />;
}
export function SidebarHeader({
  className,
  ...props
}: React.ComponentPropsWithoutRef<"div">) {
  return <div {...props} className={clsx(className, "tw-flex tw-flex-col [&>[data-slot=section]+[data-slot=section]]:tw-mt-2.5")} data-sentry-component="SidebarHeader" data-sentry-source-file="Sidebar.tsx" />;
}
export function SidebarBody({
  className,
  ...props
}: React.ComponentPropsWithoutRef<"div">) {
  return <div {...props} className={clsx(className, "tw-flex tw-flex-1 tw-flex-col tw-overflow-y-auto tw-p-[1px] [&>[data-slot=section]+[data-slot=section]]:tw-mt-8")} data-sentry-component="SidebarBody" data-sentry-source-file="Sidebar.tsx" />;
}
export function SidebarFooter({
  className,
  ...props
}: React.ComponentPropsWithoutRef<"div">) {
  return <div {...props} className={clsx(className, "tw-flex tw-flex-col [&>[data-slot=section]+[data-slot=section]]:tw-mt-2.5")} data-sentry-component="SidebarFooter" data-sentry-source-file="Sidebar.tsx" />;
}
export function SidebarSection({
  className,
  ...props
}: React.ComponentPropsWithoutRef<"div">) {
  let id = useId();
  return <LayoutGroup id={id} data-sentry-element="LayoutGroup" data-sentry-component="SidebarSection" data-sentry-source-file="Sidebar.tsx">
      <div {...props} data-slot="section" className={clsx(className, "tw-flex tw-flex-col tw-gap-2")} />
    </LayoutGroup>;
}
export function SidebarDivider({
  className,
  ...props
}: React.ComponentPropsWithoutRef<"hr">) {
  return <hr {...props} className={clsx(className, "tw-dark:tw-border-interface-divider-dark tw-my-4 tw-border-t tw-border-interface-divider-light lg:-tw-mx-4")} data-sentry-component="SidebarDivider" data-sentry-source-file="Sidebar.tsx" />;
}
export function SidebarSpacer({
  className,
  ...props
}: React.ComponentPropsWithoutRef<"div">) {
  return <div aria-hidden="true" {...props} className={clsx(className, "tw-mt-8 tw-flex-1")} data-sentry-component="SidebarSpacer" data-sentry-source-file="Sidebar.tsx" />;
}
export function SidebarHeading({
  className,
  ...props
}: React.ComponentPropsWithoutRef<"h3">) {
  return <h3 {...props} className={clsx(className, "tw-text-xs/6 tw-dark:tw-text-zinc-400 tw-mb-1 tw-px-2 tw-font-medium tw-text-zinc-500")} data-sentry-component="SidebarHeading" data-sentry-source-file="Sidebar.tsx" />;
}

// #region SidebarItem

// SidebarItem types
type VariantsSidebarItem = "base" | "inner";
type StyleItemSidebarItem = string[];
type VariantStyleSidebarItem = { [K in VariantsSidebarItem]: {
  size: StyleItemSidebarItem;
  fontStyle: StyleItemSidebarItem;
  colors: StyleItemSidebarItem;
  exeperimentalColors: StyleItemSidebarItem;
} };

// SidebarItem styles
const variantsStyleSidebarItem: VariantStyleSidebarItem = {
  base: {
    size: ["tw-h-8 data-[collapsed]:tw-w-8"],
    fontStyle: [" tw-text-14px-medium"],
    colors: [
    // Text light default
    "tw-text-text-title-light",
    // Text dark default
    "tw-dark:tw-text-text-title-dark",
    // Background light hover
    "data-[hover]:tw-bg-interface-innerCard-light",
    // Background dark hover
    "tw-dark:data-[hover]:tw-bg-interface-innerCard-dark",
    // Text light current
    "data-[current]:tw-text-brandBlues-brandDark-light",
    // Text dark current
    "tw-dark:data-[current]:tw-text-brandBlues-brandDark-dark",
    // Background light current
    "data-[current]:tw-bg-brandBlues-ice-light",
    // Background dark current
    "tw-dark:data-[current]:tw-bg-brandBlues-ice-dark",
    // Text light disabled
    "data-[disabled]:tw-text-interface-gray-light",
    // Text dark disabled
    "tw-dark:data-[disabled]:tw-text-interface-gray-dark",
    // Background light disabled
    "data-[disabled]:tw-bg-interface-card-light",
    // Background dark disabled
    "tw-dark:data-[disabled]:tw-bg-interface-card-dark"],
    exeperimentalColors: [
    // Text light default
    "tw-text-text-subTitle-light",
    // Text dark default
    "tw-dark:tw-text-text-title-dark",
    // Background light default
    "tw-bg-semantics-error10-light",
    // Background dark default
    "tw-dark:tw-bg-semantics-error10-dark",
    // Background light hover
    "data-[hover]:tw-bg-semantics-error50-light",
    // Background dark hover
    "tw-dark:data-[hover]:tw-bg-semantics-error50-light",
    // Text light current
    "data-[current]:tw-text-interface-card-light",
    // Text dark current
    "tw-dark:data-[current]:tw-text-interface-card-dark",
    // Background light current
    "data-[current]:tw-bg-semantics-error-light",
    // Background dark current
    "tw-dark:data-[current]:tw-bg-semantics-error-dark",
    // Text light disabled
    "data-[disabled]:tw-text-interface-gray-light",
    // Text dark disabled
    "tw-dark:data-[disabled]:tw-text-interface-gray-dark",
    // Background light disabled
    "data-[disabled]:tw-bg-interface-card-light",
    // Background dark disabled
    "tw-dark:data-[disabled]:tw-bg-interface-card-dark"]
  },
  inner: {
    size: ["tw-h-8 tw-w-[calc(100%-0.5rem)] data-[collapsed]:tw-w-8"],
    fontStyle: [" tw-text-14px-regular"],
    colors: [
    // Text light default
    "tw-text-text-subTitle-light",
    // Text dark default
    "tw-dark:tw-text-text-subTitle-dark",
    // Background light hover
    "data-[hover]:tw-bg-interface-innerCard-light",
    // Background dark hover
    "tw-dark:data-[hover]:tw-bg-interface-innerCard-dark",
    // Text light current
    "data-[current]:tw-text-brandBlues-brandDark-light",
    // Text dark current
    "tw-dark:data-[current]:tw-text-brandBlues-brandDark-dark",
    // Background light current
    "data-[current]:tw-bg-brandBlues-ice-light",
    // Background dark current
    "tw-dark:data-[current]:tw-bg-brandBlues-ice-dark",
    // Text light disabled
    "data-[disabled]:tw-text-interface-gray-light",
    // Text dark disabled
    "tw-dark:data-[disabled]:tw-text-interface-gray-dark",
    // Background light disabled
    "data-[disabled]:tw-bg-interface-card-light",
    // Background dark disabled
    "tw-dark:data-[disabled]:tw-bg-interface-card-dark"],
    exeperimentalColors: [
    // Text light default
    "tw-text-text-subTitle-light",
    // Text dark default
    "tw-dark:tw-text-text-subTitle-dark",
    // Background light default
    "tw-bg-semantics-error10-light",
    // Background dark default
    "tw-dark:tw-bg-semantics-error10-dark",
    // Background light hover
    "data-[hover]:tw-bg-semantics-error50-light",
    // Background dark hover
    "tw-dark:data-[hover]:tw-bg-semantics-error50-light",
    // Text light current
    "data-[current]:tw-text-interface-card-light",
    // Text dark current
    "tw-dark:data-[current]:tw-text-interface-card-dark",
    // Background light current
    "data-[current]:tw-bg-semantics-error-light",
    // Background dark current
    "tw-dark:data-[current]:tw-bg-semantics-error-dark",
    // Text light disabled
    "data-[disabled]:tw-text-interface-gray-light",
    // Text dark disabled
    "tw-dark:data-[disabled]:tw-text-interface-gray-dark",
    // Background light disabled
    "data-[disabled]:tw-bg-interface-card-light",
    // Background dark disabled
    "tw-dark:data-[disabled]:tw-bg-interface-card-dark"]
  }
};

// SidebarItem Props
export type SidebarItemProps = {
  current?: boolean;
  disabled?: boolean;
  collapsed?: boolean;
  className?: string;
  icon?: React.ReactNode;
  variant?: VariantsSidebarItem;
  label?: string;
  experimental?: boolean;
  hasSubItems?: boolean;
  expanded?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement> | undefined;
} & (Omit<Headless.ButtonProps, "as" | "className" | "onClick"> | Omit<React.ComponentPropsWithoutRef<typeof Link>, "className" | "onClick">);

// SidebarItem definition
export const SidebarItem = forwardRef(function SidebarItem({
  variant = "base",
  current,
  disabled = false,
  collapsed = false,
  className,
  icon = undefined,
  hasSubItems = false,
  label,
  experimental = false,
  expanded = false,
  onClick = undefined,
  ...props
}: SidebarItemProps, ref: React.ForwardedRef<HTMLAnchorElement | HTMLButtonElement>) {
  let classes = clsx(
  // Base large
  "tw-relative tw-z-[56000] tw-flex tw-w-full tw-items-center tw-gap-2 tw-rounded-lg tw-px-2 tw-py-2 tw-text-left",
  // Base collpased
  "data-[collapsed]:tw-gap-0  data-[collapsed]:tw-py-2 data-[collapsed]:tw-pl-2",
  // Font style
  "tw-leading-normal", variantsStyleSidebarItem[variant].fontStyle,
  // Size
  variantsStyleSidebarItem[variant].size,
  // Colors
  experimental ? variantsStyleSidebarItem[variant].exeperimentalColors : variantsStyleSidebarItem[variant].colors,
  // Transition
  "tw-transition-colors tw-duration-200 tw-ease-in-out",
  // Leading icon/icon-only
  "data-[slot=icon]:*:tw-size-[0.9375rem] data-[slot=icon]:*:tw-shrink-0",
  // Trailing icon (down chevron or similar)
  "data-[slot=icon]:last:*:tw-ml-auto data-[slot=icon]:last:*:tw-size-3",
  // Avatar
  "data-[slot=avatar]:*:-tw-m-0.5 data-[slot=avatar]:*:tw-size-7 data-[slot=avatar]:*:[--tw-ring-opacity:10%] sm:data-[slot=avatar]:*:tw-size-6",
  // Focus light
  "focus:tw-outline-none data-[focus]:tw-outline data-[focus]:tw-outline-1 data-[focus]:tw-outline-offset-0 data-[focus]:tw-outline-brandBlues-brandDark-light",
  // Focus dark
  "tw-dark:data-[focus]:tw-outline-brandBlues-brandDark-dark",
  // Transition
  "tw-transition-colors tw-duration-200 data-[hover]:tw-transition-colors data-[hover]:tw-duration-200");

  // When the sidebar item is disabled or href is undefired remove href from props to render Headless.Button that handle the disable state
  if (props && "href" in props && (disabled || !props.href)) delete (props as {
    href?: string;
  }).href;
  return <span className={clsx(className, "tw-relative", variant === "inner" ? "tw-ml-4" : "")}>
      {"href" in props ? <Headless.CloseButton as={Fragment} ref={ref}>
          {({
        hover,
        active
      }) => <Link className={classes} {...props} data-current={current ? "true" : undefined} data-collapsed={collapsed ? "true" : undefined}>
              <TouchTarget>
                {icon}
                <SidebarLabel>{!collapsed && <>{label}</>}</SidebarLabel>
              </TouchTarget>
              {collapsed && hover && <SidebarTooltipLabel>{label}</SidebarTooltipLabel>}
            </Link>}
        </Headless.CloseButton> : <>
          <Headless.Button onClick={onClick} disabled={disabled} className={clsx("tw-cursor-default", classes)} data-current={current ? "true" : undefined} data-collapsed={collapsed ? "true" : undefined} ref={ref}>
            {({
          hover,
          active
        }) => <TouchTarget>
                {icon}
                <SidebarLabel>{!collapsed && <>{label}</>}</SidebarLabel>
                {collapsed && hover && <SidebarTooltipLabel>{label}</SidebarTooltipLabel>}
                {hasSubItems && <DownChevronIcon className={clsx("tw-transition-transform tw-duration-150", expanded ? "tw-rotate-180" : "tw-rotate-0")} />}
              </TouchTarget>}
          </Headless.Button>
        </>}
    </span>;
});

// #endregion

export function SidebarLabel({
  className,
  ...props
}: React.ComponentPropsWithoutRef<"span">) {
  return <span {...props} className={clsx(className, "tw-truncate")} data-sentry-component="SidebarLabel" data-sentry-source-file="Sidebar.tsx" />;
}
export function SidebarTooltipLabel({
  className,
  ...props
}: React.ComponentPropsWithoutRef<"span">) {
  return <span {...props} className={clsx(className,
  // "tw-truncate",
  "tw-fixed tw-left-12", "tw-mr-4 tw-rounded-3xl tw-border tw-px-3 tw-py-1", "tw-text-12px-regular", "tw-border-brandBlues-ice-light tw-bg-brandBlues-pale-light tw-text-brandBlues-brandDark-light", "tw-dark:tw-bg-brandBlues-pale-dark tw-dark:tw-text-brandBlues-brandDark-dark tw-dark:tw-border-brandBlues-ice-dark")} data-sentry-component="SidebarTooltipLabel" data-sentry-source-file="Sidebar.tsx" />;
}