/**
 * Generates a style object for table transition based on hover state.
 *
 * @param {boolean} isHovered - Indicates whether the table is in a hovered state.
 * @returns {{ opacity: number; transition: string; pointerEvents: string }} A style object with transition properties.
 */
export const tableTransition = (
  isHovered: boolean
): { opacity: number; transition: string; pointerEvents: string } => {
  return {
    opacity: isHovered ? 1 : 0,
    transition: isHovered ? "opacity 0.5s" : "opacity 0.1s",
    pointerEvents: isHovered ? "auto" : "none",
  };
};
