/**
 * Validates a string to ensure it only contains URL-safe characters and meets a minimum length.
 *
 * @param {string} string - The string to validate.
 * @param {number} minLength - The minimum length required for the string (default: 6).
 * @param {boolean} allowedChar - Whether to enforce allowed URL-safe characters (default: true).
 * @returns {{ valid: boolean; messages: string[] }} An object containing a `valid` boolean and `messages` array.
 */
export function validateStringnonEncodingCharsInUrl(
  string: string,
  minLength: number = 6,
  allowedChar: boolean = true
): { valid: boolean; messages: string[] } {
  // Initialize the validation result with default values
  const validation = { valid: true, messages: [] as string[] };

  // Check if the string meets the minimum length requirement
  if (string.length < minLength) {
    validation.valid = false;
    validation.messages.push(`must be at least ${minLength} characters long`);
  }

  if (allowedChar) {
    // Regular expression to match characters that do not require URL encoding
    const nonEncodingCharsRegex = /^[a-zA-Z0-9-_.~]+$/;

    // Validate that the string contains only URL-safe characters
    if (!nonEncodingCharsRegex.test(string)) {
      validation.valid = false;
      validation.messages.push(
        "allowed characters (a-z, A-Z, 0-9, and - . _ ~)"
      );
    }
  }

  // Return the validation results
  return validation;
}
