//
// useGetProjectStorageInfo.ts
//

import { useGetFunctionalityDetails } from "@custom-hooks/billing";
import { useGetProjectLookupKey } from "@custom-hooks/billing/hooks/useGetProjectLookupKey";
import { useGetProjectDatabases } from "@custom-hooks/databases";
import { AddonsFunctionality } from "@data-types/billing-types";
import {
  FetchProjectStorageInfoResult,
  ProjectStorageInfo,
} from "@data-types/projects-types";
import { gigabytesToBytes } from "@lib/iso-utils";
import { useGetProjectUsedStorage } from "./useGetProjectUsedStorage";

/**
 * Custom hook to fetch and calculate storage usage for a specific project, with a warning threshold.
 *
 * @param {string} projectId - The ID of the project to fetch storage information for.
 * @param {number} [warningThreshold=90] - The percentage threshold at which to trigger a warning (default is 90%).
 * @param {number} [refreshInterval] - Optional interval (in milliseconds) for refreshing project storage info data.
 *
 * @returns {Object} - The result object with the storage information, UI state indicators, and a function to manage fetched data.
 */
export function useGetProjectStorageInfo(
  projectId: string,
  warningThreshold: number = 90,
  refreshInterval?: number
): FetchProjectStorageInfoResult<ProjectStorageInfo> {
  const projectLookupKey = useGetProjectLookupKey(projectId);

  const { limit: storageInGigabytes } = useGetFunctionalityDetails(
    projectLookupKey,
    AddonsFunctionality.STORAGE_IN_GB
  );

  // The total space actually provided is larger than the one specified in the plan associated with the project,
  // because some space is occupied by system files
  // and especially by space dedicated to backups.
  // Therefore, on the UI, we use the space specified in the plan as the available space.
  const availableStorage = `${storageInGigabytes ?? 0} GB`;
  const availableStorageInBytes = gigabytesToBytes(storageInGigabytes ?? 0);

  const {
    data: databases,
    hasData: availableDatabases,
    isLoading: loadingDatabases,
    isValidating: validatingDatabases,
    isError: errorDatabases,
    showLoader: showLoaderDatabases,
    emptyData: emptyDataDatabases,
    mutate: updatedProjectDatabases,
  } = useGetProjectDatabases(projectId, refreshInterval);

  let {
    usedStorageInBytes,
    usedStorage,
    hasData: availableUsedStorageInfo,
    isLoading: loadingUsedStorageInfo,
    isValidating: validatingUsedStorageInfo,
    isError: errorUsedStorageInfo,
    showLoader: showLoaderUsedStorageInfo,
    emptyData: emptyDataUsedStorageInfo,
    mutate: updatedUsedStorageInfo,
  } = useGetProjectUsedStorage({
    projectId,
    includedKeys: ["db_size", "wal_size"],
    // includedKeys: ["db_size", "shm_size", "wal_size"], TODO:TIZIANO: Temporary excluded shm_size for a bug in the core
    options: { refreshInterval: refreshInterval },
  });

  // Calculate storage usage if both plan and database data are available
  if (
    projectLookupKey &&
    storageInGigabytes &&
    availableUsedStorageInfo &&
    (availableDatabases || emptyDataDatabases)
  ) {
    if (emptyDataDatabases) {
      usedStorageInBytes = 0;
      usedStorage = "0 MB";
    }

    // Calculate used storage as a percentage
    const usedStoragePercentage =
      usedStorageInBytes !== undefined && usedStorageInBytes !== null
        ? (usedStorageInBytes / availableStorageInBytes) * 100
        : undefined;

    // Determine if usage exceeds the warning threshold
    const warning = usedStoragePercentage
      ? usedStoragePercentage > warningThreshold
      : false;

    return {
      data: {
        availableStorage,
        availableStorageInBytes,
        usedStorage,
        usedStorageInBytes: usedStorageInBytes,
        usedStoragePercentage,
        warning,
      },
      isLoading: false,
      isError: false,
      isValidating: false,
      showLoader: false,
      hasData: true,
      emptyData: false,
      noDatabases: emptyDataDatabases,
    };
  }

  return {
    data: {
      availableStorage,
      availableStorageInBytes,
      usedStorage: undefined,
      usedStorageInBytes: undefined,
      usedStoragePercentage: undefined,
      warning: false,
    },
    isLoading: loadingUsedStorageInfo || loadingDatabases,
    isError: errorUsedStorageInfo || errorDatabases,
    isValidating: validatingUsedStorageInfo || validatingDatabases,
    showLoader: showLoaderUsedStorageInfo || showLoaderDatabases,
    hasData: projectLookupKey !== null && availableUsedStorageInfo,
    emptyData: projectLookupKey !== null || emptyDataUsedStorageInfo,
    noDatabases: emptyDataDatabases,
    mutate: updatedUsedStorageInfo,
  };
}
