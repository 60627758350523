import * as Headless from "@headlessui/react";
import { Button as HeadlessUIButton } from "@headlessui/react";
import CloseIcon from "@layouts/svg-icon/dialog-close-icon.svg";
import clsx from "clsx";
import type React from "react";
import { Fragment } from "react";
import { Alerts, Message } from "./Alerts";
import { Button, ButtonProps } from "./Button";
const sizes = {
  xs: "sm:tw-w-64",
  sm: "sm:tw-w-80",
  md: "sm:tw-w-96",
  lg: "sm:tw-w-[32rem]",
  xl: "sm:tw-w-[40rem]",
  "2xl": "sm:tw-w-[48rem]",
  "3xl": "sm:tw-w-[56rem]",
  "4xl": "sm:tw-w-[64rem]",
  "5xl": "sm:tw-w-[72rem]"
};
export type SideSheetProps = {
  size?: keyof typeof sizes;
  className?: string;
  children: React.ReactNode;
  beforeLeave?: () => void;
  afterLeave?: () => void;
  beforeEnter?: () => void;
  afterEnter?: () => void;
} & Omit<Headless.DialogProps, "as" | "className">;
export const SideSheet = ({
  open,
  size = "lg",
  className,
  children,
  onClose,
  beforeLeave,
  afterLeave,
  beforeEnter,
  afterEnter
}: SideSheetProps) => {
  return <Headless.Transition show={open} as={Fragment} beforeLeave={beforeLeave} afterLeave={afterLeave} beforeEnter={beforeEnter} afterEnter={afterEnter} data-sentry-element="unknown" data-sentry-component="SideSheet" data-sentry-source-file="SideSheet.tsx">
      <Headless.Dialog as="div" className="tw-relative tw-z-10" onClose={onClose} data-sentry-element="unknown" data-sentry-source-file="SideSheet.tsx">
        <Headless.TransitionChild as={Fragment} enter="tw-transition-opacity tw-duration-700" enterFrom="tw-opacity-0" enterTo="tw-opacity-100" leave="tw-transition-opacity tw-duration-700" leaveFrom="tw-opacity-100" leaveTo="tw-opacity-0" data-sentry-element="unknown" data-sentry-source-file="SideSheet.tsx">
          <Headless.DialogBackdrop className="tw-fixed tw-inset-0 tw-bg-zinc-950/25 dark:tw-bg-zinc-950/50" data-sentry-element="unknown" data-sentry-source-file="SideSheet.tsx" />
        </Headless.TransitionChild>

        <div className="tw-fixed tw-inset-0 tw-flex tw-items-stretch">
          <Headless.TransitionChild as={Fragment} enter="tw-transform tw-transition tw-ease-in-out tw-duration-700" enterFrom="tw-translate-x-full tw-translate-y-0" enterTo="tw-translate-y-0 tw-translate-x-0" leave="tw-transform tw-transition tw-ease-in-out tw-duration-700" leaveFrom="tw-translate-y-0 tw-translate-x-0" leaveTo="tw-translate-x-full tw-translate-y-0" data-sentry-element="unknown" data-sentry-source-file="SideSheet.tsx">
            <Headless.DialogPanel className={clsx("tw-fixed tw-bg-white tw-shadow-lg", "tw-rounded-l-2xl", "tw-h-full tw-w-full tw-p-6", "tw-right-0", sizes[size], className)} data-sentry-element="unknown" data-sentry-source-file="SideSheet.tsx">
              {children}
            </Headless.DialogPanel>
          </Headless.TransitionChild>
        </div>
      </Headless.Dialog>
    </Headless.Transition>;
};
export function SideSheetTitle({
  className,
  ...props
}: {
  className?: string;
} & Omit<Headless.DialogTitleProps, "as" | "className">) {
  return <Headless.DialogTitle {...props} className={clsx(className, "tw-py-2 tw-text-18px-semiBold tw-text-text-title-light dark:tw-text-text-title-dark")} data-sentry-element="unknown" data-sentry-component="SideSheetTitle" data-sentry-source-file="SideSheet.tsx" />;
}
export function SideSheetBody({
  ...props
}: React.ComponentPropsWithoutRef<"div">) {
  return <div {...props} data-sentry-component="SideSheetBody" data-sentry-source-file="SideSheet.tsx" />;
}
export function SideSheetActions({
  className,
  ...props
}: React.ComponentPropsWithoutRef<"div">) {
  return <div {...props} className={clsx(className, "tw-flex tw-flex-col-reverse tw-items-center *:tw-w-full", "tw-gap-3 sm:tw-flex-row sm:tw-justify-between sm:*:tw-w-auto")} data-sentry-component="SideSheetActions" data-sentry-source-file="SideSheet.tsx" />;
}
export type GenericSideSheetProps = {
  sheetClassName?: SideSheetProps["className"];
  title?: string;
  body?: React.ReactNode;
  alertMessage?: Message;
  submitButtonProps?: ButtonProps;
  secondaryButtonProps?: ButtonProps;
  onClose?: () => void;
} & Omit<SideSheetProps, "children" | "onClose">;
export function GenericSideSheet({
  sheetClassName,
  title,
  body,
  alertMessage,
  submitButtonProps,
  secondaryButtonProps,
  onClose,
  ...props
}: GenericSideSheetProps) {
  return <SideSheet className={sheetClassName} {...props} onClose={(submitButtonProps?.showLoader ? undefined : onClose) || (() => {})} data-sentry-element="SideSheet" data-sentry-component="GenericSideSheet" data-sentry-source-file="SideSheet.tsx">
      <div className="tw-flex tw-h-full tw-flex-col tw-gap-6">
        <div className="tw-flex tw-flex-1 tw-flex-col tw-gap-6 tw-overflow-auto">
          <div className="tw-flex tw-flex-row tw-items-center tw-justify-between tw-gap-4">
            <SideSheetTitle data-sentry-element="SideSheetTitle" data-sentry-source-file="SideSheet.tsx">{title}</SideSheetTitle>

            {!submitButtonProps?.showLoader && onClose && <HeadlessUIButton onClick={onClose}>
                <CloseIcon />
              </HeadlessUIButton>}
          </div>

          {alertMessage && <Alerts messages={[alertMessage]} />}

          <div className="tw-h-[1px] tw-w-full tw-bg-interface-divider-light dark:tw-bg-interface-divider-dark" />

          <SideSheetBody data-sentry-element="SideSheetBody" data-sentry-source-file="SideSheet.tsx">{body}</SideSheetBody>
        </div>

        <div className="tw-sticky tw-bottom-0 tw-flex tw-flex-col tw-gap-6 tw-bg-white">
          <div className="tw-h-[1px] tw-w-full tw-bg-interface-divider-light dark:tw-bg-interface-divider-dark" />

          <SideSheetActions data-sentry-element="SideSheetActions" data-sentry-source-file="SideSheet.tsx">
            {secondaryButtonProps && <Button {...secondaryButtonProps} />}
            {submitButtonProps && <Button {...submitButtonProps} />}
          </SideSheetActions>
        </div>
      </div>
    </SideSheet>;
}