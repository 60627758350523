import * as Sentry from "@sentry/nextjs";
import { useEffect } from "react";
import { useGetCurrentProjectId } from "./projects";
import { useGetUserSession } from "./user";

export const useSetSentryContext = () => {
  const { data: session } = useGetUserSession();
  const currentProjectId = useGetCurrentProjectId();

  useEffect(() => {
    if (session?.user) {
      Sentry.setUser({ email: session.user.email, id: session.user.id });
    }
  }, [session]);

  useEffect(() => {
    if (currentProjectId) {
      Sentry.setContext("Project", { id: currentProjectId });
    }
  }, [currentProjectId]);
};
