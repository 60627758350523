/**
 * Analyzes an error object to determine if it should be reported or retried.
 *
 * @param {any} error - The error object to analyze.
 * @returns {{ reportError: boolean; retry: boolean }} An object indicating whether to report or retry the error.
 */
export function analyzeError(error: any): {
  reportError: boolean;
  retry: boolean;
} {
  let result = {
    reportError: true,
    retry: true,
  };

  // Extract additional error information if available
  const errorInfo = error.info ? error.info : undefined;

  if (errorInfo && errorInfo.endpoint && errorInfo.message) {
    const endpoint = errorInfo.endpoint;
    const message = errorInfo.message;

    // Future error filtering logic can be added here for specific endpoints or messages
    // Example:
    // const backupsEndpointRegex = /\/v1\/[a-zA-Z0-9]+\/backups/;
    // if (backupsEndpointRegex.test(endpoint)) {
    //   if (message.includes("An error occurred while looking for the node's address")) {
    //     result.reportError = false;
    //     result.retry = false;
    //   }
    // }
  }

  return result;
}
