/**
 * Builds a new URL by updating the query string based on specified parameters.
 *
 * @param {string} actualPathname - The base path of the URL.
 * @param {Record<string, string>} actualQuery - The existing query parameters as key-value pairs.
 * @param {string[]} excludedQuery - An array of query keys to exclude from the URL.
 * @param {string | string[]} updateQuery - The query key(s) to add or update in the URL.
 * @param {string | string[]} newValue - The new value(s) corresponding to the `updateQuery`.
 * @returns {string} The newly constructed URL with updated query parameters.
 */
export function buildUrlFromActualQuery(
  actualPathname: string,
  actualQuery: Record<string, string>,
  excludedQuery: string[],
  updateQuery: string | string[],
  newValue: string | string[]
): string {
  // Encode newValue for safe URL usage
  const encodedNewValue = encodeURIComponent(newValue as string);

  // Initialize the new URL with the base pathname and a "?" for query parameters
  let newUrl = `${actualPathname}?`;

  // Append existing query parameters, excluding any specified in excludedQuery
  Object.entries(actualQuery).forEach(([key, value]) => {
    if (!excludedQuery.includes(key)) {
      newUrl = `${newUrl}${key}=${value}&`;
    }
  });

  // Check if updateQuery and newValue are both arrays of the same length
  if (
    Array.isArray(updateQuery) &&
    Array.isArray(newValue) &&
    updateQuery.length === newValue.length
  ) {
    // Append each query-update pair to the URL
    updateQuery.forEach((query, index) => {
      newUrl = `${newUrl}${query}=${encodeURIComponent(newValue[index])}&`;
    });
  } else {
    // Append single updateQuery and encodedNewValue if applicable
    if (updateQuery && encodedNewValue) {
      newUrl = `${newUrl}${updateQuery}=${encodedNewValue}`;
    }
  }

  // Remove any trailing "?" or "&" from the constructed URL
  newUrl = newUrl.replace(/[?&]$/, "");

  // Return the completed URL
  return newUrl;
}
