//
// useGetProjectStatus.ts
// cdrehk1lik ctovcinxsz

import { useFetchData } from "@custom-hooks/fetch-data";
import { useGetProjectInfo } from "@custom-hooks/useApi";
import { ClientError } from "@data-types/client-error-types";
import { ProjectStatus } from "@data-types/projects-types";
import { ListStorage } from "@data-types/sqlitecloud-commands-type";
import * as Sentry from "@sentry/nextjs";
import useSWR, { mutate } from "swr";
import { useGetProjectRuntime } from "./useGetProjectRuntime";

/**
 * Custom hook to fetch and determine the status of a project.
 *
 * @param {string} projectId - The ID of the project.
 *
 * @returns {Object} The project status and loading state.
 * @returns {ProjectStatus | undefined} status - The determined status of the project.
 * @returns {boolean} isStatusLoading - Indicates if the project status is still being loaded.
 */
export function useGetProjectStatus({ projectId }: { projectId: string }) {
  const { data, isLoading: isProjectRuntimeLoading } = useGetProjectRuntime({
    projectId,
  });

  const { connectionStringApiKey, gatewayUrlHTTP } =
    useGetProjectInfo(projectId);

  const encodedQuery = encodeURIComponent("LIST STORAGE");

  const {
    isLoading: isStorageLoading,
    isError: isStorageError,
    isValidating: isStorageValidating,
  } = useFetchData<ListStorage>({
    fetchCondition:
      Boolean(gatewayUrlHTTP && connectionStringApiKey) &&
      data?.status === "running",
    source: "gateway",
    swrKey: {
      url: `${gatewayUrlHTTP}/v2/weblite/sql?sql=${encodedQuery}`,
      component: "useGetProjectStatus_list storage",
      auth: connectionStringApiKey,
      reportError: false,
    },
    options: {
      onErrorRetry(err, key, config, revalidate, { retryCount }) {
        if (retryCount >= 10) {
          // Store error globally per project ID
          mutate(`storageError-${projectId}`, true, { revalidate: false });

          err = err as typeof ClientError;
          const timestamp = new Date().toISOString();
          const errorMessage = `Failed to fetch from "${`${gatewayUrlHTTP}/v2/weblite/sql?sql=${encodedQuery}`}". Details: ${timestamp} - SWR Error occurred in "useGetProjectStatus"`;

          Sentry.captureException(new Error(errorMessage), {
            extra: { errorDetails: err.details },
          });
          return;
        }

        // Increment retry count and retry after 3 seconds
        setTimeout(() => revalidate({ retryCount }), 3000);
      },
    },
  });

  // Read global storage error per project ID
  const hasStorageError = useSWR(`storageError-${projectId}`).data ?? false;

  const status = (() => {
    if (projectId !== "cdrehk1lik" && projectId !== "ctovcinxsz") {
      switch (data?.status) {
        case "scaledToZero":
          return ProjectStatus.SCALED_TO_ZERO;
        case "deactivated":
          return ProjectStatus.DEACTIVATED;
        case "notReady":
          return ProjectStatus.NOT_READY;
        case "running": {
          if (isStorageLoading) {
            mutate(`storageError-${projectId}`, false, { revalidate: false });
            return undefined;
          } else {
            if (hasStorageError) {
              return ProjectStatus.RUNNING_BUT_NOT_WORKING;
            } else {
              return isStorageError
                ? undefined
                : ProjectStatus.RUNNING_AND_WORKING;
            }
          }
        }
      }
    } else {
      if (isStorageLoading) {
        return undefined;
      } else {
        if (hasStorageError) {
          return ProjectStatus.RUNNING_BUT_NOT_WORKING;
        } else {
          return isStorageError ? undefined : ProjectStatus.RUNNING_AND_WORKING;
        }
      }
    }
  })();

  return {
    status,
    isStatusLoading:
      isProjectRuntimeLoading ||
      isStorageLoading ||
      (!hasStorageError && isStorageError),
  };
}
