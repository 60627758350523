//
// useGetCurrentProjectId.ts
//

import { useGetCurrentOrganizationId } from "@custom-hooks/organizations";
import { useCustomRouter } from "@custom-hooks/useCustomRouter";
import { useGetUserProjectsByOrganizationId } from "./useGetUserProjectsByOrganizationId";

/**
 * Custom hook to retrieve the actual project ID based on query parameters and the user's project list.
 *
 * This hook checks if the `projectId` from the URL query exists within the user's projects.
 * If it does, the hook returns that `projectId`; otherwise, it returns `false` if the ID is not found,
 * or `undefined` if there are no projects or the `projectId` query parameter is missing.
 *
 * @returns {string | false | undefined} The actual project ID if it exists, `false` if the ID does not match
 * any project, or `undefined` if the project data is unavailable.
 */
export function useGetCurrentProjectId(): string | false | undefined {
  const organizationId = useGetCurrentOrganizationId();

  // Retrieve all user projects
  const { data: projects } = useGetUserProjectsByOrganizationId(organizationId);

  // Get query parameters from router
  const { query } = useCustomRouter();

  // Ensure projectId is a string
  const projectId =
    typeof query?.projectId === "string" ? query.projectId : undefined;

  // Determine actual project ID based on the projects list and query parameter
  const actualProjectId =
    projects && Array.isArray(projects) && projectId
      ? projects.some((obj) => obj.id === projectId)
        ? projectId
        : false
      : undefined;

  return actualProjectId;
}
