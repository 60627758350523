//
// useSendSupportEmail.ts
//

import { useEditData_v2 } from "@custom-hooks/edit-data";
import { useGetCurrentOrganizationId } from "@custom-hooks/organizations";
import { EditDataOpt, EditDataResult } from "@data-types/generic-hook-type";
import {
  SendSupportEmailPayload,
  SendSupportEmailResponse,
} from "@data-types/support-types";

/**
 * Custom hook for sending support emails.
 *
 * @param {EditDataOpt<SendSupportEmailResponse>} options - Configuration options for the hook, including settings for the edit data operation.
 *
 * @returns {Object} An object containing:
 * - `sendSupportEmail`: A function to send a support email.
 * - `isSendingSupportEmail`: A boolean indicating whether the email is being sent.
 * - `error`: Any error encountered during the email-sending process.
 */
export function useSendSupportEmail(
  options: EditDataOpt<SendSupportEmailResponse>
) {
  const { isMutatingData, isLoading, editData, error } = useEditData_v2({
    ...options,
    stopGlobalError: options?.stopGlobalError ?? true,
  });

  const organizationId = useGetCurrentOrganizationId();

  /**
   * Sends a support email.
   *
   * @param {Omit<SendSupportEmailPayload, "organizationId">} body - The payload for the support email, excluding the `organizationId`, which is auto-injected.
   *
   * @returns {Promise<EditDataResult<SendSupportEmailResponse>>} A promise resolving to the result of the API call for sending the support email.
   */
  const sendSupportEmail = async (
    body: Omit<SendSupportEmailPayload, "organizationId">
  ): Promise<EditDataResult<SendSupportEmailResponse>> => {
    return await editData({
      method: "POST",
      endpoint: "/api/send-support-email",
      endpointCallLocation: "useSendSupportEmail",
      serverType: "dashboard",
      body: {
        ...body,
        organizationId,
      },
      mutateApis: [],
    });
  };

  return {
    sendSupportEmail,
    isSendingSupportEmail: isLoading || isMutatingData,
    error,
  };
}
