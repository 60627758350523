import { ServerType } from "@data-types/client-fetch-types";

/**
 * Analyzes the fetch response to determine the status of data loading, presence, and emptiness.
 *
 * This utility function evaluates the `data` object along with error and validation states
 * to provide indicators that simplify UI state management.
 *
 * @param data - The fetched data object.
 * @param isError - A boolean indicating if an error occurred during the fetch process.
 * @param isValidating - A boolean indicating if data is being revalidated.
 * @param source  Specifies the data source type.
 * @returns An object with the following indicators:
 *   - `showLoader`: A boolean indicating if a loader should be shown (`true` for loading or validating).
 *   - `hasData`: A boolean indicating if the data exists and is non-empty.
 *   - `emptyData`: A boolean indicating if the data exists but is empty.
 */
export function renderAnalyzer(
  data: any,
  isError: boolean,
  isValidating: boolean,
  source: ServerType = "gateway"
): { showLoader: boolean; hasData: boolean; emptyData: boolean } {
  // Determine if data is currently loading (no error and no data present)
  const isLoading = !isError && !data;

  // Extract the relevant data field based on the source type
  let extractData: boolean = false;

  switch (source) {
    case "backend":
      // For backend sources, ensure `data.value` is initialized to an array if it doesn't exist
      if (typeof data === "object" && data !== null && !data.value) {
        data.value = [];
      }
      extractData = data?.value ?? false;
      break;

    case "gateway":
      // For gateway sources, extract `data.data` if available
      extractData = data?.data ?? false;
      break;

    case "dashboard":
      // For dashboard sources, ensure `data.value` is initialized to an array if it doesn't exist
      if (typeof data === "object" && data !== null && !data.value) {
        data.value = [];
      }
      extractData = data?.value ?? false;
      break;

    default:
      // If no source is specified, no specific extraction logic is applied
      break;
  }

  // Initialize flags for data presence and emptiness
  let hasData = false;
  let emptyData = false;

  // Evaluate the extracted data only if there's no error or loading
  if (!isError && !isLoading) {
    if (Array.isArray(extractData)) {
      // If data is an array, set flags based on its length
      hasData = extractData.length > 0;
      emptyData = extractData.length === 0;
    } else {
      // For non-array data, set `hasData` to true if `extractData` is truthy
      hasData = !!extractData;
    }
  }

  return {
    // Show loader if data is loading or being validated
    showLoader: isLoading || isValidating,
    // Indicates if the data exists and is non-empty
    hasData,
    // Indicates if the data exists but is empty
    emptyData,
  };
}
