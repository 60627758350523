import { create } from "zustand";

/**
 * Represents a snack notification with a type and a React component.
 *
 * @property {string} type - The type of notification (e.g., "success", "error").
 * @property {React.ReactNode} component - The React component representing the notification UI.
 */
export type SnackNotification = {
  type: string;
  component: React.ReactNode;
};

/**
 * Represents the state of snack notifications.
 *
 * @property {SnackNotification[]} snackNotifications - Array of snack notifications.
 */
type SnackNotificationState = {
  snackNotifications: SnackNotification[];
};

/**
 * Represents the actions for managing snack notifications.
 *
 * @property {(snackNotifications: SnackNotification[]) => void} updateSnackNotifications - Function to update the list of snack notifications.
 */
type SnackNotificationAction = {
  updateSnackNotifications: (snackNotifications: SnackNotification[]) => void;
};

/**
 * Zustand store for managing snack notifications.
 *
 * @returns {SnackNotificationState & SnackNotificationAction} The snack notification store with state and actions.
 */
export const useSnackNotificationStore = create<SnackNotificationState & SnackNotificationAction>(set => ({
  snackNotifications: [],
  updateSnackNotifications: snackNotifications => set(() => ({
    snackNotifications: snackNotifications
  }))
}));