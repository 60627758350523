/**
 * Generates initials from a given name.
 *
 * @param {string} name - The full name to generate initials from.
 * @returns {string} - The initials of the name. If the name consists of multiple words,
 * it returns the first letter of the first two non-empty words as initials. If the name has only
 * one word, it returns the first two letters of that word as initials, both in uppercase.
 */
export function getInitials(name: string): string {
  if (!name || typeof name !== "string") {
    return ""; // Return empty string for invalid input
  }

  // Remove extra spaces and split the name into non-empty words
  const words = name.trim().split(/\s+/).filter(Boolean);

  if (words.length > 1) {
    // Take the first letter of the first two non-empty words
    return `${words[0][0].toUpperCase()}${words[1][0].toUpperCase()}`;
  }

  if (words.length === 1 && words[0].length > 1) {
    // Take the first two letters of the single word
    return `${words[0][0].toUpperCase()}${words[0][1].toUpperCase()}`;
  }

  // If there's only one letter or no valid input, return it in uppercase
  return words[0]?.[0]?.toUpperCase() || "";
}
