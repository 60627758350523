import { useGetUserSession } from "@custom-hooks/user";

/**
 * Custom hook to retrieve organization details by its ID.
 * It checks the user's session data for authorized organizations
 * and returns the matching organization if found.
 *
 * @param {string} organizationId - The ID of the organization to retrieve.
 *
 * @returns {Object | undefined} The organization details if found, otherwise `undefined`.
 */
export const useGetOrganization = (organizationId: string) => {
  const { data: session, hasData: availableSession } = useGetUserSession();

  if (availableSession && session?.authorizations?.organizations) {
    const organizations = session.authorizations.organizations;

    if (Object.keys(organizations).includes(organizationId)) {
      return organizations[organizationId];
    }
  }

  return undefined;
};
