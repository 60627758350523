//
// index.ts - Isomorphic Utility Functions
//

export * from "./bytesToGigabytes";
export * from "./compareDateToToday";
export * from "./convertBytesBase2ToBase10";
export * from "./convertDateToUtc0";
export * from "./convertUtc0ToLocalTimeZone";
export * from "./deepCopy";
export * from "./deepCopyMap";
export * from "./emailNormalizer";
export * from "./formatBytes";
export * from "./formatDate";
export * from "./generateId";
export * from "./generateRandomId";
export * from "./generateUUID";
export * from "./getInitials";
export * from "./getUtcOffset";
export * from "./gigabytesToBytes";
export * from "./hashString";
export * from "./hideMiddlePartOfString";
export * from "./isEmpty";
export * from "./isTrialStillValid";
export * from "./isValidSlug";
export * from "./isValidTimezoneOffset";
export * from "./logThis";
export * from "./mapToObject";
export * from "./objectToMap";
export * from "./safelyParseJSON";
export * from "./snakeToCamel";
export * from "./throwIsomorphicError";
export * from "./truncateString";
export * from "./validateEnvVariable";
export * from "./validateString";
export * from "./validateStringnonEncodingCharsInUrl";
