//
// useGetFetchConditionBasedOnProject.ts - Generic data fetching hook using SWR
//

import { useGetProjectStatus } from "@custom-hooks/projects";
import { ProjectStatus, RawProject } from "@data-types/projects-types";

/**
 * Custom hook to determine if data fetching should proceed based on project status.
 *
 * @param {string} params.id - The project ID.
 * @param {ProjectStatus} [params.projectStatus] - The expected project status to check against.
 *
 * @returns {boolean} - `true` if fetching should proceed, otherwise `false`.
 */
export function useGetFetchConditionBasedOnProject({
  id,
  projectStatus
}: Pick<RawProject, "id"> & {
  projectStatus?: ProjectStatus;
}): boolean {
  const {
    status,
    isStatusLoading
  } = useGetProjectStatus({
    projectId: projectStatus ? id ?? "" : ""
  });
  return projectStatus ? !isStatusLoading && status === projectStatus : true;
}