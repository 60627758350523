import * as Headless from "@headlessui/react";
import CloseEyeIcon from "@layouts/svg-icon/close-eye-icon.svg";
import CopyIcon from "@layouts/svg-icon/copy-icon.svg";
import OpenEyeIcon from "@layouts/svg-icon/open-eye-icon.svg";
import clsx from "clsx";
import { forwardRef, useState } from "react";
import { Button, StandardButtonProps } from "./Button";
import { Description, ErrorMessage, Field, Label } from "./Fieldset";
export function InputGroup({
  children
}: React.ComponentPropsWithoutRef<"span">) {
  return <span data-slot="control" className={clsx("tw-relative tw-isolate tw-block", "[&_input]:has-[[data-slot=icon]:first-child]:tw-pl-12 [&_input]:has-[[data-slot=icon]:last-child]:tw-pr-8", "[&_input]:has-[[data-slot=password-icon]]:!tw-pl-3 [&_input]:has-[[data-slot=password-icon]]:tw-pr-8", "[&_input]:has-[[data-slot=action-button]]:!tw-pl-3 [&_input]:has-[[data-slot=action-button]]:tw-pr-[6.2rem]", "[&>[data-slot=icon]]:tw-pointer-events-none [&>[data-slot=icon]]:tw-absolute [&>[data-slot=icon]]:tw-top-1/2 [&>[data-slot=icon]]:tw-z-10 [&>[data-slot=icon]]:tw-size-6 [&>[data-slot=icon]]:-tw-translate-y-1/2 [&>[data-slot=icon]]:tw-transform", "[&>[data-slot=password-icon]]:tw-pointer-events-auto [&>[data-slot=password-icon]]:tw-absolute [&>[data-slot=password-icon]]:tw-top-1/2 [&>[data-slot=password-icon]]:tw-z-10 [&>[data-slot=password-icon]]:tw-size-6 [&>[data-slot=password-icon]]:-tw-translate-y-1/2 [&>[data-slot=password-icon]]:tw-transform", "[&>[data-slot=icon]:first-child]:tw-left-3 [&>[data-slot=icon]:last-child]:tw-right-3", "[&>[data-slot=password-icon]]:tw-right-3", "[&>[data-slot=icon]]:tw-text-text-title-light dark:[&>[data-slot=icon]]:tw-text-text-title-dark", "[&>[data-slot=password-icon]]:tw-text-text-title-light dark:[&>[data-slot=password-icon]]:tw-text-text-title-dark", "[&>[data-slot=action-button]]:tw-pointer-events-auto [&>[data-slot=action-button]]:tw-absolute [&>[data-slot=action-button]]:tw-right-2 [&>[data-slot=action-button]]:tw-top-1/2 [&>[data-slot=action-button]]:tw-z-10 [&>[data-slot=action-button]]:-tw-translate-y-1/2 [&>[data-slot=action-button]]:tw-transform")} data-sentry-component="InputGroup" data-sentry-source-file="Input.tsx">
      {children}
    </span>;
}

// #region Input

//
// types
//

const dateTypes = ["date", "datetime-local", "month", "time", "week"];
type DateType = (typeof dateTypes)[number];
type Sizes = "large" | "medium" | "small" | "custom";
type StyleItem = string[];
type SizeStyle = { [K in Sizes]: {
  layout: StyleItem;
  typography: StyleItem;
} };
interface InputStyles {
  sizes: SizeStyle;
}

//
// component
//

const styles: InputStyles = {
  sizes: {
    small: {
      layout: ["tw-h-[2.375rem] tw-px-[calc(theme(spacing[3])-1px)] tw-py-[calc(theme(spacing[3])-1px)]"],
      typography: ["tw-text-14px-regular"]
    },
    medium: {
      layout: ["tw-h-12 tw-px-[calc(theme(spacing[3])-1px)] tw-py-[calc(theme(spacing[3])-3px)]"],
      typography: ["tw-text-14px-regular"]
    },
    large: {
      layout: ["tw-h-16 tw-px-[calc(theme(spacing[3])-1px)] tw-py-[calc(theme(spacing[7])-3px)]"],
      typography: ["tw-text-16px-regular"]
    },
    custom: {
      layout: [],
      typography: []
    }
  }
};

// Input Props
export type InputProps = {
  className?: string;
  type?: "email" | "number" | "password" | "search" | "tel" | "text" | "url" | DateType;
  hasVisibilityToggle?: boolean;
} & Omit<Headless.InputProps, "as" | "className"> & ({
  sizeVar: Exclude<Sizes, "custom">;
  customSize?: never;
} | {
  sizeVar: Exclude<Sizes, "large" | "small" | "medium">;
  customSize: {
    layout: StyleItem;
    typography: StyleItem;
  };
});
export const Input = forwardRef(function Input({
  className,
  sizeVar,
  hasVisibilityToggle,
  customSize,
  ...props
}: InputProps, ref: React.ForwardedRef<HTMLInputElement>) {
  return <span data-slot="control" className={clsx([className,
  // Basic layout
  "tw-relative tw-block tw-w-full",
  // Background color + shadow applied to inset pseudo element, so shadow blends with border in light mode
  "before:tw-absolute before:tw-inset-px before:tw-rounded-[calc(theme(borderRadius.lg)-1px)] before:tw-bg-interface-card-light",
  // Background color is moved to control and shadow is removed in dark mode so hide `before` pseudo
  "dark:before:tw-hidden",
  // Focus ring
  "after:tw-pointer-events-none after:tw-absolute after:tw-inset-0 after:tw-rounded-lg after:tw-ring-inset after:tw-ring-transparent sm:after:focus-within:tw-ring-2 sm:after:focus-within:tw-ring-brandBlues-brandSecondary-light",
  // Disabled state
  "before:has-[[data-disabled]]:tw-bg-interface-card-light before:has-[[data-disabled]]:tw-shadow-none data-[disabled]:placeholder:tw-text-interface-gray-light",
  // Invalid state
  "before:has-[[data-invalid]]:tw-shadow-red-500/10",
  // Color transitions
  "tw-transition-colors tw-duration-200"])}>
      <Headless.Input ref={ref} {...props} className={clsx([
    // Date classes
    props.type && dateTypes.includes(props.type) && ["[&::-webkit-datetime-edit-fields-wrapper]:tw-p-0", "[&::-webkit-date-and-time-value]:tw-min-h-[1.5em]", "[&::-webkit-datetime-edit]:tw-inline-flex", "[&::-webkit-datetime-edit]:tw-p-0", "[&::-webkit-datetime-edit-year-field]:tw-p-0", "[&::-webkit-datetime-edit-month-field]:tw-p-0", "[&::-webkit-datetime-edit-day-field]:tw-p-0", "[&::-webkit-datetime-edit-hour-field]:tw-p-0", "[&::-webkit-datetime-edit-minute-field]:tw-p-0", "[&::-webkit-datetime-edit-second-field]:tw-p-0", "[&::-webkit-datetime-edit-millisecond-field]:tw-p-0", "[&::-webkit-datetime-edit-meridiem-field]:tw-p-0"],
    // Basic layout
    "tw-relative tw-block tw-w-full tw-appearance-none tw-rounded-lg", sizeVar !== "custom" ? styles.sizes[sizeVar].layout : customSize.layout,
    // Typography
    "tw-truncate tw-text-text-title-light placeholder:tw-text-text-subTitle-light dark:tw-text-text-title-dark dark:placeholder:tw-text-text-subTitle-dark", "data-[disabled]:tw-text-interface-gray-light dark:data-[disabled]:tw-text-interface-gray-dark", "data-[disabled]:placeholder:tw-text-interface-gray-light dark:data-[disabled]:placeholder:tw-text-interface-gray-dark", sizeVar !== "custom" ? styles.sizes[sizeVar].typography : customSize.typography,
    // Border
    "tw-border tw-border-interface-divider-light data-[hover]:tw-border-text-title-light dark:tw-border-interface-divider-dark dark:data-[hover]:tw-border-text-title-dark",
    // Background color
    "tw-bg-transparent dark:tw-bg-interface-card-dark",
    // Hide default focus styles
    "tw-focus:tw-outline-none",
    // Invalid state
    "data-[invalid]:data-[hover]:tw-border-semantics-error-light data-[invalid]:tw-border-semantics-error-light data-[invalid]:dark:tw-border-semantics-error-dark data-[invalid]:data-[hover]:dark:tw-border-semantics-error-dark",
    // Disabled state
    "data-[disabled]:tw-border-interface-divider-light dark:data-[disabled]:tw-border-interface-divider-dark dark:data-[hover]:data-[disabled]:tw-border-interface-divider-dark data-[disabled]:dark:tw-bg-interface-card-dark",
    // System icons
    "dark:[color-scheme:dark]",
    // Color transitions
    "data-[hover]:tw-transition-colors data-[hover]:tw-duration-200"])} />
    </span>;
});

// #endregion

// #region GenericInput

// component

export type GenericInputProps = {
  label?: string;
  description?: string;
  icon?: React.ReactNode;
  endIcon?: React.ReactNode;
  errors?: Record<string, string | undefined>;
  inputClassName?: InputProps["className"];
  actionButton?: {
    type: "copy";
    onClick?: () => void;
  } | {
    type: "generic";
    props: Omit<StandardButtonProps, "data-slot" | "size">;
  };
} & InputProps;
export const GenericInput = forwardRef(function GenericInput({
  label,
  description,
  disabled,
  errors,
  icon,
  endIcon,
  type,
  inputClassName,
  actionButton,
  onCopy,
  ...props
}: GenericInputProps, ref: React.ForwardedRef<HTMLInputElement>) {
  const {
    disabled: actionButtonDisabled,
    ...actionButtonProps
  } = actionButton?.type === "generic" ? actionButton?.props : {};

  // State to manage the visibility of the password input field
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  // Toggles the visibility of the password input field
  // - If the password is currently visible, this function hides it.
  // - If the password is currently hidden, this function makes it visible.
  const togglePasswordVisibility = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsPasswordVisible(prev => !prev);
  };
  let inputType = type;
  if (type === "password") {
    inputType = isPasswordVisible ? "text" : "password";
  }

  // Define copy to clipboard function used in case of copyMode enabled
  const [copied, setCopied] = useState(false);
  const copyToClipboard = async (value: string) => {
    try {
      await navigator.clipboard.writeText(value);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    } catch (err) {}
  };
  return <Field disabled={disabled}>
      {label && <Label>{label}</Label>}

      {description && <Description>{description}</Description>}

      <InputGroup>
        {type !== "password" && icon && icon}

        <Input type={inputType} {...props} invalid={errors && props.name && errors[props.name] ? true : false} ref={ref} className={inputClassName} onKeyDown={e => {
        e.stopPropagation();
      }} />

        {type === "password" && <Headless.Button data-slot="password-icon" onClick={togglePasswordVisibility}>
            {isPasswordVisible ? <CloseEyeIcon /> : <OpenEyeIcon />}
          </Headless.Button>}

        {actionButton?.type === "copy" && <Button data-slot="action-button" size="small" icon={copied ? <></> : <CopyIcon />} label={copied ? "Copied!" : "Copy"} variant="primary" disabled={disabled} onClick={async () => {
        copyToClipboard(props.value as string);
        actionButton?.onClick?.();
      }} />}

        {actionButton?.type === "generic" && <Button data-slot="action-button" size="small" disabled={actionButtonDisabled || disabled} {...actionButtonProps} />}

        {type !== "password" && endIcon && endIcon}
      </InputGroup>

      {errors && props.name && errors[props.name] && <ErrorMessage>{errors[props.name]}</ErrorMessage>}
    </Field>;
});

// #endregion