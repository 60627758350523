/**
 * Compares a given date to the current date.
 *
 * @param {string | Date} dateString - The date to compare, provided as a string or Date object.
 * @returns {string} Returns "greater" if the date is in the future, "earlier" if in the past, or "equal" if it is today.
 */
export function compareDateToToday(dateString: string | Date): string {
  // Parse the input into a Date object
  const date1 = new Date(dateString);

  // Get the current date
  const date2 = new Date();

  // Compare the dates
  if (date1 > date2) {
    return "greater"; // date1 is in the future
  } else if (date1 < date2) {
    return "earlier"; // date1 is in the past
  } else {
    return "equal"; // date1 is the same as today
  }
}
