/**
 * Gets the UTC offset for a specified date or the current date if none is provided.
 *
 * @param {Date | undefined} referenceDate - The date to calculate the UTC offset for (optional).
 * @returns {string} The UTC offset formatted as "UTC±HH:MM".
 */
export function getUtcOffset(referenceDate?: Date): string {
  // Use the provided date or default to the current date
  const date = referenceDate || new Date();

  // Get the time zone offset in minutes (positive if behind UTC, negative if ahead)
  const timezoneOffsetInMinutes = date.getTimezoneOffset();

  // Calculate the offset hours and minutes as absolute values
  const offsetHours = Math.floor(Math.abs(timezoneOffsetInMinutes) / 60);
  const offsetMinutes = Math.abs(timezoneOffsetInMinutes) % 60;

  // Determine the sign based on whether the timezone is behind (-) or ahead (+) of UTC
  const sign = timezoneOffsetInMinutes > 0 ? "-" : "+";

  // Format the UTC offset as a string in the format "UTC±HH:MM"
  const utcDifference = `UTC${sign}${String(offsetHours).padStart(2, "0")}:${String(offsetMinutes).padStart(2, "0")}`;

  // Return the formatted UTC offset
  return utcDifference;
}
