//
// avatar.tsx
//

import * as Headless from "@headlessui/react";
import clsx from "clsx";
import React, { forwardRef } from "react";
import { TouchTarget } from "./Button";
import { Link } from "./Link";
export type AvatarProps = {
  src?: string | null;
  square?: boolean;
  initials?: string;
  alt?: string;
  className?: string;
};
export function Avatar({
  src = null,
  square = false,
  initials,
  alt = "",
  className,
  ...props
}: AvatarProps & React.ComponentPropsWithoutRef<"span">) {
  return <span data-slot="avatar" {...props} className={clsx(className,
  // Basic layout
  "tw-inline-grid tw-shrink-0 tw-align-middle [--avatar-radius:20%] [--ring-opacity:100%] *:tw-col-start-1 *:tw-row-start-1", "tw-outline-none",
  // Background
  "tw-dark:tw-bg-brandBlues-cream-dark tw-bg-brandBlues-cream-light",
  // Text Color
  "tw-dark:tw-text-text-title-dark tw-text-text-title-light",
  // Add the correct border radius
  square ? "tw-rounded-[--avatar-radius] *:tw-rounded-[--avatar-radius]" : "tw-rounded-full *:tw-rounded-full")} data-sentry-component="Avatar" data-sentry-source-file="Avatar.tsx">
      {initials && <svg className="tw-size-full tw-select-none tw-fill-current tw-p-[5%] tw-text-[48px] tw-font-normal tw-uppercase" viewBox="0 0 100 100" aria-hidden={alt ? undefined : "true"}>
          {alt && <title>{alt}</title>}
          <text x="50%" y="50%" alignmentBaseline="middle" dominantBaseline="middle" textAnchor="middle" dy=".125em">
            {initials}
          </text>
        </svg>}
      {src && <img className="tw-size-full" src={src} alt={alt} />}
    </span>;
}
export const AvatarButton = forwardRef(function AvatarButton({
  src,
  square = false,
  initials,
  alt,
  className,
  ...props
}: AvatarProps & (Omit<Headless.ButtonProps, "as" | "className"> | Omit<React.ComponentPropsWithoutRef<typeof Link>, "className">), ref: React.ForwardedRef<HTMLElement>) {
  let classes = clsx(className, square ? "tw-rounded-[20%]" : "tw-rounded-full", "tw-relative tw-inline-grid ",
  // Focus light
  "focus:tw-outline-none data-[focus]:tw-outline data-[focus]:tw-outline-1 data-[focus]:tw-outline-offset-[0.1875rem] data-[focus]:tw-outline-brandBlues-brandSecondary-light",
  // Focus dark
  "tw-dark:data-[focus]:tw-outline-brandBlues-brandSecondary-dark");
  return "href" in props ? <Link {...props} className={classes} ref={ref as React.ForwardedRef<HTMLAnchorElement>}>
      <TouchTarget>
        <Avatar src={src} square={square} initials={initials} alt={alt} />
      </TouchTarget>
    </Link> : <Headless.Button {...props} className={classes} ref={ref}>
      <TouchTarget>
        <Avatar src={src} square={square} initials={initials} alt={alt} />
      </TouchTarget>
    </Headless.Button>;
});