//
// UppyProvider.tsx
//

import { ClientError } from "@data-types/client-error-types";
import { CustomAwsS3Options, CustomUppyOptions, CustomXhrUploadOpts, UploadConfig, UploadError, UploadMethods, UploadProgress, UppyContextType, UppyInstances } from "@data-types/upload-types";
import AwsS3 from "@uppy/aws-s3";
import Uppy from "@uppy/core";
import XHR from "@uppy/xhr-upload";
import { createContext, useEffect, useRef, useState } from "react";

/**
 * Context to manage Uppy instances across the application.
 * Each resource has its own Uppy instance to ensure file uploads
 * are isolated per resource.
 */
export const UppyContext = createContext<UppyContextType | null>(null);

/**
 * UppyProvider component that provides Uppy instances and active upload tracking
 * across different resources.
 *
 * Ensures:
 * - Each resource has its own unique Uppy instance.
 * - Only one upload per resource is active at a time.
 * - Uploads persist when navigating between different resources.
 * Allows:
 * - Dynamic configuration updates
 */
export function UppyProvider({
  children
}: {
  children: React.ReactNode;
}) {
  // State to store Uppy instances for each resource.
  // This ensures each resource has its own uploader and maintains its progress.
  const [uppyInstances, setUppyInstances] = useState<UppyInstances>({});
  const uppyInstancesRef = useRef(uppyInstances);
  useEffect(() => {
    uppyInstancesRef.current = uppyInstances;
  }, [uppyInstances]);

  // State to store Uppy instances upload progress for each resource.
  const [uploadsProgress, setUploadsProgress] = useState<Record<string, UploadProgress>>({});

  // State to store Uppy instances upload progress for each resource.
  const [uploadsError, setUploadsError] = useState<Record<string, UploadError | undefined>>({});

  // State to track whether a resource currently has an active upload.
  // The key is the `resourceId`, and the value is a boolean indicating
  // if an upload is in progress.
  const [activeUploads, setActiveUploads] = useState<{
    [resourceId: string]: boolean;
  }>({});

  // Stores configurations per resource
  const [configurations, setConfigurations] = useState<{
    [resourceId: string]: Partial<CustomUppyOptions>;
  }>({});

  // Stores upload method per resource
  const [uploadMethods, setUploadMethods] = useState<{
    [resourceId: string]: UploadMethods;
  }>({});

  /**
   * Retrieves or initializes an Uppy instance for a given resource.
   *
   * - If an instance exists, returns it.
   * - Otherwise, creates a new instance with the provided configuration.
   *
   * @param resourceId - The resource identifier.
   * @param config - Optional configuration for Uppy initialization.
   * @returns The Uppy instance for the given resource.
   */
  const getUppyInstance = (resourceId: string, config: Partial<CustomUppyOptions> = {}) => {
    if (!uppyInstances[resourceId]) {
      const mergedConfig = {
        id: `uppy-${resourceId}`,
        // Unique ID per resource
        allowMultipleUploadBatches: false,
        autoProceed: false,
        ...config // Merge with provided config
      } as Partial<CustomUppyOptions>;
      const newUppy = new Uppy(mergedConfig);

      // Init events handling
      newUppy.on("file-added", () => handleAddFile(resourceId));
      newUppy.on("progress", () => handleProgress(resourceId));
      newUppy.on("complete", () => handleOnComplete(resourceId));
      newUppy.on("error", error => handleOnError(resourceId, error));
      newUppy.on("retry-all", () => handleOnRetryAll(resourceId));
      newUppy.on("cancel-all", () => handleCancelAll(resourceId));

      // Store the new Uppy instance and its configuration
      setUppyInstances(prev => ({
        ...prev,
        [resourceId]: newUppy
      }));
      setConfigurations(prev => ({
        ...prev,
        [resourceId]: mergedConfig
      }));
      setUploadsProgress(prev => ({
        ...prev,
        [resourceId]: 0
      }));
      setUploadsError(prev => ({
        ...prev,
        [resourceId]: undefined
      }));
    }
    return uppyInstances[resourceId];
  };

  /**
   * Initializes upload progress and clears any existing errors when a file is added.
   *
   * This function is triggered when a new file is added to Uppy.
   * It sets the initial upload progress to `0%` for the specified resource
   * and clears any previous errors associated with it.
   *
   * @param resourceId - The unique identifier of the resource being uploaded.
   */
  const handleAddFile = (resourceId: string) => {
    const currentUppyInstances = uppyInstancesRef.current;
    if (currentUppyInstances[resourceId]) {
      setUploadsProgress(prev => ({
        ...prev,
        [resourceId]: 0 // Initialize progress for this resource
      }));
      setUploadsError(prev => ({
        ...prev,
        [resourceId]: undefined
      }));
    }
  };

  /**
   * Updates the upload progress for a specific resource and marks it as "completed" when finished.
   *
   * This function retrieves the current upload progress from the corresponding Uppy instance
   * and updates the `uploadsProgress` state. If the progress reaches 100%, it sets the status
   * to `"completed"` after a short delay.
   *
   * @param resourceId - The unique identifier of the resource being uploaded.
   */
  const handleProgress = (resourceId: string) => {
    const currentUppyInstances = uppyInstancesRef.current;
    if (currentUppyInstances[resourceId]) {
      const uppyInstance = currentUppyInstances[resourceId];
      const totalProgress = uppyInstance.getState().totalProgress;

      // Update the current progress state
      setUploadsProgress(prev => ({
        ...prev,
        [resourceId]: totalProgress
      }));

      // If the upload is complete, set the state to "completed" after a short delay
      if (totalProgress === 100) {
        setTimeout(() => {
          setUploadsProgress(prev => ({
            ...prev,
            [resourceId]: "completed"
          }));
        }, 500);
      }
    }
  };

  /**
   * Marks an upload as "completed" after a short delay.
   *
   * This function is triggered when an Uppy upload completes. It updates the
   * `uploadsProgress` state to `"completed"` for the specified resource
   * after a short timeout. The delay ensures any final updates from Uppy
   * are processed before marking the upload as complete.
   *
   * @param resourceId - The unique identifier of the completed upload.
   */
  const handleOnComplete = (resourceId: string) => {
    const currentUppyInstances = uppyInstancesRef.current;
    if (currentUppyInstances[resourceId]) {
      setTimeout(() => {
        setUploadsProgress(prev => ({
          ...prev,
          [resourceId]: "completed"
        }));
      }, 500);
    }
  };

  /**
   * Handles upload errors and updates the error state.
   *
   * This function is triggered when an Uppy upload encounters an error.
   * It creates a `ClientError` instance with relevant details and updates
   * the `uploadsError` state for the specified resource.
   *
   * The error includes the name, message, and optional details, providing
   * useful context for debugging and user feedback.
   *
   * @param resourceId - The unique identifier of the upload that failed.
   * @param error - An object containing the error name, message, and optional details.
   */
  const handleOnError = (resourceId: string, error: {
    name: string;
    message: string;
    details?: string;
  }) => {
    const uppyError = new ClientError(error.name, {
      name: error.name,
      message: error.message + " | " + error.details,
      endpoint: "",
      status: 500
    });
    const currentUppyInstances = uppyInstancesRef.current;
    if (currentUppyInstances[resourceId]) {
      setUploadsError(prev => ({
        ...prev,
        [resourceId]: {
          error: uppyError,
          retryMethod: "retry"
        }
      }));
    }
  };

  /**
   * Handles retrying all failed uploads for a specific resource.
   * If an Uppy instance exists for the given resourceId, it clears the error state.
   *
   * @param {string} resourceId - The ID of the resource associated with the upload.
   */
  const handleOnRetryAll = (resourceId: string) => {
    const currentUppyInstances = uppyInstancesRef.current;
    if (currentUppyInstances[resourceId]) {
      setUploadsError(prev => ({
        ...prev,
        [resourceId]: undefined
      }));
      setUploadsProgress(prev => ({
        ...prev,
        [resourceId]: 0
      }));
    }
  };

  /**
   * Handles canceling all uploads for a specific resource.
   * If an Uppy instance exists for the given resourceId, it resets the error state and progress.
   *
   * @param {string} resourceId - The ID of the resource associated with the uploads.
   */
  const handleCancelAll = (resourceId: string) => {
    const currentUppyInstances = uppyInstancesRef.current;
    if (currentUppyInstances[resourceId]) {
      setUploadsError(prev => ({
        ...prev,
        [resourceId]: undefined
      }));
      setUploadsProgress(prev => ({
        ...prev,
        [resourceId]: 0
      }));
    }
  };

  /**
   * Sets an upload error for a specific resource.
   * If an Uppy instance exists for the given resourceId, it updates the error state.
   *
   * @param {string} resourceId - The ID of the resource associated with the upload.
   * @param {UploadError | undefined} uploadError - The error to set, or undefined to clear the error.
   */
  const setUploadError = (resourceId: string, uploadError: UploadError | undefined) => {
    const currentUppyInstances = uppyInstancesRef.current;
    if (currentUppyInstances[resourceId]) {
      setUploadsError(prev => ({
        ...prev,
        [resourceId]: uploadError
      }));
    }
  };

  /**
   * Updates the configuration of an already initialized Uppy instance.
   *
   * - Applies new settings dynamically without reinitializing the instance.
   * - If the instance does not exist, it initializes a new one with the config.
   *
   * @param resourceId - The resource identifier.
   * @param newConfig - The new configuration settings.
   */
  const updateUppyConfig = (resourceId: string, newConfig: Partial<CustomUppyOptions>) => {
    setConfigurations(prev => {
      const updatedConfig = {
        ...prev[resourceId],
        ...newConfig
      };
      // Update existing Uppy instance
      if (uppyInstances[resourceId]) {
        uppyInstances[resourceId].setOptions(updatedConfig);
      }
      return {
        ...prev,
        [resourceId]: updatedConfig
      };
    });
  };

  /**
   * Dynamically updates the upload method for a resource.
   *
   * Supports switching between `XHRUpload` and `AwsS3` while enforcing correct type usage.
   */
  const updateUploadMethod = (resourceId: string, uploadConfig: UploadConfig) => {
    const uppy = uppyInstances[resourceId];
    if (!uppy) return;

    // Remove previous plugin before switching
    const prevMethod = uploadMethods[resourceId];
    if (prevMethod === "xhr") {
      const xhrInstance = uppy.getPlugin("XHRUpload");
      if (xhrInstance) {
        uppy.removePlugin(xhrInstance);
      }
    } else if (prevMethod === "s3") {
      const s3Instance = uppy.getPlugin("AwsS3Multipart");
      if (s3Instance) {
        uppy.removePlugin(s3Instance);
      }
    }
    const {
      method,
      config
    } = uploadConfig;
    if (method === "xhr") {
      let xhrConfig = config as CustomXhrUploadOpts;
      uppy.use(XHR, xhrConfig);
    } else if (method === "s3") {
      const s3Config = config as CustomAwsS3Options;
      uppy.use(AwsS3, s3Config);
    }

    // Store the new upload method
    setUploadMethods(prev => ({
      ...prev,
      [resourceId]: method
    }));
  };

  /**
   * Marks an upload as active.
   * @param resourceId - The unique ID of the resource being uploaded.
   */
  const startUpload = (resourceId: string) => {
    setActiveUploads(prev => ({
      ...prev,
      [resourceId]: true
    }));
  };

  /**
   * Marks an upload as complete.
   * @param resourceId - The unique ID of the resource being uploaded.
   */
  const completeUpload = (resourceId: string) => {
    setActiveUploads(prev => ({
      ...prev,
      [resourceId]: false
    }));
  };
  return <UppyContext.Provider value={{
    activeUploads,
    uploadsProgress,
    uploadsError,
    getUppyInstance,
    updateUppyConfig,
    updateUploadMethod,
    startUpload,
    completeUpload,
    setUploadError
  }} data-sentry-element="unknown" data-sentry-component="UppyProvider" data-sentry-source-file="UppyProvider.tsx">
      {children}
    </UppyContext.Provider>;
}