//
// fetchApiRoute_v2.ts
//

import { BackendSuccessResponse } from "@data-types/backend-response-types";
import { ClientError } from "@data-types/client-error-types";
import { ServerType } from "@data-types/client-fetch-types";
import * as Sentry from "@sentry/nextjs";
import { normalizeBackendError } from "./normalizeBackendError";

/**
 * Represents the configuration options for a simplified API request.
 *
 * This type is used with the new `fetchApiRoute_v2` function, designed to handle
 * JSON requests exclusively using the Fetch API.
 *
 * @property endpoint - The URL of the API endpoint to call.
 * @property method - The HTTP method to use for the request. Supported methods are `"GET"`, `"POST"`, and `"PATCH"`.
 * @property endpointCallLocation - A description or context of where this API call is being made (used for debugging/logging).
 * @property queryStrings - (Optional) An array of query string parameters to append to the endpoint.
 * @property body - (Optional) The JSON body of the request.
 * @property auth - (Optional) A Bearer token for authorization.
 */
export type FetchApiOptions_v2 = {
  endpoint: string;
  method: "GET" | "POST" | "PATCH" | "DELETE" | "PUT";
  endpointCallLocation: string;
  serverType: ServerType;
  queryStrings?: string[];
  body?: any;
  auth?: string;
};

/**
 * Executes a JSON-based API request using the Fetch API.
 *
 * This is the new and simplified version of the API call handler, specifically
 * designed for scenarios where JSON requests are required. The `_v2` suffix indicates
 * that this version is meant to replace the older implementation in all new use cases.
 *
 * ## Features:
 * - Handles JSON requests exclusively.
 * - Utilizes the Fetch API for simplicity and modern standards.
 * - Throws a `ClientError` with additional details for any failed requests.
 *
 * ## Notes:
 * - This function is intended to be used in all new development contexts
 *   where API requests are required.
 * - The old version of the API call handler is retained for file uploads
 *   and for backward compatibility in legacy code that has not yet been migrated.
 *
 * @param opt - Configuration options for the API request.
 * @returns A promise that resolves with the API response.
 * @throws A `ClientError` if the request fails or returns a non-successful status.
 */
export async function fetchApiRoute_v2<T>(
  opt: FetchApiOptions_v2,
  enableSentry: boolean = true
): Promise<BackendSuccessResponse<T> | undefined> {
  const {
    endpoint: baseEndpoint,
    method,
    endpointCallLocation,
    queryStrings,
    body,
    auth,
    serverType,
  } = opt;

  // Construct the URL with query strings if provided
  const queryString = queryStrings?.length ? `?${queryStrings.join("&")}` : "";
  const endpoint = `${baseEndpoint}${queryString}`;

  // Initialize headers
  const headers: Record<string, string> = {
    ...(auth && { Authorization: `Bearer ${auth}` }),
    ...(body && { "Content-Type": "application/json" }),
  };

  // Build the fetch request configuration
  const fetchConfig: RequestInit = {
    method,
    headers,
    ...(body && { body: JSON.stringify(body) }),
  };

  try {
    // Execute the fetch request
    const response = await fetch(endpoint, fetchConfig);

    // Redirect to sign-in if response is redirected
    if (response.ok && response.redirected) {
      window.location.href = response.url;
      return undefined;
    }

    // Check if the response is successful
    if (!response.ok && !response.redirected) {
      const rawBackendError = {
        ...(await response.json()),
        status: response.status,
      };
      const errorDetails = normalizeBackendError(rawBackendError, serverType);
      const timestamp = new Date().toISOString();
      throw new ClientError(
        `${timestamp} - Error occurred in "${endpointCallLocation}": ${response.statusText}`,
        errorDetails
      );
    }

    // Handle successful responses
    if (response.ok && !response.redirected) {
      // Parse and return the response JSON
      return await response.json();
    }
  } catch (error: any) {
    // Handle and log any fetch-related errors
    const errorMessage = `Failed to fetch from "${endpoint}" with method "${method}". Details: ${error.message}`;
    console.error(
      `An error occurred during the API request in "${endpointCallLocation}":`,
      error
    );
    if (enableSentry) {
      Sentry.captureException(new Error(errorMessage), {
        extra: {
          errorDetails: error.details || undefined,
        },
      });
    }
    // Throw a ClientError for better error propagation
    throw new ClientError(errorMessage, error.details || undefined);
  }
}
