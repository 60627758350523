/**
 * Converts a value in bytes to a human-readable format, using the appropriate size unit.
 *
 * @param {number} bytes - The value in bytes to format.
 * @param {number} decimals - The number of decimal places to include (default: 2).
 * @returns {string} The formatted string with the size in appropriate units.
 */
export function formatBytes(bytes: number, decimals: number = 2): string {
  // If bytes is 0 or undefined, return "0 Bytes"
  if (!bytes) return "0 Bytes";

  // Define the threshold for each unit (1000 bytes per unit)
  const k = 1000;

  // Ensure decimal places are non-negative
  const dm = decimals < 0 ? 0 : decimals;

  // Define size units for human-readable formatting
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  // Determine the appropriate size unit based on the logarithmic scale
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  // Calculate the converted size and format it to the specified decimal places
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}
