//
// formatDate.ts
//

/**
 * Formats a given date or timestamp into the English style "Month DaySuffix, Year".
 *
 * Example outputs:
 * - "March 1st, 2025"
 * - "February 27th, 2025"
 *
 * The function:
 * 1. Accepts either a `Date` object or a timestamp (milliseconds).
 * 2. Converts the timestamp into a `Date` object if necessary.
 * 3. Extracts the month, day, and year.
 * 4. Appends the correct ordinal suffix (`st`, `nd`, `rd`, `th`) to the day.
 * 5. Returns the formatted string in the desired format.
 *
 * @param {Date | number} input - A `Date` object or timestamp in milliseconds.
 * @returns {string} - The formatted date string in "Month DaySuffix, Year" format.
 */
export const formatDate = (input: Date | number): string => {
  // Convert input to a Date object if it's a number (timestamp)
  const date = input instanceof Date ? input : new Date(input);

  // Extract date components
  const day = date.getDate();
  const year = date.getFullYear();
  const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
    date
  );

  /**
   * Determines the appropriate suffix for the day (st, nd, rd, th).
   * Special cases: 11th, 12th, 13th always get "th".
   */
  const getDaySuffix = (day: number): string => {
    if (day >= 11 && day <= 13) return "th"; // Special case for 11-13
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  // Return formatted string: "Month DaySuffix, Year"
  return `${month} ${day}${getDaySuffix(day)}, ${year}`;
};
