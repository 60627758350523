import { useEditData_v2 } from "@custom-hooks/edit-data";
import { EditDataOpt, EditDataResult } from "@data-types/generic-hook-type";
import { usePostHog } from "posthog-js/react";

/**
 * Custom hook for managing database creation logic.
 *
 * @param options - Configuration options for the hook, such as enabling or disabling global error handling.
 *
 * @returns An object containing:
 * - `createDatabase`: A function to initiate the creation of a database.
 * - `isCreatingDatabase`: A boolean indicating whether the database creation process is ongoing.
 * - `error`: Any error encountered during the database creation process.
 */
export function useCreateDatabase(options: EditDataOpt<null>) {
  const posthog = usePostHog();

  const { isMutatingData, isLoading, editData, error } = useEditData_v2({
    ...options,
    stopGlobalError: options?.stopGlobalError ?? true,
  });

  /**
   * Creates a new database for a specific project.
   *
   * @param params.projectId - The ID of the project where the database will be created.
   * @param params.databaseName - The name of the new database. This name must be unique within the project.
   * @param params.encryptionKey - (Optional) An encryption key to secure the database.
   *
   * @returns A promise resolving to an `EditDataResult` object indicating the result of the API call.
   */
  const createDatabase = async ({
    projectId,
    databaseName,
    encryptionKey,
  }: {
    projectId: string;
    databaseName: string;
    encryptionKey?: string;
  }): Promise<EditDataResult<null>> => {
    const result = await editData({
      method: "POST",
      endpoint: `/api/projects/${projectId}/databases/${databaseName}/create`,
      endpointCallLocation: "useCreateDatabase",
      serverType: "backend",
      body:
        encryptionKey && encryptionKey != ""
          ? {
              key: encryptionKey,
            }
          : {},
      mutateApis: [
        {
          url: `/api/projects/${projectId}/databases`,
          component: "useGetProjectDatabases",
        },
      ],
    });

    return result;
  };

  return {
    createDatabase,
    isCreatingDatabase: isLoading || isMutatingData,
    error,
  };
}
