//
// useGetUserAccountProfile.ts
//

import { useFetchData } from "@custom-hooks/fetch-data";
import { FetchHookResult } from "@data-types/generic-hook-type";
import { UserAccountProfile } from "@data-types/user-types";

/**
 * Custom hook to fetch user account profile.
 *
 * @param {string} provider - The provider to fetch the profile for.
 *
 * @returns {FetchHookResult<UserAccountProfile>} The result object containing the user account profile data.
 */
export function useGetUserAccountProfile(
  provider: string
): FetchHookResult<UserAccountProfile> {
  return useFetchData<UserAccountProfile>({
    fetchCondition: provider !== "",
    source: "dashboard",
    swrKey: {
      url: `/api/provider-profile?provider=${provider}`,
      component: "useGetUserAccountProfile",
    },
    options: {
      revalidateOnFocus: false,
    },
  });
}
