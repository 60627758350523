//
// validateFileExtension.ts
//

/**
 * Validates the file name based on allowed extensions and whether an extension is required.
 *
 * @param fileName - The name of the file to validate.
 * @param allowedExtensions - An array of allowed file extensions (e.g., [".png", ".jpg"]).
 * @param allowWithoutExtension - A boolean indicating whether files without extensions are allowed.
 *
 * @returns A string with an error message if invalid, or null if the file name is valid.
 */
export const validateFileExtension = (
  fileName: string,
  allowedExtensions: string[],
  allowWithoutExtension: boolean
): string | undefined => {
  // Ensure the file name is not empty
  if (!fileName) {
    return "File name cannot be empty.";
  }

  // Find the last occurrence of a dot to determine the extension
  const extensionIndex = fileName.lastIndexOf(".");
  const hasExtension = extensionIndex !== -1;
  const extension = hasExtension
    ? fileName.slice(extensionIndex).toLowerCase()
    : "";

  // If an extension is required but missing, return an error
  if (!hasExtension && !allowWithoutExtension) {
    return "File must have an extension.";
  }

  // If an extension is present but not allowed, return an error
  if (hasExtension && !allowedExtensions.includes(extension)) {
    return `Invalid file extension. Allowed: ${allowedExtensions.join(", ")}`;
  }

  return undefined; // No errors
};
