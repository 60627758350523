import * as Headless from "@headlessui/react";
import clsx from "clsx";
import type React from "react";
export function CheckboxGroup({
  className,
  ...props
}: React.ComponentPropsWithoutRef<"div">) {
  return <div data-slot="control" {...props} className={clsx(className,
  // Basic groups
  "tw-space-y-3",
  // With descriptions
  "has-[[data-slot=description]]:tw-space-y-6 [&_[data-slot=label]]:has-[[data-slot=description]]:tw-font-medium")} data-sentry-component="CheckboxGroup" data-sentry-source-file="Checkbox.tsx" />;
}
export function CheckboxField({
  className,
  ...props
}: {
  className?: string;
} & Omit<Headless.FieldProps, "as" | "className">) {
  return <Headless.Field data-slot="field" {...props} className={clsx(className,
  // Base layout
  "tw-grid tw-grid-cols-[1.125rem_1fr] tw-items-center tw-gap-x-4 tw-gap-y-1 sm:tw-grid-cols-[1rem_1fr]",
  // Control layout
  "[&>[data-slot=control]]:tw-col-start-1 [&>[data-slot=control]]:tw-row-start-1 [&>[data-slot=control]]:tw-justify-self-center",
  // Label layout
  "[&>[data-slot=label]]:tw-col-start-2 [&>[data-slot=label]]:tw-row-start-1 [&>[data-slot=label]]:tw-justify-self-start",
  // Description layout
  "[&>[data-slot=description]]:tw-col-start-2 [&>[data-slot=description]]:tw-row-start-2",
  // With description
  "[&_[data-slot=label]]:has-[[data-slot=description]]:tw-font-medium")} data-sentry-element="unknown" data-sentry-component="CheckboxField" data-sentry-source-file="Checkbox.tsx" />;
}

//
// types
//
type Sizes = "medium";
type Variants = "primary";
type StyleItem = string[];
type SizesStyle = { [K in Sizes]: StyleItem };
type VariantStyle = { [K in Variants]: StyleItem };

//
// component
//

// Define generic styles for all switches
const sizes: SizesStyle = {
  medium: ["tw-size-5"]
};
const base = [
// Basic layout
"tw-relative tw-isolate tw-flex tw-items-center tw-justify-center tw-rounded",
// Background color + shadow applied to inset pseudo element, so shadow blends with border in light mode
"before:tw-absolute before:tw-inset-0 before:-tw-z-10 before:tw-rounded-[calc(0.3125rem-1px)] before:tw-bg-[--checkbox-unchecked-bg]",
// Background color when checked
"before:group-data-[checked]:tw-bg-[--checkbox-checked-bg]",
// Background color is moved to control and shadow is removed in dark mode so hide `before` pseudo
"tw-dark:before:tw-hidden",
// Background color applied to control in dark mode
"tw-dark:group-data-[checked]:tw-bg-[--checkbox-checked-bg]",
// Focus
"focus:tw-outline-none group-data-[focus]:tw-outline group-data-[focus]:tw-outline-1 group-data-[focus]:tw-outline-offset-[0.1875rem] group-data-[focus]:tw-outline-brandBlues-brandSecondary-light tw-dark:group-data-[focus]:tw-outline-brandBlues-brandSecondary-dark",
// Disabled state light
"group-data-[disabled]:tw-bg-interface-gray-light group-data-[disabled]:[--checkbox-check:theme(colors.interface.card.light)] group-data-[disabled]:before:tw-bg-transparent",
// Disabled state dark
"tw-dark:group-data-[disabled]:tw-bg-interface-gray-dark tw-dark:group-data-[disabled]:[--checkbox-check:theme(colors.interface.card.dark)] tw-dark:group-data-[disabled]:group-data-[checked]:after:tw-hidden",
// Forced colors mode
"tw-forced-colors:[--checkbox-check:HighlightText] tw-forced-colors:[--checkbox-checked-bg:Highlight] tw-forced-colors:group-data-[disabled]:[--checkbox-check:Highlight]", "tw-dark:tw-forced-colors:[--checkbox-check:HighlightText] tw-dark:tw-forced-colors:[--checkbox-checked-bg:Highlight] tw-dark:tw-forced-colors:group-data-[disabled]:[--checkbox-check:Highlight]"];

// Define variant colors for the checkbox
const variants: VariantStyle = {
  primary: [
  // unchecked color light
  "[--checkbox-unchecked-bg:theme(colors.brandBlues.brandSecondary.light)]",
  // unchecked color dark
  "tw-dark:[--checkbox-unchecked-bg:theme(colors.brandBlues.brandSecondary.dark)]",
  // checked color light
  "[--checkbox-check:theme(colors.interface.card.light)] [--checkbox-checked-bg:theme(colors.brandBlues.brandSecondary.light)] [--checkbox-checked-border:theme(colors.transparent)]",
  // checked color dark
  "tw-dark:[--checkbox-check:theme(colors.interface.card.dark)] tw-dark:[--checkbox-checked-bg:theme(colors.brandBlues.brandSecondary.dark)] tw-dark:[--checkbox-checked-border:theme(colors.transparent)]"]
};

// Button Props
export type CheckboxProps = {
  variant?: Variants;
  size?: Sizes;
  className?: string;
} & Omit<Headless.CheckboxProps, "as" | "className">;
export function Checkbox({
  variant = "primary",
  size = "medium",
  className,
  ...props
}: CheckboxProps) {
  return <Headless.Checkbox data-slot="control" {...props} className={clsx(className, "tw-group tw-inline-flex focus:tw-outline-none")} data-sentry-element="unknown" data-sentry-component="Checkbox" data-sentry-source-file="Checkbox.tsx">
      <span className={clsx([base, sizes[size], variants[variant]])}>
        <svg className="tw-size-5 tw-fill-[--checkbox-check] tw-opacity-0 group-data-[checked]:tw-opacity-100" viewBox="0 0 14 14" fill="none" data-sentry-element="svg" data-sentry-source-file="Checkbox.tsx">
          {/* Checkmark icon */}
          <path className="tw-opacity-100 group-data-[indeterminate]:tw-opacity-0" d="M5.11676 9.42859L3.09295 7.40426C3.039 7.35018 2.97491 7.30726 2.90436 7.27798C2.8338 7.2487 2.75816 7.23363 2.68178 7.23363C2.60539 7.23363 2.52975 7.2487 2.4592 7.27798C2.38864 7.30726 2.32455 7.35018 2.2706 7.40426C2.04314 7.63178 2.04314 7.99931 2.2706 8.22683L4.7085 10.6654C4.93596 10.8929 5.30339 10.8929 5.53085 10.6654L11.7014 4.4932C11.9289 4.26568 11.9289 3.89815 11.7014 3.67063C11.6475 3.61654 11.5834 3.57363 11.5128 3.54435C11.4423 3.51507 11.3666 3.5 11.2902 3.5C11.2138 3.5 11.1382 3.51507 11.0677 3.54435C10.9971 3.57363 10.933 3.61654 10.8791 3.67063L5.11676 9.42859Z" data-sentry-element="path" data-sentry-source-file="Checkbox.tsx" />
          {/* Indeterminate icon */}
          <path className="tw-opacity-0 group-data-[indeterminate]:tw-opacity-100" d="M3 7H11" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="Checkbox.tsx" />
        </svg>
      </span>
    </Headless.Checkbox>;
}