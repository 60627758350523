//
// normalizeBackendErrorMessage.ts
//

import { BackendErrorResponse } from "@data-types/backend-response-types";
import { ServerType } from "@data-types/client-fetch-types";

/**
 * Normalizes errors from various data sources to a unified structure.
 *
 * @param error - The original error object.
 * @param source - Specifies the source of the error.
 * @returns A normalized `BackendErrorResponse` object.
 */
export function normalizeBackendError(
  error: any,
  serverType: ServerType
): BackendErrorResponse {
  let normalizedError: BackendErrorResponse;

  switch (serverType) {
    case "backend":
      // For backend errors
      normalizedError = {
        name: error.name || "Backend Error",
        endpoint: error.endpoint || "Unknown endpoint",
        message: error.message || "An error occurred on the backend",
        status: error.status || 500,
      };
      break;

    case "gateway":
      // For gateway errors
      normalizedError = {
        name: error.error?.title || "Gateway Error",
        endpoint: extractEndpointFromDetail(error.error?.detail || ""),
        message: error.error?.detail || "An error occurred in the gateway",
        status: parseInt(error.error?.status, 10) || 500,
      };
      break;

    case "dashboard":
      // For dashboard errors
      normalizedError = {
        name: error.name || "Dashboard Error",
        endpoint: error.endpoint || "Unknown endpoint",
        message: error.message || "An error occurred on the dashboard",
        status: error.status || 500,
      };
      break;

    default:
      throw new Error("Unknown data source type");
  }

  return normalizedError;
}

/**
 * Extracts the endpoint from a detail string.
 *
 * @param detail - The detail string that may contain the endpoint.
 * @returns The extracted endpoint or "Unknown endpoint".
 */
function extractEndpointFromDetail(detail: string): string {
  const match = detail.match(/\/[a-zA-Z0-9_/.-]+/);
  return match ? match[0] : "Unknown endpoint";
}
