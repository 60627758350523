//
// useGetProjectRuntime.ts
//

import { useFetchData } from "@custom-hooks/fetch-data";
import { FetchHookResult } from "@data-types/generic-hook-type";
import { ProjectRuntime } from "@data-types/projects-types";
import { useAppState } from "@state/AppStateProvider";
import { useEffect, useState } from "react";

/**
 * Custom hook to fetch the runtime details of a project.
 *
 * @param {string} projectId - The ID of the project.
 *
 * @returns {FetchHookResult<ProjectRuntime>} The fetched project runtime data.
 */
export function useGetProjectRuntime({
  projectId,
}: {
  projectId: string;
}): FetchHookResult<ProjectRuntime> {
  const [isProjectRunning, setIsProjectRunning] = useState(false);
  const { projectsInScalingUpState, removeScalingUpProject } = useAppState();

  //TODO:TIZ:DAMLA Not show this error in the snack notification
  const { data, ...fetchDetails } = useFetchData<ProjectRuntime>({
    fetchCondition: projectId !== "",
    swrKey: {
      url: `/api/projects/${projectId}/runtime`,
      component: "useGetProjectRuntime",
    },
    source: "backend",
    options: {
      revalidateOnFocus: true,
      refreshInterval: isProjectRunning ? undefined : 10000,
    },
  });

  useEffect(() => {
    const isRunning = data?.status === "running";

    if (isRunning !== isProjectRunning) {
      setIsProjectRunning(isRunning);
    }

    if (
      projectsInScalingUpState?.includes(projectId) &&
      data?.status !== "scaledToZero"
    ) {
      removeScalingUpProject(projectId);
    }
  }, [data?.status, isProjectRunning]);

  return {
    data,
    ...fetchDetails,
  };
}
