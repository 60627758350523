"use client";

//
// dropdown.tsx
//
import * as Headless from "@headlessui/react";
import clsx from "clsx";
import type React from "react";
import { useRef, useState } from "react";
import ReactDOM from "react-dom";
import { Button } from "./Button";
import { Link } from "./Link";
export function Dropdown(props: Headless.MenuProps) {
  return <Headless.Menu as="div" {...props} data-sentry-element="unknown" data-sentry-component="Dropdown" data-sentry-source-file="Dropdown.tsx" />;
}
export function DropdownButton<T extends React.ElementType = typeof Button>({
  as = Button,
  ...props
}: {
  className?: string;
} & Omit<Headless.MenuButtonProps<T>, "className">) {
  return <Headless.MenuButton as={as} {...props} data-sentry-element="unknown" data-sentry-component="DropdownButton" data-sentry-source-file="Dropdown.tsx" />;
}

// #region DropdownMenu

// DropdownMenu types
type VariantsDropdownMenu = "base";
type StyleItemDropdownMenu = string[];
type VariantStyleDropdownMenu = { [K in VariantsDropdownItem]: {
  size: StyleItemDropdownMenu;
  colors: StyleItemDropdownMenu;
} };

// DropdownMenu styles
const variantsStyleDropdownMenu: VariantStyleDropdownMenu = {
  base: {
    size: ["tw-w-max"],
    colors: [
    // Popover background light
    "tw-bg-interface-card-light",
    // Popover background dark
    "tw-dark:tw-bg-interface-card-dark",
    // Popover border light
    "tw-border-brandBlues-pale-light",
    // Popover border light
    "tw-dark:tw-border-brandBlues-pale-dark"]
  }
};

// DropdownMenu Props
export type DropdownMenuProps = {
  variant?: VariantsDropdownMenu;
  className?: string;
} & Omit<Headless.MenuItemsProps, "as" | "className">;

// DropdownMenu definition
export function DropdownMenu({
  variant = "base",
  anchor = "bottom",
  className,
  ...props
}: DropdownMenuProps) {
  return <Headless.MenuItems {...props} transition anchor={anchor} className={clsx(className,
  // Anchor positioning
  "[--anchor-gap:theme(spacing.2)] [--anchor-padding:theme(spacing.1)] data-[anchor~=start]:[--anchor-offset:-6px] data-[anchor~=end]:[--anchor-offset:6px] sm:data-[anchor~=start]:[--anchor-offset:-4px] sm:data-[anchor~=end]:[--anchor-offset:4px]",
  // Base styles
  "tw-isolate tw-rounded-lg tw-border tw-px-1 tw-py-1 tw-backdrop-blur-xl",
  // Size
  variantsStyleDropdownMenu[variant].size,
  // Colors
  variantsStyleDropdownMenu[variant].colors,
  // Invisible border that is only visible in `forced-colors` mode for accessibility purposes
  "tw-outline tw-outline-1 tw-outline-transparent focus:tw-outline-none",
  // Handle scrolling when menu won't fit in viewport
  "tw-overflow-y-auto",
  // Shadows
  "tw-shadow-dropdown",
  // Define grid at the menu level if subgrid is supported
  "supports-[grid-template-columns:subgrid]:tw-grid supports-[grid-template-columns:subgrid]:tw-grid-cols-[auto_1fr_1.5rem_0.5rem_auto]",
  // Transitions
  "tw-transition data-[closed]:data-[leave]:tw-opacity-0 data-[leave]:tw-duration-100 data-[leave]:tw-ease-in")} data-sentry-element="unknown" data-sentry-component="DropdownMenu" data-sentry-source-file="Dropdown.tsx" />;
}

// #endregion

// #region DropdownItem

// DropdownItem types
type VariantsDropdownItem = "base";
type StyleItemDropdownItem = string[];
type VariantStyleDropdownItem = { [K in VariantsDropdownItem]: {
  size: StyleItemDropdownItem;
  colors: StyleItemDropdownItem;
} };

// DropdownItem styles
const variantsStyleDropdownItem: VariantStyleDropdownItem = {
  base: {
    size: ["tw-h-9"],
    colors: [
    // Text light default
    "tw-text-text-title-light [&>[data-slot=icon]]:tw-text-title-light ",
    // Text dark default
    "tw-dark:tw-text-text-title-dark [&>[data-slot=icon]]:tw-dark:tw-text-title-dark",
    // Focus light
    "data-[focus]:tw-bg-interface-innerCard-light ",
    // Focus dark
    "tw-dark:data-[focus]:tw-bg-interface-innerCard-dark",
    // Disable text light
    "data-[disabled]:tw-text-interface-gray-light",
    // Disable text dark
    "tw-dark:data-[disabled]:tw-text-interface-gray-dark",
    // Disable background light
    "data-[disabled]:tw-bg-interface-card-light",
    // Disable background dark
    "tw-dark:data-[disabled]:tw-bg-interface-card-dark"]
  }
};
type DropdownItemTooltipAlignments = "topLeft" | "topRight" | "topMiddle" | "bottomRight" | "bottomLeft" | "bottomMiddle";
type DropdownItemTooltipProps = {
  text: string;
  className?: string;
  alignment?: DropdownItemTooltipAlignments;
  style?: Record<string, any>;
};
function DropdownItemTooltip({
  className = "tw-w-44 tw-fixed",
  style,
  text
}: DropdownItemTooltipProps) {
  return <span style={style} className={clsx(className, "tw-absolute", "tw-rounded-lg tw-px-2 tw-py-2", "tw-whitespace-normal tw-break-words tw-text-center tw-text-12px-regular tw-text-text-subTitle-light dark:tw-text-text-subTitle-dark", "tw-border tw-border-brandBlues-brand-light dark:tw-border-brandBlues-brand-dark", "tw-z-50 tw-bg-interface-card-light dark:tw-bg-interface-card-dark")} data-sentry-component="DropdownItemTooltip" data-sentry-source-file="Dropdown.tsx">
      {text}
    </span>;
}

// DropdownItem Props
export type DropdownItemPropsBase = {
  variant?: VariantsDropdownItem;
  className?: string;
  disabled?: boolean;
} & (Omit<React.ComponentPropsWithoutRef<"button">, "as" | "className"> | Omit<React.ComponentPropsWithoutRef<typeof Link>, "className">);

/**
 * If `href` is defined (indicating a link), `tooltip` should be `never`
 */
export type DropdownItemProps = (DropdownItemPropsBase & {
  href?: never;
  tooltip?: DropdownItemTooltipProps & {
    shouldDisableButton: boolean;
  };
}) | (DropdownItemPropsBase & {
  href: string;
  tooltip?: never;
});

// DropdownItem definition
export function DropdownItem({
  className,
  variant = "base",
  disabled = false,
  tooltip,
  ...props
}: DropdownItemProps) {
  const classes = clsx(className,
  // Base styles
  "tw-group tw-cursor-default tw-rounded-lg tw-px-3",
  // Size
  variantsStyleDropdownItem[variant].size,
  // Colors
  variantsStyleDropdownItem[variant].colors,
  // Text styles
  "tw-text-left tw-text-14px-regular",
  // Focus
  "focus:tw-outline-none",
  // Forced colors mode
  "tw-forced-colors:tw-text-[CanvasText] tw-forced-colors:data-[focus]:tw-bg-[Highlight] tw-forced-colors:data-[focus]:tw-text-[HighlightText] tw-forced-colors:[&>[data-slot=icon]]:data-[focus]:tw-text-[HighlightText] tw-forced-color-adjust-none",
  // Use subgrid when available but fallback to an explicit grid layout if not
  "tw-col-span-full tw-grid tw-grid-cols-[auto_1fr_1.5rem_0.5rem_auto] tw-items-center supports-[grid-template-columns:subgrid]:tw-grid-cols-subgrid",
  // Icons
  "[&>[data-slot=icon]]:tw-col-start-1 [&>[data-slot=icon]]:tw-row-start-1 [&>[data-slot=icon]]:-tw-ml-0.5 [&>[data-slot=icon]]:tw-mr-2.5 [&>[data-slot=icon]]:tw-size-5 sm:[&>[data-slot=icon]]:tw-mr-2 [&>[data-slot=icon]]:sm:tw-size-4",
  // Avatar
  "[&>[data-slot=avatar]]:-tw-ml-1 [&>[data-slot=avatar]]:tw-mr-2.5 [&>[data-slot=avatar]]:tw-size-6 sm:[&>[data-slot=avatar]]:tw-mr-2 sm:[&>[data-slot=avatar]]:tw-size-5",
  // Transition
  "tw-transition-colors tw-duration-200 tw-ease-in-out");
  const {
    children,
    ...rest
  } = props;
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [tooltipPosition, setTooltipPosition] = useState({
    top: 0,
    left: 0
  });

  // When the mouse enters, update the tooltip position based on the button’s position.
  const updateTooltipPosition = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      // Adjust these values as needed to position the tooltip
      setTooltipPosition({
        top: rect.bottom,
        left: rect.left - 88
      });
    }
  };
  return <Headless.MenuItem disabled={disabled || tooltip?.shouldDisableButton} data-sentry-element="unknown" data-sentry-component="DropdownItem" data-sentry-source-file="Dropdown.tsx">
      {"href" in props && props.href ? <Link {...rest as {
      href: string;
    }} className={clsx("tw-cursor-pointer", classes)}>
          {children}
        </Link> : <Headless.Button
    // Omit the type property from props to avoid conflict
    type="button" {...rest as Omit<React.ComponentPropsWithoutRef<"button">, "type">} className={clsx(classes, "tw-cursor-pointer")} ref={buttonRef}
    // Update the position when the user hovers over the button
    onMouseEnter={updateTooltipPosition}>
          {({
        hover
      }) => <>
              <TouchTarget>{children}</TouchTarget>

              {hover && tooltip && ReactDOM.createPortal(<DropdownItemTooltip {...tooltip}
        // Use inline styles to position the tooltip at the same spot as the button
        style={{
          position: "absolute",
          top: tooltipPosition.top,
          left: tooltipPosition.left
        }} />, document.body)}
            </>}
        </Headless.Button>}
    </Headless.MenuItem>;
}

// #endregion

export function DropdownHeader({
  className,
  ...props
}: React.ComponentPropsWithoutRef<"div">) {
  return <div {...props} className={clsx(className, "tw-col-span-5 tw-px-3.5 tw-pb-1 tw-pt-2.5 sm:tw-px-3")} data-sentry-component="DropdownHeader" data-sentry-source-file="Dropdown.tsx" />;
}
export function DropdownSection({
  className,
  ...props
}: {
  className?: string;
} & Omit<Headless.MenuSectionProps, "as" | "className">) {
  return <Headless.MenuSection {...props} className={clsx(className,
  // Define grid at the section level instead of the item level if subgrid is supported
  "tw-col-span-full supports-[grid-template-columns:subgrid]:tw-grid supports-[grid-template-columns:subgrid]:tw-grid-cols-[auto_1fr_1.5rem_0.5rem_auto]")} data-sentry-element="unknown" data-sentry-component="DropdownSection" data-sentry-source-file="Dropdown.tsx" />;
}
export function DropdownHeading({
  className,
  ...props
}: {
  className?: string;
} & Omit<Headless.MenuHeadingProps, "as" | "className">) {
  return <Headless.MenuHeading {...props} className={clsx(className, "tw-text-sm/5 sm:tw-text-xs/5 tw-dark:tw-text-zinc-400 tw-col-span-full tw-grid tw-grid-cols-[1fr,auto] tw-gap-x-12 tw-px-3.5 tw-pb-1 tw-pt-2 tw-font-medium tw-text-zinc-500 sm:tw-px-3")} data-sentry-element="unknown" data-sentry-component="DropdownHeading" data-sentry-source-file="Dropdown.tsx" />;
}
export function DropdownDivider({
  className,
  ...props
}: {
  className?: string;
} & Omit<Headless.MenuSeparatorProps, "as" | "className">) {
  return <Headless.MenuSeparator {...props} className={clsx(className, "tw-dark:tw-bg-white/10 tw-forced-colors:tw-bg-[CanvasText] tw-col-span-full tw-mx-3.5 tw-my-1 tw-h-px tw-border-0 tw-bg-zinc-950/5 sm:tw-mx-3")} data-sentry-element="unknown" data-sentry-component="DropdownDivider" data-sentry-source-file="Dropdown.tsx" />;
}
export function DropdownLabel({
  className,
  ...props
}: {
  className?: string;
} & Omit<Headless.LabelProps, "as" | "className">) {
  return <Headless.Label {...props} data-slot="label" className={clsx(className, "tw-col-span-full tw-col-start-2 tw-row-start-1")} {...props} data-sentry-element="unknown" data-sentry-component="DropdownLabel" data-sentry-source-file="Dropdown.tsx" />;
}
export function DropdownDescription({
  className,
  ...props
}: {
  className?: string;
} & Omit<Headless.DescriptionProps, "as" | "className">) {
  return <Headless.Description data-slot="description" {...props} className={clsx(className, "tw-text-sm/5 sm:tw-text-xs/5 tw-group-data-[focus]:tw-text-white tw-dark:tw-text-zinc-400 tw-forced-colors:tw-group-data-[focus]:tw-text-[HighlightText] tw-col-span-2 tw-col-start-2 tw-row-start-2 tw-text-zinc-500")} data-sentry-element="unknown" data-sentry-component="DropdownDescription" data-sentry-source-file="Dropdown.tsx" />;
}
export function DropdownShortcut({
  keys,
  className,
  ...props
}: {
  keys: string | string[];
  className?: string;
} & Omit<Headless.DescriptionProps<"kbd">, "as" | "className">) {
  return <Headless.Description as="kbd" {...props} className={clsx(className, "tw-col-start-5 tw-row-start-1 tw-flex tw-justify-self-end")} data-sentry-element="unknown" data-sentry-component="DropdownShortcut" data-sentry-source-file="Dropdown.tsx">
      {(Array.isArray(keys) ? keys : keys.split("")).map((char, index) => <kbd key={index} className={clsx(["tw-group-data-[focus]:tw-text-white tw-forced-colors:tw-group-data-[focus]:tw-text-[HighlightText] tw-min-w-[2ch] tw-text-center tw-font-sans tw-capitalize tw-text-zinc-400",
    // Make sure key names that are longer than one character (like "Tab") have extra space
    index > 0 && char.length > 1 && "tw-pl-1"])}>
          {char}
        </kbd>)}
    </Headless.Description>;
}

/**
 * Expand the hit area to at least 44×44px on touch devices
 */
function TouchTarget({
  children
}: {
  children: React.ReactNode;
}) {
  return <>
      <span className="tw-absolute tw-left-1/2 tw-top-1/2 tw-size-[max(100%,2.75rem)] -tw-translate-x-1/2 -tw-translate-y-1/2 [@media(pointer:fine)]:tw-hidden" aria-hidden="true" />
      {children}
    </>;
}