/**
 * Computes the style configuration for a Select Input component based on the specified style variant.
 *
 * @param {number} styleVariant - The variant type to determine the style.
 * @param {string | undefined} label - The label text for the input component (optional).
 * @returns {{
 *   variant: string;
 *   textFieldSx: Record<string, any>;
 *   selectSx: Record<string, any>;
 *   label?: string;
 *   className: string;
 * }} The style configuration for the Select Input.
 */
export function computeInputSelectStyle(
  styleVariant: number,
  label?: string
): {
  variant: string;
  textFieldSx: Record<string, any>;
  selectSx: Record<string, any>;
  label?: string;
  className: string;
} {
  let variant = "outlined";
  let className = "";
  let textFieldSx: Record<string, any> = {};
  let selectSx: Record<string, any> = {};

  switch (styleVariant) {
    case 0:
      variant = "outlined";
      textFieldSx = {
        width: "100%",
        mt: 2,
      };
      selectSx = {
        width: "100%",
      };
      break;
    case 1:
      variant = "outlined";
      selectSx = {
        width: "16.5rem",
      };
      break;
    case 2:
      variant = "standard";
      className = "console";
      textFieldSx = {
        minWidth: "fit-content",
        padding: "0 1rem",
        borderRadius: 0,
        borderTopLeftRadius: "8px",
        borderRight: "#5A5877 solid 1px",
      };
      selectSx = {
        boxSizing: "content-box",
        background: "black",
        color: "#F0F5FD",
        fontFamily: "Roboto Mono",
        width: "8rem",
        height: "100%",
      };
      label = undefined;
      break;
    case 3:
      variant = "outlined";
      textFieldSx = {
        width: "100%",
      };
      selectSx = {
        width: "100%",
      };
      break;
    default:
      variant = "outlined";
      selectSx = {
        width: "16.5rem",
      };
      break;
  }

  return {
    variant,
    textFieldSx,
    selectSx,
    label,
    className,
  };
}
