//
// useGetProjectLookupKey.ts
//

import { useGetUserSession } from "@custom-hooks/user";
import { AddonLookupKey } from "@data-types/billing-types";
import { useMemo } from "react";

/**
 * A React hook that retrieves the `AddonLookupKey` for a given project ID based on the user's session data.
 *
 * This hook extracts the project data from the user's session and maps its `plan_id` to the appropriate `AddonLookupKey`.
 *
 * - If the session is not yet available or the project is not found, the hook returns `null`.
 * - The mapping between `plan_id` and `AddonLookupKey` is performed using a switch statement.
 *
 * @param {string} projectId - The ID of the project whose `AddonLookupKey` is to be retrieved.
 *
 * @returns {AddonLookupKey | null} The corresponding `AddonLookupKey` for the project, or `null` if not found.
 */
export function useGetProjectLookupKey(
  projectId: string
): AddonLookupKey | null {
  // Get user session and its status using a custom hook
  const { hasData, data: session } = useGetUserSession();

  return useMemo(() => {
    if (projectId === "") {
      return null;
    }

    // Check if session data is unavailable
    if (!hasData || !session) {
      return null;
    }

    // Retrieve the project data using the provided project ID
    const project = session?.authorizations?.projects[projectId];

    // Check if the project exists and has attributes
    if (!project || !project.attributes) {
      console.warn(`Project with ID ${projectId} not found in authorizations.`);
      return null;
    }

    // Extract the plan ID from project attributes
    const planId =
      (project.attributes as unknown as Record<string, string>).plan_id ||
      AddonLookupKey.SANDBOX_PROJECT;

    // Return the determined lookup key
    return planId as AddonLookupKey;
  }, [hasData, session, projectId]);
}
