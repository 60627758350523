//
// useApi.j
//

import { useGetProjectDatabases } from "@custom-hooks/databases/index";
import { useGetProjectNodes } from "@custom-hooks/nodes";
import { useSetError } from "@custom-hooks/useSetError";
import { fetchApiRoute, renderAnalyzer } from "@lib/client-side";
import { generateRandomId } from "@lib/iso-utils";
import { useAppState } from "@state/AppStateProvider";
import _ from "lodash";
import { useEffect, useState } from "react";
import useSWR, { useSWRConfig } from "swr";
import useSWRInfinite from "swr/infinite";
import { swrFetcher } from "./fetch-data";
import { useGetCurrentProjectId, useGetProject } from "./projects";
import { useGetUserSession } from "./user";

const USE_LOCAL_GATEWAY = process.env.NEXT_PUBLIC_USE_LOCAL_GATEWAY;
const EXPERIMENTAL_COMPANIES_ID =
  process.env.NEXT_PUBLIC_EXPERIMENTAL_COMPANIES_ID;
const experimentalCompaniesId = EXPERIMENTAL_COMPANIES_ID.split(",");
const LOGS_URL = process.env.NEXT_PUBLIC_LOGS_URL;
const LOGS_APIKEY = process.env.NEXT_PUBLIC_LOGS_APIKEY;

// #region BUILD Related
function useGetBuildId() {
  const { data, error, isValidating } = useSWR(
    { url: "/api/build-id", component: "useGetBuildId" },
    swrFetcher,
    {
      revalidateOnFocus: true,
    }
  );
  const showLoader = (!error && !data) || isValidating;

  return {
    buildId: data !== undefined ? data.buildId : data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
  };
}
// #endregion

// #region USER Related

function useGetSqlitecloudCompanyUser() {
  const { data: session } = useGetUserSession();
  const { simulateGenericUser } = useAppState();
  const [isSqlitecloudCompanyUser, setIsSqlitecloudCompanyUser] =
    useState(false);

  useEffect(() => {
    if (session) {
      const companyId =
        session?.authorizations?.organizations?.[0]?.organizationId;
      const testCompany = experimentalCompaniesId.includes(companyId);
      if (!testCompany) {
        setIsSqlitecloudCompanyUser(false);
      } else if (testCompany) {
        if (simulateGenericUser) {
          setIsSqlitecloudCompanyUser(false);
        } else {
          setIsSqlitecloudCompanyUser(true);
        }
      }
    }
  }, [session, simulateGenericUser]);

  return isSqlitecloudCompanyUser;
}

// #endregion

// #region PROJECTS related API

function useGetProjectInfo(projectId) {
  //get actual url
  let url = "";
  if (typeof window !== "undefined") {
    url = new URL(window.location.href);
  }

  const currentProjectId = useGetCurrentProjectId();
  const projectIdToUse = projectId ? projectId : currentProjectId;

  //get actual project info
  const { data: project, showLoader } = useGetProject(projectIdToUse || "");

  //build project data
  const id = project && project.id ? project.id : undefined;
  const description =
    project && project.description ? project.description : undefined;
  const name = project && project.name ? project.name : undefined;
  const adminApiKey =
    project && project.admin_apikey ? project.admin_apikey : undefined;
  const adminPassword =
    project && project.admin_password ? project.admin_password : undefined;
  const adminUsername =
    project && project.admin_username ? project.admin_username : undefined;
  const connectionStringApiKey =
    project && project.connection_string
      ? project.connection_string
      : undefined;

  const gatewayString =
    project && project.gateway_string ? project.gateway_string : undefined;
  const extractDeployment = (url) => {
    const regex = /\/([^\/:]+):/;
    const matches = url.match(regex);
    if (matches && matches.length > 1) {
      const extractedPart = matches[1];
      return extractedPart;
    } else {
      return "not defined";
    }
  };
  const deployment = connectionStringApiKey
    ? extractDeployment(connectionStringApiKey)
    : undefined;
  let gatewayDeployment = "";
  let gatewayUrlHTTP = "";
  let gatewayUrlWS = "";
  if (USE_LOCAL_GATEWAY && USE_LOCAL_GATEWAY.toLowerCase() === "true") {
    gatewayDeployment = url && "localhost";
    gatewayUrlHTTP = url && `http://localhost:8090`;
    gatewayUrlWS =
      url && `${url.protocol === "https:" ? "wss" : "ws"}://localhost:4000`;
  } else {
    gatewayDeployment = url && deployment;
    gatewayUrlHTTP = url && gatewayString;
    // console.log(gatewayUrlHTTP) //TOGLI
    // gatewayUrlHTTP = url && deployment && `https://${deployment}:8090`;
    gatewayUrlWS = url && deployment && `wss://${deployment}:4000`;
  }
  return {
    showLoader,
    deployment,
    id,
    name,
    description,
    adminApiKey,
    adminPassword,
    adminUsername,
    connectionStringApiKey,
    gatewayDeployment,
    gatewayUrlHTTP,
    gatewayUrlWS,
  };
}

// #endregion

function useGetActualNodesNumber(prjId) {
  const { data: nodes } = useGetProjectNodes(prjId);
  const [actualNodesNumber, setActualNodesNumber] = useState(undefined);

  useEffect(() => {
    if (nodes && Array.isArray(nodes) && nodes.length >= 0) {
      const actualNodesNumber = nodes.length;
      setActualNodesNumber(actualNodesNumber);
    }
  }, [nodes]);

  return actualNodesNumber;
}

function useGetProjectLogsInfo() {
  const projectId = useGetCurrentProjectId();

  const { data, error, isValidating } = useSWR(
    () =>
      projectId && {
        url: `${LOGS_URL}${projectId}/info`,
        component: "useGetProjectLogsInfo",
        auth: LOGS_APIKEY,
      },
    swrFetcher,
    {
      revalidateOnFocus: false,
    }
  );

  const { hasData, emptyData, showLoader } = renderAnalyzer(
    data,
    error,
    isValidating,
    "gateway"
  );

  return {
    info: data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
    hasData,
    emptyData,
  };
}

function useGetProjectLogs(
  filters = {},
  live = false,
  swr = true,
  queryInUrl = false,
  id
) {
  //states
  const [last, setLast] = useState(false);
  const [logs, setLogs] = useState(undefined);

  const multiplier = 15;
  //generate query string to be updated in the url
  // if (queryInUrl && swr) {
  // const urlFilterCopy = _.cloneDeep(filters);
  // //convert time to UTC
  // if (urlFilterCopy["fromTime"]) {
  // 	urlFilterCopy["fromTime"] = convertDateToUtc0(urlFilterCopy["fromTime"]);
  // }
  // if (urlFilterCopy["toTime"]) {
  // 	urlFilterCopy["toTime"] = convertDateToUtc0(urlFilterCopy["toTime"]);
  // }
  // //remove key to not be saved in url query string
  // delete urlFilterCopy["createdAt"];
  // const queryStringsForUrl = Object.entries(urlFilterCopy).map((obj) => obj[1] && obj[1] != "" ? Array.isArray(obj[1]) ? obj[1].map((val) => val && val != "" ? `${obj[0]}=${val}` : undefined) : `${obj[0]}=${obj[1]}` : undefined);
  // let newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + queryStringsForUrl.flat().filter(x => typeof x === 'string' && x.length > 0).join('&');
  // console.log(id + ", HERE")
  // window.history.pushState({}, '', newUrl);
  // }
  //generated query string to be send to gateway removing timeline, fromTime, toTime
  const gatewayFilterCopy = _.cloneDeep(filters);
  delete gatewayFilterCopy["timeline"];
  delete gatewayFilterCopy["fromTime"];
  delete gatewayFilterCopy["toTime"];
  const queryStrings = Object.entries(gatewayFilterCopy).map((obj) =>
    obj[1] && obj[1] != ""
      ? Array.isArray(obj[1])
        ? obj[1].map((val) =>
            val && val != "" ? `${obj[0]}=${val}` : undefined
          )
        : `${obj[0]}=${obj[1]}`
      : undefined
  );
  const projectId = useGetCurrentProjectId();
  const getKey = (pageIndex, previousPageData) => {
    if (!swr) return null;
    if (previousPageData && !previousPageData.data) return null;
    if (pageIndex === 0)
      return () =>
        projectId !== null && {
          url:
            `${LOGS_URL}${projectId}?` +
            queryStrings
              .flat()
              .filter((x) => typeof x === "string" && x.length > 0)
              .join("&") +
            `&limit=${multiplier}`,
          component: "useGetProjectLogs",
          auth: LOGS_APIKEY,
        };
    return () =>
      projectId && {
        url:
          `${LOGS_URL}${projectId}?` +
          queryStrings
            .flat()
            .filter((x) => typeof x === "string" && x.length > 0)
            .join("&") +
          `&limit=${multiplier}` +
          `&offset=${pageIndex * multiplier}`,
        component: "useGetProjectLogs",
        auth: LOGS_APIKEY,
      };
  };
  const { data, error, isValidating, size, setSize } = useSWRInfinite(
    getKey,
    swrFetcher,
    {
      revalidateOnFocus: false,
      revalidateFirstPage: live,
      revalidateAll: live,
      refreshInterval: live ? 5000 : 0,
    }
  );

  const { hasData, emptyData, showLoader } = renderAnalyzer(
    data ? data[0] : undefined,
    error,
    isValidating,
    "gateway"
  );

  //concatenate logs received from gateway in different pages
  useEffect(() => {
    if (data) {
      const concatenatedLogs = { data: [] };
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      Array.isArray(data) &&
        data.forEach((log) => {
          if (log.data.length > 0) {
            if (last) setLast(false);
            if (concatenatedLogs.data.length > 0) {
              concatenatedLogs.data = concatenatedLogs.data.concat(log.data);
            } else {
              concatenatedLogs.data = log.data;
            }
          } else {
            setLast(true);
          }
        });
      setLogs(concatenatedLogs.data);
    } else {
      setLogs(undefined);
    }
  }, [data]);

  //increment size in live mode
  const len =
    Math.trunc((logs && logs.length > 0 ? logs.length : 0) / multiplier) + 1;
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  live && len > size && setSize(len);

  return {
    logs,
    last,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
    hasData,
    emptyData,
    size,
    setSize,
  };
}

function useGetHardwares() {
  const { data, error, isValidating } = useSWR(
    { url: `/api/hardwares`, component: "useGetHardwares" },
    swrFetcher,
    {
      revalidateOnFocus: false,
    }
  );

  const showLoader = (!error && !data) || isValidating;

  return {
    hardwares: data !== undefined ? data.value : data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
  };
}

// #endregion

// #region DATABASES related API

function useGetDatabasesTotSize() {
  const currentProjectId = useGetCurrentProjectId();
  const { data: databases } = useGetProjectDatabases(currentProjectId || "");
  const [totSize, setTotSize] = useState(null);
  useEffect(() => {
    if (databases) {
      let sum = 0;
      for (let obj of databases) {
        sum += obj.size;
      }
      setTotSize(sum);
    }
  }, [databases]);
  return totSize;
}

// #endregion

// #region SETTINGS related API
function useGetSettings() {
  const projectId = useGetCurrentProjectId();
  const { data, error, isValidating } = useSWR(
    () =>
      projectId && {
        url: `/api/projects/${projectId}/settings`,
        component: "useGetSettings",
      },
    swrFetcher,
    {
      revalidateOnFocus: false,
    }
  );
  const showLoader = (!error && !data) || isValidating;

  return {
    settings: data !== undefined ? data.value : data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
  };
}

function useGetAnalyzerSettings() {
  const { settings, error, showLoader } = useGetSettings();
  const [analyzerState, setAnalyzerState] = useState(false);
  const [analyzerThreshold, setAnalyzerThreshold] = useState(null);
  useEffect(() => {
    if (settings && !error && !showLoader) {
      settings.forEach((setting) => {
        if (setting.key == "query_analyzer_enabled") {
          setAnalyzerState(setting.value == "1" ? true : false);
        }
        if (setting.key == "query_analyzer_threshold") {
          setAnalyzerThreshold(setting.value);
        }
      });
    }
  }, [settings, error, showLoader]);

  return { analyzerState, analyzerThreshold, error, showLoader };
}

function useSetSetting(opt = {}) {
  //get actual project id
  const projectId = useGetCurrentProjectId();
  //get method to call editing api
  const { loading, error, editedData, mutatedData, mutatingData, editData } =
    useEditData(opt);
  const setSetting = async (setting) => {
    const body = {
      value: setting.value,
    };
    const opt = {
      method: "POST",
      endpoint: `/api/projects/${projectId}/setting/${setting.key}`,
      endpointCallLocation: "useApi.js",
      body: body,
      mutateApis: [
        {
          url: `/api/projects/${projectId}/settings`,
          component: "useGetSettings",
        },
      ],
    };
    await editData(opt);
  };
  return { loading, error, editedData, mutatedData, mutatingData, setSetting };
}

// #endregion

// #region COMMANDS related API
function useGetCommands() {
  const projectId = useGetCurrentProjectId();
  const { data, error, isValidating } = useSWR(
    () =>
      projectId && {
        url: `/api/projects/${projectId}/commands`,
        component: "useGetCommands",
      },
    swrFetcher,
    {
      revalidateOnFocus: false,
    }
  );
  const showLoader = (!error && !data) || isValidating;

  return {
    commands: data !== undefined ? data.value : data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
  };
}

// #endregion

// #region PLUGINS related API
function useGetPlugins() {
  const projectId = useGetCurrentProjectId();
  const { data, error, isValidating } = useSWR(
    () =>
      projectId && {
        url: `/api/projects/${projectId}/plugins`,
        component: "useGetPlugins",
      },
    swrFetcher,
    {
      revalidateOnFocus: false,
    }
  );
  const showLoader = (!error && !data) || isValidating;

  return {
    plugins: data !== undefined ? data.value : data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
  };
}

// #endregion

// #region IPS related API
function useGetIps() {
  const projectId = useGetCurrentProjectId();
  const { data, error, isValidating } = useSWR(
    () =>
      projectId && {
        url: `/api/projects/${projectId}/ips`,
        component: "useGetIps",
      },
    swrFetcher,
    {
      revalidateOnFocus: false,
    }
  );
  const { hasData, emptyData, showLoader } = renderAnalyzer(
    data,
    error,
    isValidating,
    "backend"
  );

  return {
    ips: data !== undefined ? data.value : data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
    hasData,
    emptyData,
  };
}

// #endregion

// #region ENV related API
function useGetProjectEnv() {
  const projectId = useGetCurrentProjectId();
  const { data, error, isValidating } = useSWR(
    () =>
      projectId && {
        url: `/api/projects/${projectId}/env`,
        component: "useGetProjectEnv",
      },
    swrFetcher,
    {
      revalidateOnFocus: false,
    }
  );
  const { hasData, emptyData, showLoader } = renderAnalyzer(
    data,
    error,
    isValidating,
    "backend"
  );

  return {
    projectEnv: data !== undefined ? data.value : data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
    hasData,
    emptyData,
  };
}

// #endregion

// #region OPEN API

function useGetOpenApiManifest_v2() {
  //get project info
  const {
    gatewayDeployment,
    gatewayUrlHTTP,
    deployment,
    connectionStringApiKey,
  } = useGetProjectInfo();
  //openManifestApi states
  const [openManifestApi, setOpenManifestApi] = useState(undefined);
  const [version, setVersion] = useState(undefined);
  //get openManifestApi
  const { data, error, isValidating } = useSWR(
    () =>
      connectionStringApiKey &&
      deployment &&
      gatewayUrlHTTP && {
        url: `${gatewayUrlHTTP}/v2/openapi.json`,
        component: "useGetOpenApiManifest_v2",
      },
    swrFetcher,
    {
      revalidateOnFocus: false,
    }
  );
  const showLoader = (!error && !data) || isValidating;
  useEffect(() => {
    if (!error && !isValidating && data) {
      data.servers[0].url = gatewayUrlHTTP;
      data.servers[0].variables.node.default = gatewayDeployment;
      setVersion(data.info.version);
      setOpenManifestApi(data);
    }
  }, [data, error, isValidating]);
  return {
    showLoader,
    isValidating,
    isError: error,
    connectionStringApiKey,
    gatewayUrlHTTP,
    openManifestApi,
    version,
  };
}
// #endregion

// #region CREATE DATA
function useEditData(opt = {}) {
  //analyze opt
  const startCallback = opt.startCallback ?? null;
  const errorCallback = opt.errorCallback ?? null;
  const editedCallback = opt.editedCallback ?? null;
  const mutatedCallback = opt.mutatedCallback ?? null;
  //get mutate swr method
  const { mutate } = useSWRConfig();
  //creation state
  const [loading, setLoading] = useState(null);

  const [error, setError] = useState(null);
  useSetError(opt.stopGlobalError ? null : error);
  const [editedData, setEditedData] = useState(null);
  const [mutatedData, setMutatedData] = useState(null);
  const [mutatingData, setMutatingData] = useState(false);
  let errorCatch = false;
  //reset state before new edit
  const resetStates = () => {
    setEditedData(null);
    setMutatedData(null);
    setError(null);
  };
  const editData = async (opt) => {
    resetStates();
    if (startCallback) {
      startCallback();
    }
    const { endpointCallLocation, mutateApis } = opt;
    const loadingId = generateRandomId();
    setLoading(true);
    if (mutateApis && Array.isArray(mutateApis) && mutateApis.length > 0) {
      setMutatingData(true);
    }
    setError(null);
    try {
      const data = await fetchApiRoute(opt);
      if (editedCallback) {
        editedCallback(data);
      }
      setEditedData(data);
    } catch (error) {
      setError(error);
      if (errorCallback) {
        errorCallback(error);
      }
      errorCatch = true;
    } finally {
      setLoading(false);
      if (mutateApis && Array.isArray(mutateApis)) {
        if (!errorCatch) {
          let arrayMutatedData = [];
          for (let i = 0; i < mutateApis.length; i++) {
            const awaitMutated = await mutate(mutateApis[i]);
            arrayMutatedData.push(awaitMutated);
          }
          if (mutatedCallback) {
            mutatedCallback();
            setMutatedData(arrayMutatedData);
            setMutatingData(false);
          }
        } else {
          setMutatingData(false);
        }
      }
    }
  };
  return { loading, error, editedData, mutatedData, mutatingData, editData };
}

// #endregion

export {
  useEditData,
  useGetActualNodesNumber,
  useGetAnalyzerSettings,
  useGetBuildId,
  useGetCommands,
  useGetDatabasesTotSize,
  useGetHardwares,
  useGetIps,
  useGetOpenApiManifest_v2,
  useGetPlugins,
  useGetProjectEnv,
  useGetProjectInfo,
  useGetProjectLogs,
  useGetProjectLogsInfo,
  useGetSettings,
  useGetSqlitecloudCompanyUser,
  useSetSetting,
};
