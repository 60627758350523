/**
 * Generates a random ID by combining a random component and a timestamp, both converted to base 36.
 *
 * @returns {string} A unique random ID string.
 */
export function generateRandomId(): string {
  // Generate a random number, convert it to base 36, and take a substring for the random part
  const randomPart = Math.random().toString(36).substring(2, 11); // 9-character random string

  // Append the current timestamp (converted to base 36) to ensure uniqueness even if generated in rapid succession
  const timestampPart = Date.now().toString(36);

  // Combine the random and timestamp parts to form the ID
  const randomId = randomPart + timestampPart;

  // Return the unique ID
  return randomId;
}
