//
// useGetProjectUsedStorage.ts
//

import { useRunListStorage } from "@custom-hooks/sqlitecloud-commands";
import { FetchProjectUsedStorageResult } from "@data-types/projects-types";
import {
  ListInfo,
  ListStorage,
  StorageEntry,
} from "@data-types/sqlitecloud-commands-type";
import { formatBytes } from "@lib/iso-utils";
import { useMemo } from "react";
import { SWRConfiguration } from "swr";

/**
 * Custom hook to retrieve and compute the total used storage for a specific project using the "LIST STORAGE" SQL query.
 *
 * This hook fetches storage details for a given project and calculates the total used storage by summing
 * all or a subset of storage keys returned by the "LIST STORAGE" query. It also provides the total in both
 * raw bytes and a human-readable format.
 *
 * @param {Object} params - Parameters for fetching and computing project storage.
 * @param {string} params.projectId - The unique identifier of the project for which storage usage is calculated.
 * @param {Array<StorageEntry["key"]>} [params.includedKeys] - (Optional) An array of specific storage keys to include in the total calculation.
 * If not provided, all storage keys will be considered.
 * @param {SWRConfiguration} [params.options] - (Optional) SWR configuration options for data fetching, such as refresh intervals or retry behavior.
 *
 * @returns {FetchProjectUsedStorageResult<ListStorage>} An object containing:
 * - `usedStorageInBytes` (number): The total used storage in bytes.
 * - `usedStorage` (string): A human-readable version of the used storage (e.g., "1.5 GB", "750 MB").
 * - `data` (ListStorage | undefined): The raw list of storage entries fetched from the database.
 * - `isLoading` (boolean): Indicates whether the data is currently being loaded.
 * - `isError` (any): Contains error details if an error occurred during data fetching.
 * - Other fetch details inherited from the `useRunListStorage` hook.
 *
 */
export function useGetProjectUsedStorage({
  projectId,
  includedKeys,
  options,
}: {
  projectId: string;
  includedKeys?: Array<StorageEntry["key"]>;
  options?: SWRConfiguration;
}): FetchProjectUsedStorageResult<ListStorage | ListInfo> {
  // Use the existing hook to fetch storage data for the specific project
  const { data, ...fetchDetails } = useRunListStorage(projectId, options);
  // Compute total used storage, considering only the specified includedKeys if provided
  const usedStorageInBytes = useMemo(() => {
    if (!data) return 0;

    return data.reduce((total, entry) => {
      if (!includedKeys || includedKeys.includes(entry.key)) {
        return total + entry.value;
      }
      return total;
    }, 0);
  }, [data, includedKeys]);

  /*
  const { data, ...fetchDetails } = useRunListInfo(projectId, options);

  // Compute total used storage, considering only the specified includedKeys if provided
  let usedStorageInBytes = useMemo(() => {
    if (!data) return 0;

    return data.reduce((total: any, entry) => {
      if (["disk_usage"].includes(entry.key)) {
        return entry.value;
      }
      return total;
    }, 0);
  }, [data, includedKeys]);
*/

  return {
    usedStorageInBytes: parseInt(usedStorageInBytes as unknown as string, 10),
    usedStorage: formatBytes(usedStorageInBytes),
    data,
    ...fetchDetails,
  };
}
