//
// useGetNodeConnections.ts
//

import { useFetchData } from "@custom-hooks/fetch-data";
import { useGetFetchConditionBasedOnProject } from "@custom-hooks/fetch-data/hooks/useGetFetchConditionBasedOnProject";
import { FetchHookResult } from "@data-types/generic-hook-type";
import { NodeConnections } from "@data-types/nodes-types";
import { ProjectStatus } from "@data-types/projects-types";

/**
 * Custom hook to fetch connections for a specific node within a project.
 *
 * @param {string} nodeId - The ID of the node for which the connections are being fetched.
 * @param {string} projectId - The ID of the project containing the node.
 *
 * @returns {FetchHookResult<NodeConnections>} The result object containing the fetched node connections data.
 */
export function useGetNodeConnections(
  nodeId: string,
  projectId: string
): FetchHookResult<NodeConnections> {
  const fetchCondition = useGetFetchConditionBasedOnProject({
    id: projectId,
    projectStatus: ProjectStatus.RUNNING_AND_WORKING,
  });

  return useFetchData<NodeConnections>({
    fetchCondition: fetchCondition && projectId !== "" && nodeId !== "",
    source: "backend",
    swrKey: {
      url: `/api/projects/${projectId}/nodes/${nodeId}/connections`,
      component: "useGetNodeConnections",
    },
    options: {
      revalidateOnFocus: false,
    },
  });
}
