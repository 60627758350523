//
// useGetDatabaseTables.ts
//

import { useFetchData } from "@custom-hooks/fetch-data";
import { useGetFetchConditionBasedOnProject } from "@custom-hooks/fetch-data/hooks/useGetFetchConditionBasedOnProject";
import { DatabaseTables } from "@data-types/databases-types";
import { FetchHookResult } from "@data-types/generic-hook-type";
import { ProjectStatus } from "@data-types/projects-types";
import { databaseNameExist } from "../utils/databaseNameExist";
import { useGetProjectDatabases } from "./useGetProjectDatabases";

/**
 * Custom hook to fetch tables for a specific database within a project.
 *
 * @param {string} databaseName - The name of the database for which the tables are being fetched.
 * @param {string} projectId - The ID of the project containing the database.
 *
 * @returns {FetchHookResult<DatabaseTables>} The result object containing the fetched database tables data.
 */
export function useGetDatabaseTables(
  databaseName: string,
  projectId: string
): FetchHookResult<DatabaseTables> {
  const { data: databases } = useGetProjectDatabases(projectId);
  const doesDatabaseNameExist = databaseNameExist(
    databaseName,
    databases ?? []
  );

  const fetchCondition = useGetFetchConditionBasedOnProject({
    id: projectId,
    projectStatus: ProjectStatus.RUNNING_AND_WORKING,
  });

  return useFetchData<DatabaseTables>({
    fetchCondition:
      fetchCondition &&
      projectId !== "" &&
      databaseName !== "" &&
      doesDatabaseNameExist,
    source: "backend",
    swrKey: {
      url: `/api/projects/${projectId}/databases/${databaseName}/tables`,
      component: "useGetDatabaseTables",
    },
    options: {
      revalidateOnFocus: false,
    },
  });
}
