"use client";

import { useGetCurrentOrganizationId } from "@custom-hooks/organizations";
import { useGetUserSession } from "@custom-hooks/user";
import { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from "react";

/**
 * Defines the structure of the application state context.
 *
 * @property {string | null} tabId - The currently active tab ID.
 * @property {Dispatch<SetStateAction<string | null>>} setTabId - Function to update the tab ID.
 * @property {boolean} simulateGenericUser - Flag indicating whether to simulate a generic user experience.
 * @property {Dispatch<SetStateAction<boolean>>} setSimulateGenericUser - Function to toggle simulation of a generic user.
 * @property {string[]} projectsInScalingUpState - List of project IDs that are scaling up.
 * @property {(projectId: string) => void} addScalingUpProject - Function to add a project ID to the scaling-up list.
 * @property {(projectId: string) => void} removeScalingUpProject - Function to remove a project ID from the scaling-up list.
 */
type AppStateContextType = {
  tabId: string | null;
  setTabId: Dispatch<SetStateAction<string | null>>;
  simulateGenericUser: boolean;
  setSimulateGenericUser: Dispatch<SetStateAction<boolean>>;
  projectsInScalingUpState: string[];
  addScalingUpProject: (projectId: string) => void;
  removeScalingUpProject: (projectId: string) => void;
};

/**
 * Context for managing the global application state.
 */
export const AppStateContext = createContext<AppStateContextType | null>(null);

/**
 * Provides application state management to its child components.
 *
 * This provider handles:
 * - Managing the active tab ID.
 * - Simulating a generic user experience based on stored user settings.
 * - Managing snack notifications.
 * - Tracking projects that are scaling up.
 *
 * @param {React.ReactNode} props.children - The child components that will have access to the context.
 *
 * @returns {JSX.Element} The provider component wrapping its children.
 */
export function AppStateProvider({
  children
}: {
  children: React.ReactNode;
}) {
  const [tabId, setTabId] = useState<string | null>(null);
  const [simulateGenericUser, setSimulateGenericUser] = useState(true);
  const [projectsInScalingUpState, setProjectsInScalingUpState] = useState<string[]>([]);
  const {
    data: session
  } = useGetUserSession();
  const addScalingUpProject = (projectId: string) => {
    setProjectsInScalingUpState(prev => prev.includes(projectId) ? prev : [...prev, projectId]);
  };
  const removeScalingUpProject = (projectId: string) => {
    setProjectsInScalingUpState(prev => prev.filter(id => id !== projectId));
  };
  const companyId = useGetCurrentOrganizationId();
  useEffect(() => {
    if (session?.userId && companyId) {
      try {
        const retrievedData = localStorage.getItem(`${companyId}-${session.userId}`);
        const parsedData = retrievedData ? JSON.parse(retrievedData) : null;
        setSimulateGenericUser(parsedData === false || parsedData === true ? parsedData : true);
      } catch (error) {
        setSimulateGenericUser(true);
      }
    } else {
      setSimulateGenericUser(true);
    }
  }, [session]);
  return <AppStateContext.Provider value={{
    tabId,
    setTabId,
    simulateGenericUser,
    setSimulateGenericUser,
    projectsInScalingUpState,
    addScalingUpProject,
    removeScalingUpProject
  }} data-sentry-element="unknown" data-sentry-component="AppStateProvider" data-sentry-source-file="AppStateProvider.tsx">
      {children}
    </AppStateContext.Provider>;
}

/**
 * Custom hook to access the application state context.
 *
 * This hook provides access to the application state, including:
 * - The active tab ID.
 * - The simulation mode for a generic user experience.
 * - A list of snack notifications.
 * - The list of projects that are scaling up.
 *
 * @throws {Error} If used outside of an `AppStateProvider`.
 *
 * @returns {AppStateContextType} The current application state context.
 */
export const useAppState = (): AppStateContextType => {
  const context = useContext(AppStateContext);
  if (!context) {
    throw new Error("useAppState must be used within an AppStateProvider");
  }
  return context;
};