import * as Headless from "@headlessui/react";
import clsx from "clsx";
import type React from "react";
export function Fieldset({
  className,
  ...props
}: {
  className?: string;
} & Omit<Headless.FieldsetProps, "as" | "className">) {
  return <Headless.Fieldset {...props} className={clsx(className, "[&>*+[data-slot=control]]:tw-mt-6 [&>[data-slot=text]]:tw-mt-1")} data-sentry-element="unknown" data-sentry-component="Fieldset" data-sentry-source-file="Fieldset.tsx" />;
}
export function Legend({
  className,
  ...props
}: {
  className?: string;
} & Omit<Headless.LegendProps, "as" | "className">) {
  return <Headless.Legend data-slot="legend" {...props} className={clsx(className, "tw-text-base/6 sm:tw-text-sm/6 tw-dark:tw-text-white tw-font-semibold tw-text-zinc-950 data-[disabled]:tw-opacity-50")} data-sentry-element="unknown" data-sentry-component="Legend" data-sentry-source-file="Fieldset.tsx" />;
}
export function FieldGroup({
  className,
  ...props
}: React.ComponentPropsWithoutRef<"div">) {
  return <div data-slot="control" {...props} className={clsx(className, "tw-space-y-8")} data-sentry-component="FieldGroup" data-sentry-source-file="Fieldset.tsx" />;
}
export function Field({
  className,
  ...props
}: {
  className?: string;
} & Omit<Headless.FieldProps, "as" | "className">) {
  return <Headless.Field {...props} className={clsx(className, "[&>[data-slot=label]+[data-slot=control]]:tw-mt-2", "[&>[data-slot=label]+[data-slot=description]]:tw-mt-1", "[&>[data-slot=description]+[data-slot=control]]:tw-mt-3", "[&>[data-slot=control]+[data-slot=description]]:tw-mt-3", "[&>[data-slot=control]+[data-slot=error]]:tw-mt-3", "[&>[data-slot=label]]:tw-font-normal")} data-sentry-element="unknown" data-sentry-component="Field" data-sentry-source-file="Fieldset.tsx" />;
}
export function Label({
  className,
  ...props
}: {
  className?: string;
} & Omit<Headless.LabelProps, "as" | "className">) {
  return <Headless.Label data-slot="label" {...props} className={clsx(className, " tw-dark:tw-text-brandBlues-darkBlue-dark tw-select-none tw-text-16px-regular tw-text-brandBlues-darkBlue-light data-[disabled]:tw-opacity-100")} data-sentry-element="unknown" data-sentry-component="Label" data-sentry-source-file="Fieldset.tsx" />;
}
export function Description({
  className,
  ...props
}: {
  className?: string;
} & Omit<Headless.DescriptionProps, "as" | "className">) {
  return <Headless.Description data-slot="description" {...props} className={clsx(className, "tw-dark:tw-text-text-subTitle-dark tw-text-14px-regular tw-text-text-subTitle-light data-[disabled]:tw-opacity-100")} data-sentry-element="unknown" data-sentry-component="Description" data-sentry-source-file="Fieldset.tsx" />;
}
export function ErrorMessage({
  className,
  ...props
}: {
  className?: string;
} & Omit<Headless.DescriptionProps, "as" | "className">) {
  return <Headless.Description data-slot="error" {...props} className={clsx(className, "tw-dark:tw-text-semantics-error-dark tw-text-14px-regular tw-text-semantics-error-light data-[disabled]:tw-opacity-100")} data-sentry-element="unknown" data-sentry-component="ErrorMessage" data-sentry-source-file="Fieldset.tsx" />;
}