//
// client-error-types.ts
//

import { BackendErrorResponse } from "./backend-response-types";

/**
 * Represents a custom client error for enhanced error handling.
 *
 * This class extends the built-in `Error` object to include additional
 * metadata, such as `details`, which provides context about the error.
 * The `details` field is optional and can be a `BackendErrorResponse` object,
 * enabling seamless propagation of server-side error information to the client.
 */
export class ClientError extends Error {
  details?: BackendErrorResponse;

  /**
   * Creates a new `ClientError` instance.
   *
   * @param message - A descriptive message providing context about the error.
   * @param details - (Optional) The error details, typically a `BackendErrorResponse` object
   *                  containing information about the error (e.g., name, endpoint, message).
   */
  constructor(message: string, details?: BackendErrorResponse) {
    super(message); // Call the parent Error constructor
    this.details = details;

    // Maintain proper stack trace (only in V8 environments like Node.js)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ClientError);
    }
  }
}
