import { capitalizeFirstLetter } from "./capitalizeFirstLetter";

/**
 * Validates if the resource name contains only allowed characters.
 *
 * @param fileName - The name of the resource to validate.
 *
 * @returns A string with an error message if invalid, or undefined if the resource name is valid.
 */
export const validateResourceName = ({
  resourceName,
  resourceType,
}: {
  resourceName: string;
  resourceType: string;
}): string | undefined => {
  if (!resourceName) {
    return `${capitalizeFirstLetter(resourceType)} name cannot be empty.`;
  }

  const validPattern = /^[a-zA-Z0-9 _.\-@]+$/;
  if (!validPattern.test(resourceName)) {
    return `${capitalizeFirstLetter(resourceType)} name must be composed only of letters, numbers, spaces, underscores, dashes, periods, or at symbols.`;
  }

  return undefined;
};
