import { validateResourceName } from "@lib/client-side";
import { validateFileExtension } from "./validateFileExtension";

/**
 * Validates both file name characters and file extension by calling
 * validateFileName and validateFileExtension functions.
 *
 * @param fileName - The name of the file to validate.
 * @param allowedExtensions - An array of allowed file extensions.
 * @param allowWithoutExtension - A boolean indicating whether files without extensions are allowed.
 *
 * @returns A string with an error message if invalid, or undefined if the file name is valid.
 */
export const validateFileNameAndExtension = (
  fileName: string,
  allowedExtensions: string[] | undefined,
  allowWithoutExtension: boolean
): string | undefined => {
  const fileNameError = validateResourceName({
    resourceName: fileName,
    resourceType: "file",
  });

  if (fileNameError) {
    return fileNameError;
  }

  if (allowedExtensions) {
    const extensionError = validateFileExtension(
      fileName,
      allowedExtensions,
      allowWithoutExtension
    );

    if (extensionError) {
      return extensionError;
    }
  }

  return undefined;
};
