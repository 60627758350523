/**
 * Converts a Map to a plain object, recursively converting nested Maps to objects.
 *
 * @param {Map<any, any>} map - The Map to convert.
 * @returns {Record<string, any>} An object representation of the Map.
 */
export function mapToObject(map: Map<any, any>): Record<string, any> {
  const obj: Record<string, any> = {};

  for (const [key, value] of map) {
    // Recursively convert nested Maps to objects
    obj[String(key)] = value instanceof Map ? mapToObject(value) : value;
  }

  return obj;
}
