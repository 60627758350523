//
// swrFetcher.ts
//

import { ClientError } from "@data-types/client-error-types";
import { ServerType, SWRFetcherKey } from "@data-types/client-fetch-types";
import { normalizeBackendError } from "@lib/client-side/normalizeBackendError";
import * as Sentry from "@sentry/nextjs";
import posthog from "posthog-js";

/**
 * Fetch function used by SWR hooks, designed to throw an error if the request fails.
 *
 * @param {[string, string, string[], string]} params - An array containing:
 *   - url (string): The URL to fetch.
 *   - component (string): The component making the request, used in error messages.
 *   - queryStrings (string[] | undefined): Optional query strings to append to the URL.
 *   - auth (string | undefined): Optional authorization token.
 *
 * @returns {Promise<any>} - The response data or binary content if the request is successful.
 *
 * Function Steps:
 * 1. Appends any provided query strings to the URL.
 * 2. Sets up headers, including an authorization token if provided.
 * 3. Sends a GET request to the specified URL.
 * 4. If redirected, resets PostHog and navigates to the sign-in page.
 * 5. Throws an error if the response is not ok and not redirected, including timestamped error info.
 * 6. Returns binary data if the content type is `application/octet-stream` or an image.
 * 7. Otherwise, returns the JSON response data.
 */

export async function swrFetcher({
  url,
  component,
  queryStrings,
  auth,
  reportError = true,
}: SWRFetcherKey): Promise<any> {
  // Check if there are query strings to be appended to the URL
  if (queryStrings && queryStrings.length > 0) {
    url += "?";
    queryStrings.forEach((query) => {
      url += "&" + query;
    });
  }

  let serverType: ServerType = "backend";

  // Initialize headers
  const headers = new Headers();
  if (auth) {
    serverType = "gateway";
    headers.append("Authorization", "Bearer " + auth);
  }

  // Make the fetch request
  const res = await fetch(url, {
    method: "GET",
    headers,
  });

  // Redirect to sign-in if response is redirected
  if (res.ok && res.redirected) {
    posthog.reset();
    window.location.href = res.url;
    return;
  }

  // If response is not okay and not redirected, throw an error
  if (!res.ok && !res.redirected) {
    const rawBackendError = {
      ...(await res.json()),
      status: res.status,
    };
    const errorDetails = normalizeBackendError(rawBackendError, serverType);
    const timestamp = new Date().toISOString();
    const errorMessage = `Failed to fetch from "${url}". Details: ${timestamp} - SWR Error occurred in "${component}": ${res.statusText}`;

    if (reportError) {
      // TODO: TIZ:DAMLA Consider moving this Sentry capture to src/pages/_app.tsx within onErrorRetry
      // to trigger the capture only after 10 retries. However, evaluate the impact on hooks used
      // in components that are part of the App Router.
      Sentry.captureException(new Error(errorMessage), {
        extra: { errorDetails },
      });
    }

    // Throw a ClientError for better error propagation
    throw new ClientError(errorMessage, errorDetails);
  }

  // Handle successful responses
  if (res.ok && !res.redirected) {
    const contentType = res.headers.get("Content-Type");

    // Check if response is binary (like application/octet-stream or image)
    if (
      contentType === "application/octet-stream" ||
      contentType?.startsWith("image/")
    ) {
      return { data: await res.arrayBuffer(), headers: res.headers };
    }

    // Otherwise, parse response as JSON
    return await res.json();
  }
}
