/**
 * Validates an environment variable name to ensure it contains only letters, digits, and underscores,
 * and does not start with a digit.
 *
 * @param {string} envValue - The environment variable name to validate.
 * @returns {object} An object with a boolean `valid` property and a `messages` array indicating validation errors.
 */
export function validateEnvVariable(envValue: string): {
  valid: boolean;
  messages: string[];
} {
  // Check if the name starts with a digit
  if (/^\d/.test(envValue)) {
    return {
      valid: false,
      messages: [
        "The name contains invalid characters. Only letters, digits, and underscores are allowed. Furthermore, the name should not start with a digit.",
      ],
    };
  }

  // Check if the name contains only allowed characters (letters, digits, and underscores)
  if (/^[A-Za-z0-9_]+$/.test(envValue)) {
    return {
      valid: true,
      messages: [],
    };
  }

  // Return invalid result if the name contains invalid characters
  return {
    valid: false,
    messages: [
      "The name contains invalid characters. Only letters, digits, and underscores are allowed. Furthermore, the name should not start with a digit.",
    ],
  };
}
