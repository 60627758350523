/**
 * Generates a simple hash for a given string.
 * Note: This hash is not suitable for cryptographic purposes; it’s intended for obfuscation only.
 *
 * @param {string} input - The string to hash.
 * @returns {string} A hexadecimal string representing the hash.
 */
export function hashString(input: string): string {
  // Initialize hash with a specific seed value
  let hash = 5381;

  // Compute hash by iterating over each character in the input string
  for (let i = 0; i < input.length; i++) {
    // Shift hash left by 5 bits and add the current character code
    hash = (hash << 5) + hash + input.charCodeAt(i); // Equivalent to hash * 33 + c
  }

  // Return the hash as a hexadecimal string
  return hash.toString(16);
}
