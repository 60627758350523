//
// useUppy.ts
//

import { ClientError } from "@data-types/client-error-types";
import {
  CustomUppyFile,
  CustomUppyOptions,
  ForbiddenFile,
  UploadConfig,
  UploadError,
  UseUppyReturn,
} from "@data-types/upload-types";
import { UppyContext } from "@state/UppyProvider";
import Uppy from "@uppy/core";
import { useContext, useEffect, useRef, useState } from "react";
import { getForbiddenFile } from "../utils/getForbiddenFile";
import { validateFileNameAndExtension } from "../utils/validateFileNameAndExtension";

/**
 * Custom React hook to manage Uppy instance for a specific resource.
 *
 * - Initializes and retrieves the Uppy instance automatically.
 * - Ensures the instance is ready before returning.
 * - Manages upload progress and state updates.
 * - Provides methods for updating Uppy configuration and upload methods.
 *
 *
 * @param resourceId - Unique identifier of the resource.
 * @param initialConfig - Optional initial configuration for Uppy (applied only on first initialization).
 * @returns An object containing the Uppy instance, upload state, progress, and configuration methods.
 */

export function useUppy({
  resourceId,
  initialConfig = {},
  allowedExtensions,
  forbiddenFiles,
}: {
  resourceId: string;
  initialConfig?: Partial<CustomUppyOptions>;
  allowedExtensions?: string[];
  forbiddenFiles?: ForbiddenFile[];
}): UseUppyReturn {
  // Retrieve Uppy context
  const context = useContext(UppyContext);
  if (!context) {
    throw new ClientError("useUppy must be used within an UppyProvider");
  }

  // Destructure context functions
  const {
    getUppyInstance,
    updateUppyConfig,
    activeUploads,
    uploadsProgress,
    uploadsError,
    updateUploadMethod,
    startUpload,
    completeUpload,
    setUploadError,
  } = context;

  // State for managing the Uppy instance
  const [uppy, setUppy] = useState<Uppy | null>(null);

  useEffect(() => {
    if (resourceId) {
      // Initialize Uppy instance when resourceId changes
      setUppy(getUppyInstance(resourceId, initialConfig));
    }
  }, [resourceId, getUppyInstance, initialConfig]);

  // State variables for tracking upload progress and file details
  const [fileId, setFileId] = useState<string | null>(null);
  const [fileName, setFileName] = useState<string | undefined>("");
  const [fileSize, setFileSize] = useState<number | undefined>(undefined);
  const [fileError, setFileError] = useState<string | undefined>(undefined);
  const [isFileTaken, setIsFileTaken] = useState<ForbiddenFile | undefined>(
    undefined
  );

  // Create a ref for forbiddenFiles
  const forbiddenFilesRef = useRef<ForbiddenFile[] | undefined>(forbiddenFiles);

  // Sync ref with prop changes
  useEffect(() => {
    forbiddenFilesRef.current = forbiddenFiles;
  }, [forbiddenFiles]);

  // Resets all state variables related to file upload.
  const resetStates = () => {
    setFileId(null);
    // setFileName("");
    setFileSize(undefined);
    setFileError(undefined);
    setIsFileTaken(undefined);
  };

  // Validate file name
  const validateFileName = (fileName: string) => {
    setFileError(
      validateFileNameAndExtension(fileName, allowedExtensions, true)
    );

    if (forbiddenFilesRef.current) {
      setIsFileTaken(getForbiddenFile(forbiddenFilesRef.current, fileName));
    }
  };

  useEffect(() => {
    if (!uppy) return;

    // Initialize state based on current Uppy file state
    const files = uppy.getFiles();
    if (files.length > 0) {
      const file = files[0];
      const fileName = file.name?.trim();
      setFileId(file.id);
      setFileName(fileName);
      setFileSize(file.size as number);
      validateFileName(fileName as string);
    }

    // Attach event listeners
    uppy.on("file-added", handleAddFile);
    // uppy.on("progress", updateProgress);
    uppy.on("complete", handleOnComplete);
    uppy.on("file-removed", clearUppy);

    return () => {
      // Cleanup event listeners when component unmounts
      // uppy.off("progress", updateProgress);
      uppy.off("complete", handleOnComplete);
      uppy.off("file-added", handleAddFile);
      uppy.off("file-removed", clearUppy);
    };
  }, [uppy]);

  /**
   * Handles file addition event
   * @param file - The newly added file
   */
  const handleAddFile = (file: CustomUppyFile) => {
    const fileName = file.name?.trim();
    setFileId(file.id);
    setFileName(fileName as string); // Store file name
    setFileSize(file.size as number); // Store file size
    validateFileName(fileName as string);
    // setUploadProgress(0);
  };

  /**
   * Clears Uppy state when an upload is completed or file is removed
   */
  const clearUppy = () => {
    if (uppy) {
      uppy.clear(); // Reset Uppy instance
      resetStates();
    }
  };

  /**
   * Handles upload completion event
   */
  const handleOnComplete = () => {
    resetStates();
  };

  /**
   * Updates the file name and metadata in Uppy
   * @param newName - The new file name
   */
  const updateFileName = (newName: string) => {
    if (uppy && fileId) {
      newName = newName.trim();
      uppy.setFileState(fileId, { name: newName });
      uppy.setFileMeta(fileId, { name: newName });
      setFileName(newName);
      validateFileName(newName);
    }
  };

  return {
    uppy,
    isUploading: activeUploads[resourceId] || false, // Check if upload is active
    uploadProgress: uploadsProgress[resourceId] || 0,
    uploadError: uploadsError[resourceId] || undefined,
    fileId,
    fileName,
    fileSize,
    fileError,
    isFileTaken,
    clearUppy,
    updateFileName,
    updateUploadMethod: (newConfig: UploadConfig) =>
      updateUploadMethod(resourceId, newConfig),
    updateUppyConfig: (newConfig: Partial<CustomUppyOptions>) =>
      updateUppyConfig(resourceId, newConfig),
    startUpload: () => startUpload(resourceId),
    completeUpload: () => completeUpload(resourceId),
    setUploadError: (uploadError: UploadError | undefined) =>
      setUploadError(resourceId, uploadError),
  };
}
