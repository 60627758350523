/**
 * Generates a cryptographically safe unique ID with an optional prefix.
 *
 * @param {string | undefined} prefix - An optional prefix to prepend to the generated ID.
 * @param {number} numberOfDigits - The number of characters in the generated ID (default: 12).
 * @returns {string} A unique ID string, optionally prefixed.
 */
export function generateId(
  prefix: string | undefined = undefined,
  numberOfDigits: number = 12
): string {
  // Custom alphabet for generating the ID
  const customAlphabet =
    "1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  let id = "";

  // Generate a random character sequence of specified length
  for (let i = 0; i < numberOfDigits; i++) {
    id += customAlphabet.charAt(
      Math.floor(Math.random() * customAlphabet.length)
    );
  }

  // Return the ID with the optional prefix, if provided
  return prefix ? prefix + id : id;
}
