//
// constants.ts -- Constants used to handle billing
//

import {
  AddonLookupKey,
  AddonsDetails,
  AddonsFunctionality,
} from "@data-types/billing-types";
import { Message, MessageType } from "@tw-components/ui/iso";

export const FAILED_TO_UPGRADE_SUBSCRIPTION_ERROR_MESSAGE: Message = {
  type: MessageType.Error,
  title: "Failed to upgrade",
  description:
    "Something went wrong while upgrading. Please try again later. If the issue persists, contact us for assistance.",
};

export const FAILED_TO_VIEW_SUBSCRIPTION_ERROR_MESSAGE: Message = {
  type: MessageType.Error,
  title: "Failed to load subscription details",
  description:
    "We couldn't retrieve your subscription details at the moment. Please try again later. If the issue persists, contact us for assistance.",
};

export const FAILED_TO_LOAD_SUBSCRIPTION_DETAILS_ERROR_MESSAGE: Message = {
  type: MessageType.Error,
  title: "Unable to retrieve subscription plan",
  description:
    "We couldn't retrieve your subscription plan. Please try again later. If the issue persists, contact us for assistance.",
};

export const FAILED_TO_CANCEL_SUBSCRIPTION_ERROR_MESSAGE: Message = {
  type: MessageType.Error,
  title: "Failed to Cancel Subscription",
  description:
    "We encountered an issue while trying to cancel your subscription. Please try again later. If the issue persists, contact us for assistance.",
};

export const FREE_MEMBERS_PER_ORGANIZATION_LIMIT = 3;
export const SANDBOX_PROJECTS_PER_USER_LIMIT = 2;
export const PAST_DUE_SUBSCRIPTIONS_GRACE_PERIOD_DAYS = 30;
export const BOOK_A_MEETING_CALENDAR_URL =
  "https://calendly.com/marcobambini/30min";
export const SQLITE_CLOUD_ORGANIZATION_ID =
  process.env.NEXT_PUBLIC_SQLITE_CLOUD_ORGANIZATION_ID;

/**
 * Defines the details of available add-ons.
 *
 * The `addons` object provides a mapping of `AddonLookupKey` values to their corresponding
 * add-on details. Each add-on includes information such as its name, description,
 * included functionality, and detailed feature descriptions.
 *
 * This structure is used to define the capabilities and limitations of various project tiers.
 *
 * ## Add-on Keys:
 * - `SANDBOX_PROJECT`: Basic tier for prototypes and hobby applications.
 * - `SCALE_PROJECT`: Advanced tier for large-scale applications with custom configurations.
 * - `PRO_PROJECT`: Professional tier for mission-critical and production workloads.
 *
 * ## Add-on Details:
 * - `lookupKey`: Identifier used to reference the add-on programmatically.
 * - `name`: Display name of the add-on.
 * - `description`: Brief explanation of the add-on's purpose.
 * - `additionalInfo` (Optional): Any supplementary information about the add-on.
 * - `includedFunctionality`: A mapping of included features (e.g., storage, retention period).
 * - `functionalityDetails`: Detailed descriptions of specific features.
 */
export const addons: Record<AddonLookupKey, AddonsDetails> = {
  [AddonLookupKey.MEMBER]: {
    lookupKey: AddonLookupKey.MEMBER,
    name: "Member",
    description: "",
    includedFunctionality: {},
    functionalityDetails: {},
    monthlyPricing: {
      basePrice: 10,
      discountDetails: {
        discountedPrice: 0,
        discountReason: "Free until",
      },
    },
  },
  [AddonLookupKey.SANDBOX_PROJECT]: {
    lookupKey: AddonLookupKey.SANDBOX_PROJECT,
    name: "Sandbox",
    description: "For prototypes and hobby apps.",
    additionalInfo: "Limit of 2 sandbox projects across all your workspaces.",
    includedFunctionality: {
      [AddonsFunctionality.STORAGE_IN_GB]: 1,
      [AddonsFunctionality.CLUSTER_NODE_COUNT]: 1,
    },
    functionalityDetails: {
      [AddonsFunctionality.CLUSTER_NODE_COUNT]: {
        summary: "Single node",
        fullDescription: "Single node cluster",
      },
      [AddonsFunctionality.STORAGE_IN_GB]: {
        summary: "1 GB",
        fullDescription: "1 GB storage",
      },
      [AddonsFunctionality.VCPU]: {
        summary: "0.5 vCPUs",
        fullDescription: "Up to 0.5 vCPUs",
      },
      [AddonsFunctionality.RAM]: {
        summary: "500MB",
        fullDescription: "Up to 0.5 GB RAM",
      },
      [AddonsFunctionality.SUPPORT]: {
        summary: "Community",
        fullDescription: "Community support",
      },
      [AddonsFunctionality.COLD_START]: {
        fullDescription: "Cold starts after 3 days of inactivity",
      },
      [AddonsFunctionality.CONNECTIONS]: {
        summary: "Up to 30 concurrent",
        fullDescription: "Up to 30 concurrent connections",
      },
    },
    monthlyPricing: {
      basePrice: 0,
    },
  },
  [AddonLookupKey.SCALE_PROJECT]: {
    lookupKey: AddonLookupKey.SCALE_PROJECT,
    name: "Scale",
    description: "For large-scale apps with custom requirements.",
    functionalityDetails: {
      [AddonsFunctionality.INSTANCE_SIZE]: {
        fullDescription: "Dedicated and customizable instance sizes",
      },
      [AddonsFunctionality.CLUSTER_NODE_COUNT]: {
        fullDescription: "Custom cluster configurations",
      },
      [AddonsFunctionality.REGIONS]: {
        fullDescription: "Multiple regions",
      },
      [AddonsFunctionality.SUPPORT]: {
        fullDescription: "Priority support",
      },
      [AddonsFunctionality.SLA]: {
        fullDescription: "Uptime SLAs",
      },
      [AddonsFunctionality.CONNECTIONS]: {
        fullDescription: "Unlimited connections",
      },
    },
    includedFunctionality: {
      [AddonsFunctionality.STORAGE_IN_GB]: 10,
      [AddonsFunctionality.RETENTION_IN_HOURS]: 72,
      [AddonsFunctionality.CLUSTER_NODE_COUNT]: 3,
    },
    monthlyPricing: {
      basePrice: 29,
    },
  },
  [AddonLookupKey.PRO_PROJECT]: {
    lookupKey: AddonLookupKey.PRO_PROJECT,
    name: "Pro",
    description: "For mission-critical workload and production apps.",
    includedFunctionality: {
      [AddonsFunctionality.STORAGE_IN_GB]: 10,
      [AddonsFunctionality.RETENTION_IN_HOURS]: 72,
      [AddonsFunctionality.CLUSTER_NODE_COUNT]: 3,
    },
    functionalityDetails: {
      [AddonsFunctionality.CLUSTER_NODE_COUNT]: {
        summary: "Multi node",
        fullDescription: "Multi-node cluster",
        tooltip:
          "Multi-node clusters are fault tolerant, and can handle more traffic and concurrent users.",
      },
      [AddonsFunctionality.STORAGE_IN_GB]: {
        summary: "10 GB",
        fullDescription: "10 GB storage",
      },
      [AddonsFunctionality.VCPU]: {
        summary: "1 vCPU",
        fullDescription: "Up to 1 vCPU",
      },
      [AddonsFunctionality.RAM]: {
        summary: "1 GB",
        fullDescription: "Up to 1 GB RAM",
      },
      [AddonsFunctionality.RETENTION_IN_HOURS]: {
        summary: "3 days with PITR",
        fullDescription: "3 days of backups (PITR)",
        tooltip:
          "Point-in-time recovery allows you to recover a database to any specific moment within a retention period.",
      },
      [AddonsFunctionality.SUPPORT]: {
        summary: "Priority",
        fullDescription: "Email support",
      },
      [AddonsFunctionality.COLD_START]: {
        fullDescription: "No cold starts",
      },
      [AddonsFunctionality.EARLY_ACCESS]: {
        fullDescription: "Early access to new features",
      },
      [AddonsFunctionality.CONNECTIONS]: {
        summary: "Up to 100 concurrent",
        fullDescription: "Up to 100 concurrent connections",
      },
    },
    monthlyPricing: {
      basePrice: 29,
      discountDetails: {
        discountedPrice: 0,
        discountReason: "Free until",
      },
    },
  },
  [AddonLookupKey.INTERNAL_PROJECT]: {
    lookupKey: AddonLookupKey.INTERNAL_PROJECT,
    name: "Internal",
    description: "For internal projects of Sqlite Cloud.",
    functionalityDetails: {},
    includedFunctionality: {
      [AddonsFunctionality.STORAGE_IN_GB]: 20,
      [AddonsFunctionality.RETENTION_IN_HOURS]: 72,
      [AddonsFunctionality.CLUSTER_NODE_COUNT]: 1,
    },
    monthlyPricing: {
      basePrice: 0,
    },
  },
  [AddonLookupKey.ORGANIZATION]: {
    lookupKey: AddonLookupKey.ORGANIZATION,
    name: "Organization",
    description: "",
    includedFunctionality: {},
    functionalityDetails: {},
    monthlyPricing: {
      basePrice: 0,
    },
  },
};
