//
// useFetchData.ts - Generic data fetching hook using SWR
//

import { swrFetcher } from "@custom-hooks/fetch-data";
import { SWRFetchOptions } from "@data-types/client-fetch-types";
import { FetchHookResult } from "@data-types/generic-hook-type";
import useSWR from "swr";

/**
 * Generic hook to fetch and manage data from an API endpoint using SWR.
 *
 * This hook abstracts repetitive SWR logic and provides standardized UI state indicators.
 *
 * @template T - The type of data to be fetched.
 *
 * @param {SWRFetchOptions} fetchOptions - Configuration options for the fetch operation, including:
 *   - `fetchCondition`: A flag indicating whether the fetch operation should occur. If `false`, the SWR fetch will not execute.
 *   - `swrKey`: Parameters used as the SWR key for caching and identifying the data.
 *   - `source`: The source type of the data (e.g., `api`, `local`, `cache`).
 *   - `options`: SWR configuration settings to customize the behavior of the fetch operation.
 *
 * @returns {FetchHookResult<T>} An object containing:
 *   - `data`: The fetched and processed data or `undefined` if no data is available.
 *   - `isLoading`: `true` if the data is currently loading; otherwise, `false`.
 *   - `isError`: `true` if there was an error during data fetching; otherwise, `false`.
 *   - `isValidating`: `true` if the data is in the process of revalidating; otherwise, `false`.
 *   - `showLoader`: `true` if a loading indicator should be displayed (based on loading or validating state).
 *   - `hasData`: `true` if there is data available after fetching.
 *   - `emptyData`: `true` if data was fetched but is empty (e.g., an empty array).
 *   - `mutate`: A function to manually revalidate and refresh the fetched data.
 */
export function useFetchData<T>(fetchOptions: SWRFetchOptions): FetchHookResult<T> {
  const {
    fetchCondition,
    swrKey,
    source,
    options
  } = fetchOptions;
  const {
    data,
    error,
    isValidating,
    mutate,
    isLoading
  } = useSWR(() => fetchCondition && swrKey, swrFetcher, options);

  // Extract data based on the source type
  const extractedData = (() => {
    switch (source) {
      case "backend":
        if (data && typeof data === "object" && !data.value) {
          data.value = [];
        }
        return data?.value;
      case "gateway":
        return data?.data;
      case "dashboard":
        if (data && typeof data === "object" && !data.value) {
          data.value = [];
        }
        return data?.value;
    }
  })();
  const isError = !!error;

  // Initialize flags for data presence and emptiness
  let hasData = false;
  let emptyData = false;

  // Evaluate the extracted data only if there's no error or loading
  if (!isError && !isLoading) {
    if (Array.isArray(extractedData)) {
      // If data is an array, set flags based on its length
      hasData = extractedData.length > 0;
      emptyData = extractedData.length === 0;
    } else {
      // For non-array data, set `hasData` to true if `extractData` is truthy
      hasData = !!extractedData;
    }
  }
  return {
    data: extractedData,
    isLoading,
    isError,
    isValidating,
    showLoader: isLoading || isValidating,
    hasData,
    emptyData,
    mutate
  };
}