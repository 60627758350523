/**
 * Checks if a specific class exists on an HTML element.
 *
 * @param {HTMLElement} element - The element to check for the class.
 * @param {string} className - The class name to look for.
 * @returns {boolean} Returns `true` if the class exists on the element, otherwise `false`.
 */
export function hasClass(element: HTMLElement, className: string): boolean {
  // Use classList.contains for a more reliable check
  return element.classList.contains(className);
}
