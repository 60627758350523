import { useEditData_v2 } from "@custom-hooks/edit-data";
import { EditDataOpt, EditDataResult } from "@data-types/generic-hook-type";

/**
 * Custom hook to handle database deletion within a project.
 *
 * @param {EditDataOpt<null>} options - Configuration options for the delete operation.
 *
 * @returns {{
 *   deleteDatabase: (params: { projectId: string, databaseName: string }) => Promise<EditDataResult<null>>;
 *   isDeletingDatabase: boolean;
 *   error: any;
 * }} - Returns the delete function, loading state, and any errors.
 */
export function useDeleteDatabase(options: EditDataOpt<null>) {
  const { isMutatingData, isLoading, editData, error } = useEditData_v2({
    ...options,
    stopGlobalError: options?.stopGlobalError ?? true,
  });

  // const { connectionStringApiKey, gatewayUrlHTTP } =
  //   useGetProjectInfo(projectId);
  /**
   * Deletes a specified database within the current project.
   *
   * @param {string} params.projectId - The ID of the project that the database belongs to.
   * @param {string} params.databaseName - The name of the database to delete.
   *
   * @returns {Promise<EditDataResult<null>>} - The result of the delete operation.
   */
  const deleteDatabase = async ({
    projectId,
    databaseName,
    gatewayUrlHTTP,
    connectionStringApiKey,
  }: {
    projectId: string;
    databaseName: string;
    gatewayUrlHTTP: string;
    connectionStringApiKey: string;
  }): Promise<EditDataResult<null>> => {
    return await editData({
      method: "GET",
      endpoint: `/api/projects/${projectId}/databases/${databaseName}/delete`,
      endpointCallLocation: "useDeleteDatabase",
      serverType: "backend",
      mutateApis: [
        {
          url: `/api/projects/${projectId}/databases`,
          component: "useGetProjectDatabases",
        },
        {
          url: `${gatewayUrlHTTP}/v2/weblite/sql?sql=${encodeURIComponent("LIST INFO")}`,
          component: "useRunSQLQuery",
          auth: connectionStringApiKey,
        },
      ],
    });
  };

  return {
    deleteDatabase,
    isDeletingDatabase: isLoading || isMutatingData,
    error,
  };
}
