//
// projects-types.ts
//

import { AddonLookupKey } from "./billing-types";
import { FetchHookResult } from "./generic-hook-type";
import { Regions } from "./regions-types";

export enum Environment {
  Development = 0,
  Staging = 1,
  Production = 2,
}

export type EnvironmentInfo = {
  color: string;
  label: "DEVELOPMENT" | "STAGING" | "PRODUCTION";
};

/**
 * Represents a project record inside the projects table in users.sqlitecloud database
 */
export type ProjectRecord = {
  project_id: string;
  organization_id: string;
  name: string;
  attributes: string; //JSON string
};

/**
 * Represents how project is returned by backend
 */
export type RawProject = {
  admin_apikey?: string;
  admin_reserved_apikey?: string;
  admin_password: string;
  admin_username: string;
  connection_string?: string;
  creation_date: string;
  description: string;
  env: Environment;
  gateway_string?: string;
  id: string;
  name: string;
  nodes_count: number;
  creating_count: number;
  project_address: string;
  regions: string; // e.g. [{"code":"us-west-1","description":"US West (N. California)"}]
};

export type Project = Omit<RawProject, "regions"> & {
  regionsList: Regions;
};

export type RawProjects = RawProject[];

export type Projects = Project[];

export type ProjectStorageInfo = {
  availableStorage: string;
  availableStorageInBytes: number;
  usedStorage?: string;
  usedStorageInBytes?: number;
  usedStoragePercentage?: number;
  warning: boolean;
};

/**
 * Represents the request body for creating a new project.
 * The `operation_id` is optional and is primarily used for paid projects.
 * The `plan_id` determines the type of project being created.
 */
export type CreateProjectBodyPayload = {
  operation_id?: string;
  plan_id:
    | AddonLookupKey.PRO_PROJECT
    | AddonLookupKey.SCALE_PROJECT
    | AddonLookupKey.SANDBOX_PROJECT;
  name: string;
  region: string;
  env: Environment;
  description: string;
  admin_username?: string;
  admin_password?: string;
};

/**
 * Type definition for creating a sandbox project.
 * - `operation_id` is not allowed for sandbox projects.
 * - `plan_id` must be `SANDBOX_PROJECT`.
 */
export type CreateSandboxProjectPayload = Omit<
  CreateProjectBodyPayload,
  "operation_id" | "plan_id"
> & {
  operation_id?: never;
  plan_id?: AddonLookupKey.SANDBOX_PROJECT;
};

/**
 * Type definition for creating a paid project.
 * - `operation_id` is mandatory.
 * - `plan_id` must be either `PRO_PROJECT` or `SCALE_PROJECT`.
 */
export type CreatePaidProjectPayload = Omit<
  CreateProjectBodyPayload,
  "operation_id" | "plan_id"
> & {
  operation_id: string; // Required for tracking the creation process of paid projects.
  plan_id: AddonLookupKey.SCALE_PROJECT | AddonLookupKey.PRO_PROJECT; // Allowed plans for paid projects.
};

/**
 * Extends FetchHookResult to include specific flags for tracking job progress.
 *
 * @template T - The type of data being fetched, such Jobs.
 * @property {boolean} isAnyJobInProgress - Indicates if at least one job is currently in progress.
 */
export type FetchProjectJobsResult<T> = FetchHookResult<T> & {
  isAnyJobInProgress: boolean;
  isAnyJobWithError: boolean;
  projectStatusString: string | undefined;
  projectErrorString: string | undefined;
  errors: string[];
};

/**
 * Extends the `FetchHookResult` type to include additional flags for tracking job progress and storage info.
 * This type adds support for managing the presence of databases and an optional mutate function.
 *
 * @template T - The type of data being fetched, which extends `FetchHookResult<T>`.
 *
 * @property {boolean} [noDatabases] - A flag indicating whether no databases were found.
 * @property {function} [mutate] - A function to manually refresh or update the data.
 */
export type FetchProjectStorageInfoResult<T> = Omit<
  FetchHookResult<T>,
  "mutate"
> & {
  noDatabases?: boolean;
  mutate?: () => void;
};

/**
 * Extends FetchHookResult to include additional metadata for sandbox projects owned by a user.
 *
 * @template T - The type of data being fetched, such as projects or jobs.
 * @property {number | undefined} sandboxProjectsCount - The total number of sandbox projects owned by the user, or `undefined` if not available.
 */
export type FetchOwnedSandboxProjectsResult<T> = FetchHookResult<T> & {
  sandboxProjectsCount: number | undefined;
};

/**
 * Extended fetch hook result for project used storage information.
 *
 * This type extends the generic `FetchHookResult<T>` by adding
 * specific fields related to storage usage, allowing for both
 * raw byte values and a human-readable format.
 *
 * @template T - The type of data being fetched, which extends `FetchHookResult<T>`.
 *
 *
 * @property {number} usedStorageInBytes - The total used storage in bytes.
 * Useful for calculations, thresholds, or further data processing.
 * @property {string} usedStorage - A human-readable version of the used storage
 * (e.g., "1.5 GB", "750 MB"), optimized for display in the UI.
 *
 */
export type FetchProjectUsedStorageResult<T> = FetchHookResult<T> & {
  usedStorageInBytes: number;
  usedStorage: string;
};

export type ProjectRuntime = {
  status: "running" | "deactivated" | "scaledToZero" | "notReady";
};

export enum ProjectStatus {
  SCALED_TO_ZERO = "scaled_to_zero",
  DEACTIVATED = "deactivated",
  RUNNING_AND_WORKING = "running_and_working",
  RUNNING_BUT_NOT_WORKING = "running_but_not_working",
  NOT_READY = "not_ready",
}
