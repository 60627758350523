import { ClientError } from "@data-types/client-error-types";
import { ServerType } from "@data-types/client-fetch-types";
import * as Sentry from "@sentry/nextjs";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { normalizeBackendError } from "./normalizeBackendError";

export type FetchApiOptions = {
  tech?: "fetch" | "axios";
  endpoint: string;
  method: "GET" | "POST" | "PATCH";
  endpointCallLocation: string;
  queryStrings?: string[];
  body?: any;
  auth?: string;
  onDownloadProgress?: (progressEvent: ProgressEvent) => void;
  onUploadProgress?: (progressEvent: ProgressEvent) => void;
  isBlob?: boolean;
};

/**
 * Utility function to fetch data from Next.js API routes, supporting both fetch and axios libraries.
 *
 * @param {FetchApiOptions} opt - The options for the fetch request.
 *   - tech (optional, "fetch" | "axios"): Specifies the library to use ("fetch" by default).
 *   - endpoint (string): The endpoint URL for the API request.
 *   - method ("GET" | "POST" | "PATCH"): HTTP method for the request.
 *   - endpointCallLocation (string): The component or function name initiating the call, used in error messages.
 *   - queryStrings (optional, string[]): Array of query strings to append to the URL.
 *   - body (optional, any): The request payload for POST or PATCH requests.
 *   - auth (optional, string): Bearer token for authorization, if needed.
 *   - onDownloadProgress (optional, function): Callback for monitoring download progress (axios only).
 *   - onUploadProgress (optional, function): Callback for monitoring upload progress (axios only).
 *   - isBlob (optional, boolean): If true, expects binary response data; default is false.
 *
 * @returns {Promise<any>} - A promise that resolves with the API response data, either in JSON or binary format.
 *
 * Function Workflow:
 * 1. Constructs the request URL by appending query strings, if provided.
 * 2. Builds the authorization header if an auth token is provided.
 * 3. Configures request parameters, including headers, body, and content type.
 * 4. Executes the request using either fetch or axios based on the specified tech.
 * 5. Handles potential errors:
 *    - Logs detailed error information if the request fails.
 *    - Throws a custom error with status, request configuration, and message.
 * 6. Checks the response content type:
 *    - For binary responses (e.g., application/octet-stream or images), converts the response to a Blob.
 *    - For JSON responses, parses and returns the JSON data.
 *
 * Error Handling:
 * - If the request is unsuccessful, an error is thrown with detailed information, including status and error message.
 *
 * Usage:
 * This function is designed for flexibility, supporting both JSON and binary responses, and works with both fetch and axios.
 * It can handle authentication, query strings, and provides hooks for progress tracking when using axios.
 */
export async function fetchApiRoute(opt: FetchApiOptions): Promise<any> {
  const {
    method,
    endpointCallLocation,
    queryStrings,
    body,
    auth,
    onDownloadProgress,
    isBlob = false,
  } = opt;
  let { tech, endpoint, onUploadProgress } = opt;

  // Set default tech to "fetch" if not specified
  if (!tech) tech = "fetch";

  // Append query strings to the URL if provided
  if (queryStrings && queryStrings.length > 0) {
    endpoint = endpoint + "?";
    queryStrings.forEach((query) => {
      endpoint = endpoint + "&" + query;
    });
  }

  let serverType: ServerType = "backend";

  // Create authorization headers if auth is provided
  let headers: Headers | Record<string, string> =
    tech === "fetch" ? new Headers() : {};
  if (auth) {
    serverType = "gateway";
    const authHeader = "Bearer " + auth;
    if (tech === "fetch") {
      (headers as Headers).append("Authorization", authHeader);
    } else {
      headers = { Authorization: authHeader };
    }
  }

  // Build fetch request parameters
  const fetchObj: any = {
    method,
    headers,
  };

  // Add body to request if specified
  if (body) {
    const isBuffer = body instanceof ArrayBuffer;
    if (tech === "fetch") {
      (fetchObj as RequestInit).body = isBuffer ? body : JSON.stringify(body);
      (fetchObj.headers as Headers).append(
        "Content-Type",
        isBuffer ? "application/octet-stream" : "application/json"
      );
    } else {
      (fetchObj as AxiosRequestConfig).data = isBuffer
        ? body
        : JSON.stringify(body);
      (fetchObj.headers as Record<string, string>)["Content-Type"] = isBuffer
        ? "application/octet-stream"
        : "application/json";
    }
  }
  let res: any;
  try {
    // Execute request with fetch or axios based on selected tech
    if (tech === "fetch") {
      res = await fetch(endpoint, fetchObj as RequestInit);
    } else if (tech === "axios") {
      fetchObj.url = endpoint;
      if (isBlob) fetchObj.responseType = "blob";
      if (method === "GET" && onDownloadProgress) {
        fetchObj.onDownloadProgress = onDownloadProgress;
      }
      if ((method === "POST" || method === "PATCH") && onUploadProgress) {
        fetchObj.onUploadProgress = onUploadProgress;
      }
      res = await axios(fetchObj as AxiosRequestConfig);
    }
    // Check for response success status
    const resOk =
      tech === "fetch"
        ? (res as Response).ok
        : (res as AxiosResponse).status === 200;

    // Check if there is a redirect in the response
    const redirected =
      tech === "fetch"
        ? (res as Response).redirected
        : [301, 302, 303, 307, 308].includes((res as AxiosResponse).status);

    // If there is a redirect extract the redirect url
    let redirectUrl = "";
    if (redirected) {
      redirectUrl =
        tech === "fetch"
          ? (res as Response).url
          : (res as AxiosResponse).headers.location;
    }

    if (resOk && redirected) {
      window.location.href = redirectUrl;
      return null;
    }

    // Throw an error if the response is unsuccessful
    if (!resOk && !redirected) {
      let rawBackendError: any;
      if (tech === "fetch") {
        rawBackendError = {
          ...(await res.json()),
          status: res.status,
        };
      } else if (tech === "axios") {
        rawBackendError = {
          ...res.data,
          status: res.status,
        };
      }
      if (method === "POST" || method === "PATCH") {
        rawBackendError.reqBody = JSON.stringify(body);
      }
      const errorDetails = normalizeBackendError(rawBackendError, serverType);
      const timestamp = new Date().toISOString();
      throw new ClientError(
        `${timestamp} - Error occurred in "${endpointCallLocation}": ${res.statusText}`,
        errorDetails
      );
    }
    // Determine content type for response handling
    const contentType =
      tech === "fetch"
        ? (res as Response).headers.get("Content-Type")
        : (res as AxiosResponse).headers["content-type"];
    // Handle binary responses
    if (
      contentType === "application/octet-stream" ||
      contentType?.startsWith("image/")
    ) {
      if (tech === "fetch") {
        const blob = new Blob([await (res as Response).arrayBuffer()], {
          type: "application/octet-binary",
        });
        return {
          data: blob,
          headers: (res as Response).headers,
          config: { url: endpoint },
        };
      } else {
        const convertSQLiteStringToBlob = (sqliteString: string): Blob => {
          const bytes = sqliteString
            .replace(/\\x([0-9A-Fa-f]{2})/g, (match, p1) =>
              String.fromCharCode(parseInt(p1, 16))
            )
            .split("")
            .map((c) => c.charCodeAt(0));
          return new Blob([new Uint8Array(bytes)], {
            type: "application/octet-stream",
          });
        };
        const blob = isBlob
          ? (res as AxiosResponse).data
          : convertSQLiteStringToBlob((res as AxiosResponse).data);
        return {
          data: blob,
          headers: (res as AxiosResponse).headers,
          request: (res as AxiosResponse).request,
          config: (res as AxiosResponse).config,
        };
      }
    } else {
      // Return JSON data for non-binary responses
      return tech === "fetch"
        ? (res as Response).json()
        : (res as AxiosResponse).data;
    }
  } catch (error: any) {
    // Handle and log any fetch-related errors
    const errorMessage = `Failed to fetch from "${endpoint}" with method "${method}". Details: ${error.message}`;
    console.error(
      `An error occurred during the API request in "${endpointCallLocation}":`,
      error
    );
    if (tech === "axios") {
      error.details = error;
      Sentry.captureException(new Error(errorMessage), {
        extra: {
          errorDetails: error.details || undefined,
        },
      });
      // Throw a ClientError for better error propagation
      throw new ClientError(errorMessage, error.details || undefined);
    } else {
      Sentry.captureException(new Error(errorMessage), {
        extra: {
          errorDetails: error.details || undefined,
        },
      });
      // Throw a ClientError for better error propagation
      throw new ClientError(errorMessage, error.details || undefined);
    }
  }
}
