//
// allowedDatabaseExtensions.ts
//

export const allowedDatabaseExtensions = [
  ".sqlite",
  ".sqlite3",
  ".db",
  ".db3",
  ".s3db",
  ".sl3",
  ".sqlitecloud",
];
