import { ErrorPage } from "@tw-components/error/ErrorPage";
import React from "react";
interface ErrorBoundaryProps {
  children: React.ReactNode;
}
interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}
class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: null
    };
  }
  static getDerivedStateFromError(): Partial<ErrorBoundaryState> {
    return {
      hasError: true
    };
  }
  async componentDidCatch(error: Error): Promise<void> {
    this.setState({
      error
    });
  }
  render(): React.ReactNode {
    if (this.state.hasError) {
      return <ErrorPage error={this.state.error} onNavigateToHomeClicked={() => {
        this.setState({
          hasError: false
        });
      }} data-sentry-element="ErrorPage" data-sentry-component="ErrorBoundary" data-sentry-source-file="ErrorBoundary.tsx" />;
    }
    return this.props.children;
  }
}
export default ErrorBoundary;