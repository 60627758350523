//
// useRunListStorage.ts
//

import { useRunSQLQuery } from "@custom-hooks/weblite";
import { FetchHookResult } from "@data-types/generic-hook-type";
import { ListStorage } from "@data-types/sqlitecloud-commands-type";
import { SWRConfiguration } from "swr";

export function useRunListStorage(
  projectId: string,
  options?: SWRConfiguration
): FetchHookResult<ListStorage> {
  return useRunSQLQuery({
    projectId,
    query: "LIST STORAGE",
    options,
  });
}
