/**
 * Converts a value in gigabytes to bytes.
 *
 * @param {number} gigabytes - The value in gigabytes to convert.
 * @returns {number} The converted value in bytes.
 */
export function gigabytesToBytes(gigabytes: number): number {
  // If gigabytes is 0 or undefined, return 0
  if (!gigabytes) return 0;

  // Convert gigabytes to bytes
  const bytes = gigabytes * 1_000_000_000;
  return bytes;
}
